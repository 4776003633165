import { Carrier, Equipment, IChargeUser, IOrder, Leg } from "./orders";

export type TripStop = {
	id: number;
	tripId: number;
	address: {
		id: number;
		invPartyName: string;
		countryCode: string;
		addressLine: string;
		zipCode: string;
		city: string;
		hall: string;
		plant: string;
		dock: string;
		lat: string | null;
		lon: string | null;
		type: null | "Origin" | "Destination" | "Consignee" | "Sender" | "Deposit" | "Partner";
	};
	stopSequence: number;
	stopTime: null | string;
	startTime: null | string;
}

export type TripStatus = null | TripStatusOptionType;

export interface ITrip {
	id: number;
	description: string | null;
	equipment: Equipment;
	// orders: IOrder[];
	// confirmed: boolean;
	weight: number;
	volume: number;
	linearMeters: number;
	taxWeight: number;
	shipmentUnit: number
	carrier: null | Carrier;
	status: TripStatus;
	truckPlateNumber: null | ITripTruckPlate;
	truckPlateTrailer: null | ITripTruckPlate;
	owner: null | IChargeUser;
	stops: TripStop[];
	transportMode: "Road" | "Combi";
	sgaTripId: null | string | number;
	legs: Leg[];
	cutoff: string | null;
	dailyId: null | number;
	creationTime: string;
	dependencies: ITripDependencies;
	sendImmediatly: boolean;
	errorColor?: string | null;
}

export interface ITripDependencies {
	trips: ITrip[];
	orders: IOrder[];
};

export interface ITripTruckPlate {
	id: null | number;
	name: string;
	category?: null | string;
};

export interface ICheckRightStopOrderResult {
	isCorrect: boolean,
	issues: StopOrderIssue[]
}

export interface StopOrderIssue {
	originStopId: number,
	destinationStopId: number
}

export enum TripStatusEnum {
	NOT_DISPATCHABLE = 1,
	DISPATCHABLE = 2,
	DISPATCHING = 3,
	DISPATCHED = 4,
	ERROR = 5,
	REPLANNING = 6,
	DEPARTED = 7,
	HANDLED_ERROR = 8
};

export type TripStatusOptionType = {
	name: string;
	id: number;
}

export interface TripInfo {
	dailyId?: number | null;
	creationTime?: Date | null;
	creationUser?: string | null;
	status?: string | null;
	equipment?: string | null;
	transportMode?: string | null;
	sgaTripId?: string | null;
	description?: string | null;
	tripSent: Date[];
	tripReplan: Date[];
	feedback?: TripInfoFeedback | null;
	statusHistory: TripInfoStatusHistory[];
}

export interface TripInfoFeedback {
	success?: boolean | null;
	time?: Date | null;
}

export interface TripInfoStatusHistory {
	status?: string | null;
	timeStamp?: Date | null;
	user?: string | null;
}

export class TripCombination {
	public combinationCode: string = "";
	public legTypeId: number | undefined = undefined;
	public legOriginId: number | undefined = undefined;
	public legOriginIT: boolean | undefined = undefined;
	public legDestinationId: number | undefined = undefined;
	public legDestinationIT: boolean | undefined = undefined;
	public immediateDispatch: boolean | undefined = undefined;
	public rowId: number | undefined = undefined;
}

export class TripRule {
	public ruleCode: string = "";
	public templateCode: string = "";
	public combinations: string[] = [];
}