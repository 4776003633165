import { createSlice } from "@reduxjs/toolkit";

export const dataInitialState = {
  updateTime: new Date(),
  requestUpdateTime: new Date(),
};

const dataSlice = createSlice({
  name: "data",
  initialState: dataInitialState,
  reducers: {
    setUpdateTime(state, action) {
      state.updateTime = action.payload;
    },
    setRequestUpdateTime(state, action) {
      state.requestUpdateTime = action.payload;
    },
  },
});

export const { setUpdateTime, setRequestUpdateTime } = dataSlice.actions;

export default dataSlice.reducer;
