import React, { useEffect } from "react";
import {
  GenerateCodeVerifier,
  GenerateCodeChallenge,
} from "../../../utils/AuthUtils";
import config from "../../../settings";

export default function AuthorizePage() {
  useEffect(() => {
    // Genero PKCE
    const codeVerifier = GenerateCodeVerifier();
    const codeChallenge = GenerateCodeChallenge(codeVerifier);
    window.sessionStorage.setItem("codeVerifier", codeVerifier);

    // Costruisco chiamata verso Authorize endpoint
    var authorizeUrl =
      config.baseIdpUrl +
      config.authorizeEndpoint +
      "?client_id=" +
      config.clientId +
      "&redirect_uri=" +
      encodeURIComponent(window.location.origin + config.loginCallbackUrl) +
      "&response_type=code" +
      "&scope=" +
      encodeURIComponent(
        "uom_api_scope usl_tools openid profile offline_access usl_telematics pt_scope"
      ) +
      "&code_challenge_method=S256" +
      "&code_challenge=" +
      codeChallenge;

    //var Url = `${config.authorizeReactAppUrl}`+'?client_id=arcese-bre&redirect_uri='+`${config.baseUriReactApp}`+'/signin-oidc&response_type=code&scope=bre_api_scope%20monitor_api_scope%20openid%20profile%20offline_access&state=1234zyx&code_challenge_method=S256&code_challenge='+`${code_challenge}`;

    window.location.href = authorizeUrl;
  }, []);

  return (
    <>
      <div className="loading-container"></div>
      <div className="loading-widget">
        <div className="loading-img"></div>
      </div>
    </>
  );
}
