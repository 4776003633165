//eslint-disable-next-line
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import config from "../settings";
import { MapService } from "../services/mapService";

export const MapSettings = {
  platform: null,
  defaultLayers: null,
  router: null,
  searchService: null,
  map: null,
  behavior: null,
  ui: null,
};

export const BaseMapService = {
  initMap: mapRef => {
    const H = window.H;

    MapSettings.platform = new H.service.Platform({
      apikey: config.HMap_AppKey,
    });

    MapSettings.defaultLayers = MapSettings.platform.createDefaultLayers();
    MapSettings.router = MapSettings.platform.getRoutingService(null, 8);
    MapSettings.searchService = MapSettings.platform.getSearchService();
    MapSettings.map = new H.Map(
      mapRef.current,
      MapSettings.defaultLayers.raster.normal.map,
      {
        center: { lat: 50, lng: 5 },
        zoom: 4,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    MapService.setBaseTileLayer(
      "00000000F",
      MapSettings.map,
      "reduced.day",
      config.HMap_AppKey
    );

    window.addEventListener(
      "resize",
      MapService.events.resize(MapSettings.map)
    );
    MapSettings.map.addEventListener(
      "pointermove",
      MapService.events.pointerMove(MapSettings.map, H),
      false
    );

    MapSettings.behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(MapSettings.map)
    );
    MapSettings.ui = H.ui.UI.createDefault(
      MapSettings.map,
      MapSettings.defaultLayers
    );
  },

  disposeMap: () => {
    MapSettings.map.dispose();
    window.removeEventListener(
      "resize",
      MapService.events.resize(MapSettings.map)
    );
    MapSettings.map.removeEventListener(
      "pointermove",
      MapService.events.pointerMove
    );
  },
};
