import React from "react";

export const LateOnTime = ({ timeDifference, statusTimeDifference }) => {
  const lateComponent = (
    <div className="late time-status">
      <p>
        {statusTimeDifference != null && statusTimeDifference != undefined
          ? statusTimeDifference
          : " --- "}{" "}
        +{timeDifference}H
      </p>
    </div>
  );

  const earlyComponent = (
    <div className="early time-status">
      <p>
        {statusTimeDifference != null && statusTimeDifference != undefined
          ? statusTimeDifference
          : " --- "}{" "}
        {timeDifference}H
      </p>
    </div>
  );

  const etaNotAvailable = (
    <div className="late time-status" style={{ display: "none" }}>
      <p>ETA Not Available</p>
    </div>
  );
  return (
    <>
      {statusTimeDifference != null && statusTimeDifference != undefined
        ? timeDifference > 0
          ? lateComponent
          : earlyComponent
        : etaNotAvailable}
    </>
  );
};
