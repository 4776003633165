import { createSlice } from "@reduxjs/toolkit";

export const reportsInitialState = {
  workspaceId: "",
  reportId: "",
  reportPageName: "",
  requestRefreshCustomViews: new Date(),
};

const reportsSlice = createSlice({
  name: "report",
  initialState: reportsInitialState,
  reducers: {
    setReport(state, action) {
      state.workspaceId = action.payload.workspaceId;
      state.reportId = action.payload.reportId;
      state.reportPageName = action.payload.reportPageName;
    },
    refreshCustomViews(state, action) {
      state.requestRefreshCustomViews = new Date();
    },
  },
});

export const { setReport, refreshCustomViews } = reportsSlice.actions;

export default reportsSlice.reducer;
