import basicRequest from "../basicRequest";
import { planningToolApiUrls } from "../../configs/planningToolApiUrls";

export const userConfigurationService = {
    getUserConfiguration: async () => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getUserConfiguration);
    },

    updateUserColumnState: async (columnStateJson: string) => {
        const request = new basicRequest();
        const payload = {
            columnState: columnStateJson
        };
        return await request.put(planningToolApiUrls.updateUserColumnState, payload);
    }
};
