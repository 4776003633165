import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { expectedDate } from "../OrderProgressRequested";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { detailAPI } from "../../../../../api/detail/detailAPI";
import PerfectScrollbar from "react-perfect-scrollbar";
import { formatDateToMin } from "../../../../../utils/DateUtils";

export const Card = ({ data, suData, isToCustomer }) => {
  const [sender, setSender] = useState({});
  const [consignee, setConsignee] = useState({});
  const [senderRemark, setSenderRemark] = useState(null);
  const [consigneeRemark, setConsigneeRemark] = useState(null);
  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
    useBothWheelAxes: false,
    suppressScrollX: true,
  };
  useEffect(() => {
    setSender(data.invParties.find(invParty => invParty.activity === "LOAD"));
    setConsignee(
      data.invParties.find(invParty => invParty.activity === "UNLOAD")
    );
  }, [data]);
  const buildInvPartyName = invPartyName => {
    return invPartyName ? (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{invPartyName}</Tooltip>}
      >
        <div style={{ maxWidth: "90%" }}>
          <h4 className="hideTextShowDots">{invPartyName}</h4>
        </div>
      </OverlayTrigger>
    ) : (
      <div>
        <h4 className="hideTextShowDots">--</h4>
      </div>
    );
  };

  const buildAddressItem = address => {
    return address ? (
      <OverlayTrigger placement="top" overlay={<Tooltip>{address}</Tooltip>}>
        <div style={{ maxWidth: "90%" }}>
          <span className="hideTextShowDots">{address}</span>
        </div>
      </OverlayTrigger>
    ) : (
      <div>
        <span className="hideTextShowDots">--</span>
      </div>
    );
  };

  function loadRemarks(type) {
    detailAPI.orderDetails.getTransportOrderDetailsRemarkById(
      data.toLegId,
      type == "SENDER" ? "LOAD" : "CONSIGNEE" ? "UNLOAD" : "",
      res => {
        var isMounted = true;

        if (isMounted) {
          if (res != null && res.length > 0) {
            var remarks = <></>;
            var date = null;

            for (var i = 0; i < res.length; i++) {
              if (i > 0)
                remarks = (
                  <>
                    {remarks}
                    <br />
                  </>
                );

              date =
                res[i].syS_LastUpdateTS != null
                  ? res[i].syS_LastUpdateTS
                  : res[i].syS_CreationTS;
              remarks = (
                <>
                  {remarks}
                  <div style={{ fontWeight: "bold" }}>
                    Nota {formatDateToMin(date)}
                  </div>
                </>
              );
              remarks = (
                <>
                  {remarks}
                  <div>{res[i].remarkDesc}</div>
                </>
              );
            }
          }
          remarks = (
            <>
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                style={{ maxHeight: "140px", position: "relative" }}
              >
                {remarks}
              </PerfectScrollbar>
            </>
          );

          if (type == "SENDER") setSenderRemark(remarks);
          else if (type == "CONSIGNEE") setConsigneeRemark(remarks);
        }
      }
    );
  }

  return (
    <div className="order-progress-card">
      <div className="order-progress-card-header">
        <div className="sender">
          <SVG
            src={toAbsoluteUrl(
              "/media/svg/new/order-progress/requested/card/departure.svg"
            )}
          />
          <h2>Sender</h2>
        </div>
        <div className="consignee">
          <SVG
            src={toAbsoluteUrl(
              "/media/svg/new/order-progress/requested/card/arrival.svg"
            )}
          />
          <h2>Consignee</h2>
          {data.event ? (
            <div className="event">
              <span>{data.event}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="order-progress-card-body">
        <div className="inv-party-name">
          <div className="sender">{buildInvPartyName(sender.invPartyName)}</div>
          <div className="consignee">
            {buildInvPartyName(consignee.invPartyName)}
          </div>
        </div>
        <div className="address">
          <div className="sender">{buildAddressItem(sender.address)}</div>
          <div className="consignee">{buildAddressItem(consignee.address)}</div>
        </div>
        <div className="city">
          <div className="sender">
            <span>
              {`${sender.city} `}{" "}
              {sender.provinceCode != null ? `(${sender.provinceCode})` : ""}
            </span>
          </div>
          <div className="consignee">
            <span>
              {`${consignee.city} `}
              {consignee.provinceCode != null
                ? `(${consignee.provinceCode})`
                : ""}
            </span>
          </div>
        </div>
        <div className="zip-code">
          <div className="sender">
            <span>{`${sender.zipCode} - ${sender.countryCode}`}</span>
          </div>
          <div className="consignee">
            <span>{`${consignee.zipCode} - ${consignee.countryCode}`}</span>
          </div>
        </div>
        <div className="dock">
          <div className="sender">
            <span>Dock: {sender.dock ?? <b>N/A</b>}</span>
          </div>
          <div className="consignee">
            <span>Dock: {consignee.dock ?? <b>N/A</b>}</span>
          </div>
        </div>
        <div className="expected">
          <div className="pickup">
            <h4>Expected pickup</h4>
            {expectedDate(sender.expectedEarlyDate, sender.expectedLateDate)}
          </div>
          {data.originSlotBooking != null && (
            <div
              style={{
                position: "absolute",
                marginTop: "2%",
                marginLeft: "28%",
              }}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/new/order-progress/requested/card/rebook.svg"
                )}
              />
            </div>
          )}
          <div className="delivery">
            <h4>Expected delivery</h4>
            {expectedDate(
              consignee.expectedEarlyDate,
              consignee.expectedLateDate
            )}
          </div>
          {data.destinationSlotBooking != null && (
            <div
              style={{
                position: "absolute",
                marginTop: "2%",
                marginLeft: "76%",
              }}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/new/order-progress/requested/card/rebook.svg"
                )}
              />
            </div>
          )}
        </div>
        <div className="other-info">
          <div className="sender">
            {sender.latitude && sender.longitude ? (
              <span className="ball">
                TMS{" "}
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/new/order-progress/requested/card/green-ball.svg"
                  )}
                />
              </span>
            ) : (
              <span className="ball">
                UOM{" "}
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/new/order-progress/requested/card/green-ball.svg"
                  )}
                />
              </span>
            )}
            {data.hasRemarkLoad && (
              <button
                style={{
                  marginLeft: "2%",
                  marginTop: "-1.25%",
                  borderStyle: "none",
                  background: "transparent",
                }}
                onClick={() => {
                  loadRemarks("SENDER");
                }}
              >
                <OverlayTrigger
                  placement="auto"
                  trigger={["click"]}
                  rootClose
                  overlay={({
                    placement,
                    scheduleUpdate,
                    arrowProps,
                    outOfBoundaries,
                    show,
                    ...props
                  }) => (
                    <div
                      style={props.style}
                      {...props}
                      className="custom-tooltip"
                    >
                      <div style={{ width: "250px", height: "140px" }}>
                        {senderRemark != null ? (
                          senderRemark
                        ) : (
                          <>
                            <div
                              className="loading-widget"
                              style={{ top: "0", left: "0" }}
                            >
                              <div className="loading-img"></div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                >
                  <SVG
                    style={{ cursor: "pointer" }}
                    src={toAbsoluteUrl(
                      "/media/svg/new/order-progress/requested/card/note.svg"
                    )}
                  />
                </OverlayTrigger>
              </button>
            )}
          </div>
          <div className="consignee">
            {consignee.latitude && consignee.longitude ? (
              <span className="ball">
                TMS{" "}
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/new/order-progress/requested/card/green-ball.svg"
                  )}
                />
              </span>
            ) : (
              <span className="ball">
                UOM{" "}
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/new/order-progress/requested/card/green-ball.svg"
                  )}
                />
              </span>
            )}
            {data.hasRemarkUnload && (
              <button
                style={{
                  marginLeft: "2%",
                  marginTop: "-1.25%",
                  borderStyle: "none",
                  background: "transparent",
                }}
                onClick={() => {
                  loadRemarks("CONSIGNEE");
                }}
              >
                <OverlayTrigger
                  placement="auto"
                  trigger={["click"]}
                  rootClose
                  overlay={({
                    placement,
                    scheduleUpdate,
                    arrowProps,
                    outOfBoundaries,
                    show,
                    ...props
                  }) => (
                    <div
                      style={props.style}
                      {...props}
                      className="custom-tooltip"
                    >
                      <div style={{ width: "250px", height: "140px" }}>
                        {consigneeRemark != null ? (
                          consigneeRemark
                        ) : (
                          <>
                            <div
                              className="loading-widget"
                              style={{ top: "0", left: "0" }}
                            >
                              <div className="loading-img"></div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                >
                  <SVG
                    style={{ cursor: "pointer" }}
                    src={toAbsoluteUrl(
                      "/media/svg/new/order-progress/requested/card/note.svg"
                    )}
                  />
                </OverlayTrigger>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="order-progress-card-footer">
        <div className="shipping-unit">
          <h4>Shipping unit</h4>
          <span>{data.totalCountShipUnit ?? "--"}</span>
        </div>
        {isToCustomer == true ? (
          <>
            <div className="gross-weight">
              <h4>{`Gross Weight${
                suData.grossWeightUOM ? ` (${suData.grossWeightUOM})` : ""
              }`}</h4>
              <span>
                {suData.grossWeight ? suData.grossWeight.toFixed(2) : "--"}
              </span>
            </div>
            <div className="volume">
              <h4>{`Volume${
                suData.volumeUOM ? ` (${suData.volumeUOM})` : ""
              }`}</h4>
              <span>{suData.volume ? suData.volume.toFixed(2) : "--"}</span>
            </div>
          </>
        ) : (
          <>
            <div className="gross-weight">
              <h4>{`Gross Weight${
                data.grossWeightUOM ? ` (${data.grossWeightUOM})` : ""
              }`}</h4>
              <span>
                {data.grossWeight ? data.grossWeight.toFixed(2) : "--"}
              </span>
            </div>
            <div className="volume">
              <h4>{`Volume${data.volumeUOM ? ` (${data.volumeUOM})` : ""}`}</h4>
              <span>{data.volume ? data.volume.toFixed(2) : "--"}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
