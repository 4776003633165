import { createSlice } from "@reduxjs/toolkit";

export const planningToolInitialState = {
  triageCount: null,
  groupCount: null,
  tripCount: null,
  userOrderErrorsCount: null,
  isOpenGroupsPopup: false,
};

const planningToolSlice = createSlice({
  name: "planningTool",
  initialState: planningToolInitialState,
  reducers: {
    setTriageCount(state, action) {
      state.triageCount = action.payload;
    },
    setGroupCount(state, action) {
      state.groupCount = action.payload;
    },
    setTripCount(state, action) {
      state.tripCount = action.payload;
    },
    setUserOrderErrorsCount(state, action) {
      state.userOrderErrorsCount = action.payload;
    },
    setIsOpenGroupsPopup(state, action) {
      state.isOpenGroupsPopup = action.payload;
    },
  },
});

export const {
  setTriageCount,
  setGroupCount,
  setTripCount,
  setUserOrderErrorsCount,
  setIsOpenGroupsPopup,
} = planningToolSlice.actions;

export default planningToolSlice.reducer;
