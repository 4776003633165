import { createSlice } from "@reduxjs/toolkit";

export const bookmarksInitialState = {
  newBookmark: false,
};

const bookmarksSlice = createSlice({
  name: "bookmark",
  initialState: bookmarksInitialState,
  reducers: {
    setNewBookmark(state, action) {
      state.newBookmark = action.payload;
    },
  },
});

export const { setNewBookmark } = bookmarksSlice.actions;

export default bookmarksSlice.reducer;
