import { CardConsts } from "../../../consts/cardConst";
import config from "../../../settings";

const orderIds = CardConsts.cardIDs.orders;
const tripIds = CardConsts.cardIDs.trips;

export default function mockCardConfig(mock) {
  mock
    .onPost(
      `${config.baseWebApiUrl}${config.orderCards.addOrUpdateCardConfiguration}`
    )
    .reply(config => {
      console.log("Adding or updating card configuration...");

      return [200];
    });

  mock
    .onGet(`${config.baseWebApiUrl}${config.orderCards.getCardConfiguration}`)
    .reply(() => {
      console.log("Fetching configuration");

      const data = {
        configurationString: [
          orderIds.toBeColl,
          orderIds.toBeDelivered,
          orderIds.inXdock,
          orderIds.outForColl,
          orderIds.missColl,
          orderIds.partDelivery,
        ].toString(),
      };

      return [200, data];
    });

  mock
    .onPost(
      `${config.baseWebApiUrl}${config.orderCards.removeCardConfiguration}`
    )
    .reply(config => {
      console.log("Removing card configuration...");

      return [200];
    });

  mock
    .onPost(
      `${config.baseWebApiUrl}${config.tripCards.addOrUpdateCardConfiguration}`
    )
    .reply(config => {
      console.log("Adding or updating card configuration...");

      return [200];
    });

  mock
    .onGet(`${config.baseWebApiUrl}${config.tripCards.getCardConfiguration}`)
    .reply(() => {
      console.log("Fetching configuration");

      const data = {
        configurationString: [
          tripIds.active,
          tripIds.booking,
          tripIds.trInTransit,
          tripIds.loadUnload,
          tripIds.gps,
          tripIds.noGps,
        ].toString(),
      };

      return [200, data];
    });

  mock
    .onPost(
      `${config.baseWebApiUrl}${config.tripCards.removeCardConfiguration}`
    )
    .reply(config => {
      console.log("Removing card configuration...");

      return [200];
    });
}
