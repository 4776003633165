import React from "react";
import Button from "../../../components/ui/button/Button";
import { customConfirmAlert } from "../../../utils/functions/customConfirmAlert";
import { Tooltip } from "primereact/tooltip";
type SaveButtonWithConfirmationProps = {
  onSave: () => void;
};

const SaveButtonWithConfirmation: React.FC<SaveButtonWithConfirmationProps> = ({
  onSave,
}) => {
  const handleSave = async () => {
    const isConfirmed = await customConfirmAlert({
      title: "Conferma Salvataggio",
      message: "Sei sicuro di voler salvare le impostazioni?",
    });

    if (isConfirmed) {
      onSave();
    }
  };

  return (
    <div>
      <Button className="save-button text-blue" fontSize={10} callback={handleSave}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2.5em"
          height="2.5em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h11.175q.4 0 .763.15t.637.425l2.85 2.85q.275.275.425.638t.15.762V19q0 .825-.587 1.413T19 21zm7-3q1.25 0 2.125-.875T15 15t-.875-2.125T12 12t-2.125.875T9 15t.875 2.125T12 18m-5-8h7q.425 0 .713-.288T15 9V7q0-.425-.288-.712T14 6H7q-.425 0-.712.288T6 7v2q0 .425.288.713T7 10"
          />
        </svg>
      </Button>
      <Tooltip
        position="top"
        target=".save-button"
        content="Save Grid Settings"
      ></Tooltip>
    </div>
  );
};

export default SaveButtonWithConfirmation;
