import React, { createContext, useEffect, useRef, useState } from "react";
import { Carrier, Equipment, IBusinessFlowType, IDepartment, IDeposit } from "../types/orders";
import { masterDataService } from "../api/masterDataService/masterDataService";
import { IPartner } from "../types/partner";
import { ITruckOrTrailer } from "../types/vehicles";
import { ApiRulesCombinationTripsResponse, IRuleCombinationTrip } from "../types/rulesCombinationsTrips";
import { computeRulesCombinationsTrips } from "../utils/functions/computeRulesCombinationsTrips";
import { connect } from "react-redux";
import { setGroupCount, setTriageCount, setTripCount, setUserOrderErrorsCount } from "../../redux/features/planning-tool/planningToolSlice";
import SignalRConnector from "../utils/signalr-connection";
import { IPushMessage, ITriageCounterMessage, IUserChargeCounterMessage, IUserOrderErrorsMessage, PushMessagesTypes } from "../types/pushMessages";
import { TripStatusOptionType } from "../types/trips";
import { toast } from "react-toastify";

export interface IMasterDataContext {
    businessFlowTypes: IBusinessFlowType[];
    setBusinessFlowTypes: React.Dispatch<React.SetStateAction<IBusinessFlowType[]>>;
    departments: IDepartment[];
    setDepartments: React.Dispatch<React.SetStateAction<IDepartment[]>>;
    equipments: Equipment[];
    setEquipments: React.Dispatch<React.SetStateAction<Equipment[]>>;
    deposits: IDeposit[];
    setDeposits: React.Dispatch<React.SetStateAction<IDeposit[]>>;
    carriers: Carrier[];
    setCarriers: React.Dispatch<React.SetStateAction<Carrier[]>>;
    partners: IPartner[];
    setPartners: React.Dispatch<React.SetStateAction<IPartner[]>>;
    trucks: ITruckOrTrailer[];
    setTrucks: React.Dispatch<React.SetStateAction<ITruckOrTrailer[]>>;
    trailers: ITruckOrTrailer[];
    setTrailers: React.Dispatch<React.SetStateAction<ITruckOrTrailer[]>>;
    rulesCombinationsTrips: IRuleCombinationTrip[][];    
    tripStatusOptions: TripStatusOptionType[];
    setTripStatusOptions: React.Dispatch<React.SetStateAction<TripStatusOptionType[]>>;
    updateDepartments: () => any;
    updateDeposits: (favorite?: boolean) => any;
    updatePartners: (favorite?: boolean) => any;
    updateCarriers: (favorite?: boolean) => any;
    updateVehicles: (favorite?: boolean) => any;    
}

export const MasterDataContext = createContext<null | IMasterDataContext>(null);

interface Props {
    children: React.ReactNode,
    setTriageCount: (count: number | null) => any;
    setTripCount: (count: number | null) => any;
    setUserOrderErrorsCount: (count: number | null) => any;
    setGroupCount: (count: number | null) => any;
}

const MasterDataProviderFn: React.FC<Props> = ({
    children,
    setTriageCount,
    setGroupCount,
    setUserOrderErrorsCount,
    setTripCount
}) => {
    const [businessFlowTypes, setBusinessFlowTypes] = useState<IBusinessFlowType[]>([]);
    const [departments, setDepartments] = useState<IDepartment[]>([]);
    const [equipments, setEquipments] = useState<Equipment[]>([]);
    const [deposits, setDeposits] = useState<IDeposit[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [partners, setPartners] = useState<IPartner[]>([]);
    const [trucks, setTrucks] = useState<ITruckOrTrailer[]>([]);
    const [trailers, setTrailers] = useState<ITruckOrTrailer[]>([]);
    const [tripStatusOptions, setTripStatusOptions] = useState<TripStatusOptionType[]>([]);
    const [rulesCombinationsTrips, setRulesCombinationsTrips] = useState<IRuleCombinationTrip[][]>([]);

    const { pushMessagesEvents } = SignalRConnector();

    const canGo = useRef(true);
    useEffect(() => {
        if (!!canGo && canGo.current === true) {
            canGo.current = false;

            updateBusinessFlowTypes();
            updateDepartments();
            updateEquipments();
            updateDeposits();
            updateCarriers();
            updatePartners();
            updateVehicles();
            updateTripStatusOptions();
            getSetRulesCombinationsTrips();
            getSetTriageCounter();
            getSetUserOrderErrorsCounter();
            getSetTripsAndGroupsCounter();
            subscribeToPushMessagesEvents();
        }
    }, []);

    const updateBusinessFlowTypes = async () => {
        const response = await masterDataService.getAllBusinessFlowTypes();
        if (response.success) {
            setBusinessFlowTypes(response.data);
        } else {
            toast.error('Error retrieving business flow types');
        }
    }

    const updateDepartments = async () => {
        const response = await masterDataService.getDepartments();
        if (response.success) {
            setDepartments(response.data);
        } else {
            toast.error('Error retrieving departments');
        }
    }

    const updateEquipments = async () => {
        const response = await masterDataService.getEquipments();
        if (response.success) {
            setEquipments(response.data.map((e: Equipment) => {
                return {
                    id: e.id,
                    name: e.name
                }
            }));
        } else {
            toast.error('Error retrieving equipments');
        }
    }

    const updateDeposits = async (favorite: boolean = true, page: number = 1, pageSize: number = 100) => {
        const response = await masterDataService.getDeposits(favorite, page, pageSize);
        if (response.success) {
            setDeposits(response.data);
        } else {
            toast.error('Error retrieving deposits');
        }
    }

    const updateCarriers = async (favorite: boolean = true, page: number = 1, pageSize: number = 100) => {
        const response = await masterDataService.getCarriers(favorite, page, pageSize);
        if (response.success) {
            setCarriers(response.data);
        } else {
            toast.error('Error retrieving carriers');
        }
    }

    const updatePartners = async (favorite: boolean = true, page: number = 1, pageSize: number = 100) => {
        const response = await masterDataService.getPartners(favorite, page, pageSize);
        if (response.success) {
            setPartners(response.data);
        } else {
            toast.error('Error retrieving partners');
        }
    };

    const updateVehicles = async (favorite: boolean = true, page: number = 1, pageSize: number = 100) => {
        // Trucks
        const responseTrucks = await masterDataService.getVehicles(favorite, false, page, pageSize);
        if (responseTrucks.success) {
            setTrucks(responseTrucks.data);
        } else {
            toast.error('Error retrieving vehicles');
        }

        // Trailers
        const responseTrailers = await masterDataService.getVehicles(favorite, true, page, pageSize);
        if (responseTrailers.success) {
            setTrailers(responseTrailers.data);
        }
    }

    const updateTripStatusOptions = async (favorite: boolean = true) => {
        const response = await masterDataService.getTripStatusOptions();
        if (response.success) {
            setTripStatusOptions(response.data);
        } else {
            toast.error('Error retrieving trips\' statuses');
        }
    }

    const getSetRulesCombinationsTrips = async () => {
        const response = await masterDataService.getTripRules();

        if (response.success) {
            const data: ApiRulesCombinationTripsResponse = response.data;
            const computedDataRulesCombinationsTrips: IRuleCombinationTrip[][] = computeRulesCombinationsTrips(data);
            setRulesCombinationsTrips(computedDataRulesCombinationsTrips);
        } else {
            toast.error('Error retrieving trips\' rules');
        }
    }

    const getSetTriageCounter = async () => {
        const response = await masterDataService.getTriageCount();

        if (response.success) {
            const data: { group: number, routing: number } = response.data;
            setTriageCount(data.group + data.routing);
        } else {
            toast.error('Error retrieving triage counter');
        }
    }

    const getSetUserOrderErrorsCounter = async () => {
        const response = await masterDataService.getUserOrderErrorsCount();

        if (response.success) {
            const data: { count: number } = response.data;
            setUserOrderErrorsCount(data.count);
        } else {
            toast.error('Error retrieving user order error counter');
        }
    };

    const subscribeToPushMessagesEvents = () => {
        pushMessagesEvents((msg) => {
            handleNewPushMessageReceived(msg);
        });
    };

    const handleNewPushMessageReceived = (msg: IPushMessage) => {
        if (msg.type === PushMessagesTypes.USER_CHARGE_COUNTER) {
            let newMsg = (msg as IUserChargeCounterMessage);
            setGroupCount(newMsg.groupCounter);
            setTripCount(newMsg.tripCounter);
        }

        if (msg.type === PushMessagesTypes.TRIAGE_COUNTER) {
            let newMsg = (msg as ITriageCounterMessage);
            setTriageCount(newMsg.group + newMsg.routing);
        }

        if (msg.type === PushMessagesTypes.USER_ORDER_ERRORS) {
            let newMsg = (msg as IUserOrderErrorsMessage);
            setUserOrderErrorsCount(newMsg.count);
        }
    };

    const getSetTripsAndGroupsCounter = async () => {
        const response = await masterDataService.getTripsAndGroupsCount();

        if (response.success) {
            const data: { groupCounter: number, tripCounter: number } = response.data;
            setTripCount(data.tripCounter);
            setGroupCount(data.groupCounter);
        } else {
            toast.error('Error retrieving trips and groups general info');
        }
    }

    const value: IMasterDataContext = {
        businessFlowTypes,
        setBusinessFlowTypes,
        departments,
        setDepartments,
        equipments,
        setEquipments,
        deposits,
        setDeposits,
        carriers,
        setCarriers,
        partners,
        setPartners,
        trucks,
        setTrucks,
        trailers,
        setTrailers,
        rulesCombinationsTrips,
        tripStatusOptions,
        setTripStatusOptions,

        // Updates funcions
        updateDeposits,
        updateDepartments,
        updatePartners,
        updateCarriers,
        updateVehicles
    };

    return (
        <MasterDataContext.Provider
            value={value}
        >
            {children}
        </MasterDataContext.Provider>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setTriageCount: (count: number) => {
            dispatch(setTriageCount(count));
        },
        setTripCount: (count: number) => {
            dispatch(setTripCount(count));
        },
        setGroupCount: (count: number) => {
            dispatch(setGroupCount(count));
        },
        setUserOrderErrorsCount: (count: number) => {
            dispatch(setUserOrderErrorsCount(count));
        }
    };
};

// @ts-ignore
export const MasterDataProvider = connect(null, mapDispatchToProps)(MasterDataProviderFn);