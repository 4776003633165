export enum AddressTypesEnum {
    Origin = "Origin",
    Destination = "Destination",
    Deposit = "Deposit",
    Partner = "Partner"
};

export enum AddressTypesIdsEnum {
    ToOrigin = 1,
    ToDestination = 2,
    Depot = 3,
    Partner = 4
}