import axios from "axios";
import config from "../../settings";
import {
  CustomerIdsToCarrierCodesSubstitution,
  AddClaimsToFilters,
} from "../translators/commonAPI.translators";

export const dataAPI = {
  getOrdersDelivered: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getValueOrderDeliveredUrl}`,
        filters
      )
      .then(data => {
        thenCallback(data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getPickedUpOrders: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getValuePickedUpOrdersUrl}`,
        filters
      )
      .then(data => {
        thenCallback(data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOrdersDeliveredInDelay: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getValueOrderDeliveredInDelayUrl}`,
        filters
      )
      .then(data => {
        thenCallback(data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getValueAnomaliesReported: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getValueAnomaliesReportedUrl}`,
        filters
      )
      .then(data => {
        thenCallback(data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getWarnings: (filters, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.getListWarningsUrl}`, filters)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getAllWarnings: (filter, thenCallback) => {
    axios
      .get(`${config.baseWebApiUrl}${config.getListAllWarningsUrl}`)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListAnomaliesReported: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListAnomaliesReported}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListAnomalies: (filters, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.getListAnomalies}`, filters)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTOs: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListTransportOrderUrl}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTOsFromIndex: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListTOListFromIndexUrl}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  GetTOsExecutionFromIndex: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListTOListFromIndexSSR}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTOsCustomerFromIndex: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListTOCustomerListFromIndexUrl}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTOsFromIndexSSR: (filters, thenCallback, callbackError) => {
    // ! NB: To uncomment when implementing SSR in TOsGrid
    // axios
    //     .post(
    //         `${config.baseWebApiUrl}${config.getListTOListFromIndexUrl}`,
    //         filters
    //     )
    //     .then((res) => {
    //         thenCallback(res.data);
    //     })
    //     .catch((err) => {
    //         callbackError(err);
    //         console.log(err);
    //     });
  },
  getListPickedUpOrders: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.GetListPickedUpOrdersUrl}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListDeliveredOrders: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListOrderDeliveredUrl}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListDelayDeliveredOrders: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListOrderDeliveredInDelayUrl}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListNotDeliveredOrdersWithEvents: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListOrderNotDeliveredWithEventsUrl}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListNotDeliveredOrdersWithoutEvents: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListOrderNotDeliveredWithoutEventsUrl}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListNotPickedupOrders: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListOrderNotPickedUpUrl}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListBookmarks: (userMail, thenCallback) => {
    axios
      .get(`${config.baseWebApiUrl}${config.getListBookmarks}`, {
        params: { userMail: userMail },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  addBookmark: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.addBookmark}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  removeBookmark: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.removeBookmark}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getIsBookmarkById: (params, thenCallback) => {
    axios
      .get(`${config.baseWebApiUrl}${config.getIsBookmarkById}`, {
        params: {
          searchItemId: params.searchItemId,
          type: params.type,
          userMail: params.userMail,
        },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getEmbedToken: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.getEmbedToken}`, params)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListMenuReports: thenCallback => {
    axios
      .get(`${config.baseWebApiUrl}${config.getListMenuReports}`)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListCustomersSettings: thenCallback => {
    axios
      .get(`${config.baseWebApiUrl}${config.getListCustomersSettings}`)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  GetColumnSuggestionTOCustomerSSR: (filters, thenCallback, callbackError) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getColumnSuggestionTOCustomerSSR}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        callbackError(err);
        console.log(err);
      });
  },
  addCustomers: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.addCustomers}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  updateCustomers: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.updateCustomers}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListUserGeneratedReports: thenCallback => {
    axios
      .get(`${config.baseWebApiUrl}${config.getListUserGeneratedReports}`)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  addReport: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.addReport}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  updateReport: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.updateReport}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  removeReport: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.removeReport}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListTmsCustomersByCustomerId: (params, thenCallback) => {
    axios
      .get(`${config.baseWebApiUrl}${config.getListTmsCustomersByCustomerId}`, {
        params,
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  removeTmsCustomer: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.removeTmsCustomer}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  addTmsCustomer: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.addTmsCustomer}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  updateTmsCustomer: (params, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.updateTmsCustomer}`, params)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListCustomerBusinessNames: thenCallback => {
    axios
      .get(`${config.baseWebApiUrl}${config.getListCustomerBusinessNames}`)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListTmsCustomerSystem: thenCallback => {
    axios
      .get(`${config.baseWebApiUrl}${config.getListTmsCustomerSystem}`)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOrderVariance: (filters, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.getOrderVariance}`, filters)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  // Order Card APIs
  getSalesOrderReceived: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getSalesOrderReceivedUrl}`,
        filters
      )
      .then(data => {
        thenCallback(data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getSOsFromIndex: (filters, thenCallback, callbackError) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListSalesOrderIndexSSR}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        callbackError(err);
        console.log(err);
      });
  },
  GetColumnSuggestionSalesOrderSSR: (filters, thenCallback, callbackError) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getColumnSuggestionSalesOrderSSR}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        callbackError(err);
        console.log(err);
      });
  },
  getValueStorage: (filters, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.orders.getValueStorage}`, filters)
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderToBeCollected: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderToBeCollected}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderNoCollectionLeg: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderNoCollectionLeg}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueCollectionInProgress: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueCollectionInProgress}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderToBeDelivered: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderToBeDelivered}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueCollectedNoDMIOnLegs: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueCollectedNoDMIOnLegs}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderInXdock: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderInXdock}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderOutForCollection: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderOutForCollection}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderOutForDelivery: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderOutForDelivery}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderMissedCollections: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderMissedCollections}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderWithoutDeliveryLeg: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderWithoutDeliveryLeg}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderMissedDeliveries: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderMissedDeliveries}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderPartialDeliveries: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderPartialDeliveries}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderInTransit: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderInTransit}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getValueOrderWithoutDataEntry: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getValueOrderWithoutDataEntry}`,
        filters
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getStorages: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListStorageUrl}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListSalesOrderReceived: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListSalesOrderReceivedUrl}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListSalesOrderReceivedFromIndex: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListSalesOrderReceivedFromIndexUrl}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListSalesOrderReceivedFromIndexSSR: (
    filters,
    thenCallback,
    callbackError
  ) => {
    // ! NB: To uncomment when implementing SSR on SalesOrdersGrid
    // axios
    //     .post(
    //         `${config.baseWebApiUrl}${config.orders.getListSalesOrderReceivedFromIndexUrlSSR}`,
    //         AddClaimsToFilters(filters)
    //     )
    //     .then((res) => {
    //         thenCallback(res.data);
    //     })
    //     .catch((err) => {
    //         callbackError(err);
    //         console.log(err);
    //     });
  },

  getListOrderToBeCollected: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderToBeCollected}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderNoCollectionLeg: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderNoCollectionLeg}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListCollectionInProgress: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListCollectionInProgress}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderToBeDelivered: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderToBeDelivered}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListCollectedNoDMIOnLegs: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListCollectedNoDMIOnLegs}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderInXdock: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderInXdock}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderOutForCollection: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderOutForCollection}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderOutForDelivery: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderOutForDelivery}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderMissedCollections: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderMissedCollections}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderWithoutDeliveryLeg: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderWithoutDeliveryLeg}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderMissedDeliveries: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderMissedDeliveries}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderPartialDeliveries: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderPartialDeliveries}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderInTransit: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderInTransit}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListOrderWithoutDataEntry: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.orders.getListOrderWithoutDataEntry}`,
        AddClaimsToFilters(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  // Trip Card APIs

  getListOngoingTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListOngoingsTripsUrl}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListOngoingsTripsIndex: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListOngoingsTripsIndexUrl}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListOngoingsTripsIndexSSR: (filters, thenCallback, callbackError) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListOngoingsTripsIndexSSRUrl}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        callbackError(err);
        console.log(err);
      });
  },
  GetColumnSuggestionTripsSSR: (filters, thenCallback, callbackError) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getColumnSuggestionTripsSSR}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        callbackError(err);
        console.log(err);
      });
  },
  GetColumnSuggestionTOExecutionSSR: (filters, thenCallback, callbackError) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getColumnSuggestionTOExecutionSSR}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        callbackError(err);
        console.log(err);
      });
  },
  getOnGoingTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getValueOngoingsTripsUrl}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(data => {
        thenCallback(data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getBookingTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getBookingTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getActiveTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getActiveTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getInTransitTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getInTransitTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getLoadingUnloadingTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getLoadingUnloadingTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getGPSTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getGPSTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getNoGPSTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getNoGPSTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListBookingTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getListBookingTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListActiveTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getListActiveTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListInTransitTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getListInTransitTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListLoadingUnloadingTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getListLoadingUnloadingTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListGPSTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getListGPSTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListNoGPSTrips: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.trips.getListNoGPSTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListEventMonitoringByCode: (params, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListEventMonitoringByCode}`,
        params
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListSuEventMonitoringByCodeToId: (params, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getListSuEventMonitoringByCodeToId}`,
        {
          params: {
            Limit: params.Limit,
            code: params.code,
            toId: params.toId,
          },
        }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListTabsEventMonitoring: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListTabsEventMonitoring}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getListSmartSearch: thenCallback => {
    axios
      .get(`${config.baseWebApiUrl}${config.getListSmartSearch}`)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getIsEnrichmentRunning: (filters, thenCallback) => {
    axios
      .post(`${config.baseWebApiUrl}${config.getIsEnrichmentRunning}`, filters)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getListCustomersByFilter: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.getListCustomersByFilter}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getDistinctListTmsSystem: thenCallback => {
    axios
      .get(`${config.baseWebApiUrl}${config.getDistinctListTmsSystem}`)
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
};
