import React from "react";
import { Route, Routes as DomRoutes } from "react-router-dom";
import AuthorizePage from "./pages/auth/AuthorizePage";
import TokenPage from "./pages/auth/TokenPage";
import LogoutPage from "./pages/auth/LogoutPage";
import config from "../settings";
import Redirect from "../planning-tool/components/redirect/Redirect";
import PublicLayout from "./layouts/PublicLayout";
import Page404 from "../planning-tool/pages/404/404";

// Planning Tool
import PlanningToolRoot from "../planning-tool/components/PlanningToolRoot";
import PlanningToolLayout from "../planning-tool/layouts/PlanningToolLayout";
import { planningToolRouteUrls } from "../planning-tool/configs/planningToolRouteUrls";
import PlanningToolPlanning from "../planning-tool/pages/planningToolPlanning/PlanningToolPlanning";
import PlanningToolDashboard from "../planning-tool/pages/planningToolDashboard/PlanningToolDashboard";
import PlanningToolGroupsCharge from "../planning-tool/pages/planningToolGroupsCharge/PlanningToolGroupsCharge";
import PlanningToolGroupsAssociation from "../planning-tool/pages/planningToolGroupsAssociation/PlanningToolGroupsAssociation";
import PlanningToolDefaultRoutingAssignment from "../planning-tool/pages/planningToolDefaultRoutingAssignment/PlanningToolDefaultRoutingAssignment";
import PlanningToolCutoff from "../planning-tool/pages/planningToolCutoff/PlanningToolCutoff";
import PlanningToolManageDepartments from "../planning-tool/pages/planningToolManageDepartments/PlanningToolManageDepartments";
import PlanningToolManageGroups from "../planning-tool/pages/planningToolManageGroups/PlanningToolManageGroups";
import AuthRoutePlanningTool from "../planning-tool/AuthRoutePlanningTool";
import {
  PermissionAreas,
  PermissionOperations,
} from "../planning-tool/types/permissions";
import PlanningToolTriage from "../planning-tool/pages/planningToolTriage/PlanningToolTriage";
import PlanningToolManageCharges from "../planning-tool/pages/planningToolManageCharges/PlanningToolManageCharges";
import PlanningToolExportExcel from "../planning-tool/pages/planningToolExportExcel/PlanningToolExportExcel";
import GeneralAuthLayout from "./layouts/GeneralAuthLayout";
import PlanningToolAuthLayout from "./layouts/PlanningToolAuthLayout";
import PlanningToolOrderInfo from "../planning-tool/pages/planningToolOrderInfo/PlanningToolOrderInfo";
import PlanningToolTripInfo from "../planning-tool/pages/planningToolTripInfo/PlanningToolTripInfo";
import PlanningToolTripCombination from "../planning-tool/pages/planningToolTripCombination/PlanningToolTripCombination";
import PlanningToolTripRules from "../planning-tool/pages/planningToolTripRules/PlanningToolTripRules";
import PlanningToolLeg1Combinations from "../planning-tool/pages/planningToolLegCombinations/PlanningToolLeg1Combinations";
import PlanningToolLeg2Combinations from "../planning-tool/pages/planningToolLegCombinations/PlanningToolLeg2Combinations";
import PlanningToolLeg3Combinations from "../planning-tool/pages/planningToolLegCombinations/PlanningToolLeg3Combinations";
import PlanningToolAreasConfiguration from "../planning-tool/pages/planningToolAreasConfiguration/PlanningToolAreasConfiguration";

export function Routes() {
  return (
    <DomRoutes>
      <Route path="*" element={<Page404 />} />

      {/* Redirect from root URL to /dashboard.  */}
      <Route exact path="/" element={<Redirect to="/planning-tool" />} />

      {/* 
                // PROTECTED ROUTES
                // OLD <BasePage /> Routes (Authorized Routes)
            */}

      <Route element={<GeneralAuthLayout />}>
        {/* Planning Tool */}
        <Route path="/planning-tool" element={<PlanningToolRoot />}>
          <Route element={<PlanningToolAuthLayout />}>
            <Route element={<PlanningToolLayout />}>
              <Route
                path=""
                element={<Redirect to="/planning-tool/dashboard" />}
              />

              <Route
                element={
                  <AuthRoutePlanningTool area={PermissionAreas.Dashboard} />
                }
              >
                <Route
                  index
                  path={planningToolRouteUrls.dashboard.compact}
                  element={<PlanningToolDashboard />}
                  errorElement={<div>Planning Tool Dashboard error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool area={PermissionAreas.TakeInCharge} />
                }
              >
                <Route
                  path={planningToolRouteUrls.groupsCharge.compact}
                  element={<PlanningToolGroupsCharge />}
                  errorElement={<div>Planning Tool Groups Charge error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool area={PermissionAreas.Planning} />
                }
              >
                <Route
                  path={planningToolRouteUrls.planning.compact}
                  element={<PlanningToolPlanning />}
                  errorElement={<div>Planning Tool Planning error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool area={PermissionAreas.Planning} />
                }
              >
                <Route
                  path={planningToolRouteUrls.planningVisualization.compact}
                  element={<PlanningToolPlanning />}
                  errorElement={<div>Planning Tool Planning error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool area={PermissionAreas.Planning} />
                }
              >
                <Route
                  path={planningToolRouteUrls.exportExcel.compact}
                  element={<PlanningToolExportExcel />}
                  errorElement={<div>Planning Tool Export Planning error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.GroupCondition}
                  />
                }
              >
                <Route
                  path={planningToolRouteUrls.groupsAssociation.compact}
                  element={<PlanningToolGroupsAssociation />}
                  errorElement={
                    <div>Planning Tool Groups Association error!</div>
                  }
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.RoutingCondition}
                  />
                }
              >
                <Route
                  path={planningToolRouteUrls.defaultRoutingAssignment.compact}
                  element={<PlanningToolDefaultRoutingAssignment />}
                  errorElement={
                    <div>Planning Tool Default Routing Assignment error!</div>
                  }
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool area={PermissionAreas.Cutoff} />
                }
              >
                <Route
                  path={planningToolRouteUrls.cutoff.compact}
                  element={<PlanningToolCutoff />}
                  errorElement={<div>Planning Tool Cutoff error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.ManageDepartment}
                  />
                }
              >
                <Route
                  path={planningToolRouteUrls.manageDepartments.compact}
                  element={<PlanningToolManageDepartments />}
                  errorElement={
                    <div>Planning Tool Manage Departments error!</div>
                  }
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool area={PermissionAreas.ManageGroup} />
                }
              >
                <Route
                  path={planningToolRouteUrls.manageGroups.compact}
                  element={<PlanningToolManageGroups />}
                  errorElement={<div>Planning Tool Manage Groups error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool area={PermissionAreas.Triage} />
                }
              >
                <Route
                  path={planningToolRouteUrls.triage.compact}
                  element={<PlanningToolTriage />}
                  errorElement={<div>Planning Tool Triage error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.TakeInCharge}
                    operation={PermissionOperations.ForceTakeOut}
                  />
                }
              >
                <Route
                  path={planningToolRouteUrls.manageCharges.compact}
                  element={<PlanningToolManageCharges />}
                  errorElement={<div>Planning Tool Manage Charges error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.InfoOrdersAndTrips}
                    operation={PermissionOperations.View}
                  />
                }
              >
                <Route
                  path={planningToolRouteUrls.orderInfo.compact}
                  element={<PlanningToolOrderInfo />}
                  errorElement={<div>Planning Tool Order Info error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.InfoOrdersAndTrips}
                    operation={PermissionOperations.View}
                  />
                }
              >
                <Route
                  path={planningToolRouteUrls.tripInfo.compact}
                  element={<PlanningToolTripInfo />}
                  errorElement={<div>Planning Tool Trip Info error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.ConfigurationHub_TripCombinations}
                  />
                }
              >
                <Route
                  path={
                    planningToolRouteUrls.configurationHub.tripCombinations
                      .compact
                  }
                  element={<PlanningToolTripCombination />}
                  errorElement={
                    <div>Planning Tool Trip Combinations error!</div>
                  }
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.ConfigurationHub_TripRules}
                  />
                }
              >
                <Route
                  path={
                    planningToolRouteUrls.configurationHub.tripRules.compact
                  }
                  element={<PlanningToolTripRules />}
                  errorElement={<div>Planning Tool Trip Rules error!</div>}
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.ConfigurationHub_LegCombinations}
                  />
                }
              >
                <Route
                  path={
                    planningToolRouteUrls.configurationHub.leg1Combinations
                      .compact
                  }
                  element={<PlanningToolLeg1Combinations />}
                  errorElement={
                    <div>Planning Tool Leg 1 Combinations error!</div>
                  }
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.ConfigurationHub_LegCombinations}
                  />
                }
              >
                <Route
                  path={
                    planningToolRouteUrls.configurationHub.leg2Combinations
                      .compact
                  }
                  element={<PlanningToolLeg2Combinations />}
                  errorElement={
                    <div>Planning Tool Leg 2 Combinations error!</div>
                  }
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.ConfigurationHub_LegCombinations}
                  />
                }
              >
                <Route
                  path={
                    planningToolRouteUrls.configurationHub.leg3Combinations
                      .compact
                  }
                  element={<PlanningToolLeg3Combinations />}
                  errorElement={
                    <div>Planning Tool Leg 3 Combinations error!</div>
                  }
                />
              </Route>

              <Route
                element={
                  <AuthRoutePlanningTool
                    area={PermissionAreas.AreasConfiguration}
                    operation={PermissionOperations.View}
                  />
                }
              >
                <Route
                  path={planningToolRouteUrls.areasConfiguration.compact}
                  element={<PlanningToolAreasConfiguration />}
                  errorElement={
                    <div>Planning Tool Areas Configuration error!</div>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* PUBLIC ROUTES */}
      <Route element={<PublicLayout />}>
        <Route path="/authorize" element={<AuthorizePage />} />
        <Route path={config.loginCallbackUrl} element={<TokenPage />} />
        <Route path="/logout" element={<LogoutPage />} />
      </Route>
    </DomRoutes>
  );
}
