import React, { useMemo } from "react";
// LayoutContext
import { useHtmlClassService } from "../../../_metronic/layout/_core/MetronicLayout";
// Import Layout components
import {
  HeaderMobile,
  Aside,
  Header,
  Footer,
  User,
  ScrollTop,
  LayoutInit,
} from "../../components";
// Import containers
import { SubHeaderContainer } from "../../containers";
import { useLocation } from "react-router-dom";

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
    };
  }, [uiService]);

  const location = useLocation();

  const contentPaddingClass = location.pathname.includes("planning-tool")
    ? "pt-1"
    : "";

  return (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          <Aside />
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={
                `content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid ` +
                contentPaddingClass
              }
            >
              <SubHeaderContainer />

              {/*begin::Entry*/}
              <div className="d-flex flex-column-fluid pl-10 pr-10">
                {/*begin::Container*/}
                <div className={layoutProps.contentContainerClasses}>
                  <React.Suspense
                    fallback={
                      <div>
                        <div className="loading-container"></div>
                        <div className="loading-widget">
                          <div className="loading-img"></div>
                        </div>
                      </div>
                    }
                  >
                    {/* CHILDREN */}
                    {children}
                  </React.Suspense>
                </div>
                {/*end::Container*/}
              </div>
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <User />
      <ScrollTop />
      {/*end::Main*/}
      <LayoutInit />
    </>
  );
}
