import axios, { AxiosInstance, AxiosResponse } from "axios";
import settings from "../settings";
import { Id, toast } from "react-toastify";

export type ResponseType = {
    success: boolean;
    error: any;
    data: any;
    detailObject?: any;
    statusCode: number;
    toastId: Id;
};

export enum RequestMethods {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
};

class basicRequest {
    instance: AxiosInstance;
    hasDefaultParams: boolean;
    timeoutOverride?: number;
    toastId: any;

    constructor({ hasDefaultParams = true, timeoutOverride = undefined }: { hasDefaultParams?: boolean, timeoutOverride?: number } = {}) {
        this.hasDefaultParams = !!hasDefaultParams ? hasDefaultParams : true;
        this.timeoutOverride = !!timeoutOverride ? timeoutOverride : 240000;

        // inizializzo axios in una variabile di classe
        let apiBaseUrl = settings.apiBaseUrl;
        let headers = {
            'Content-Type': 'application/json; charset=utf-8'
        };

        (headers as any)["Authorization"] = "Bearer " + window.sessionStorage.getItem('accessToken');

        this.instance = axios.create({
            baseURL: apiBaseUrl, // base url del server
            timeout: this.timeoutOverride, // MS,
            headers: headers
        });

        // Response interceptor for API calls
        this.instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                if (!error.response) {
                    return new Promise((resolve, reject) => {
                        reject(error);
                    });
                }

                throw error.response;
            }
        );
    }

    // funzione per ottenere accountId & contactId & lang
    getAccountParams = () => {
        return {
            // lang: i18n.resolvedLanguage            
        }
    };

    isPrimitiveObjectOrArray = (obj: any) => {
        if (Array.isArray(obj)) {
            return true;
        }
        if (typeof obj == "object" || typeof obj == "function") {
            return false;
        } else {
            return true;
        }
    };

    // Costruzione dei parametri querystring
    createParams = (params: any) => {
        let queryString = "";
        let accountParams: any = this.getAccountParams();
        if (!!accountParams) {
            Object.keys(accountParams).forEach(key => {
                if (!!accountParams[key]) {
                    queryString += `${key}=${accountParams[key]}&`;
                }
            });
        }

        if (!!params) {
            if (this.isPrimitiveObjectOrArray(params)) {
                let varName = Object.keys(params)[0];
                if (Array.isArray(params)) {
                    params.forEach((element: any) => {
                        queryString += `${varName}=${element}&`;
                    });
                } else {
                    queryString += `${varName}=${params}&`;
                }
            } else {
                Object.keys(params).forEach(key => {
                    if (params[key] !== undefined && params[key] !== null) {
                        if (Array.isArray(params[key])) {
                            params[key].forEach((element: any) => {
                                queryString += `${key}=${encodeURIComponent(element)}&`;
                            });
                        } else {
                            queryString += `${key}=${encodeURIComponent(params[key])}&`;
                        }
                    }
                });
            }
        }

        return '?' + queryString;
    }

    // Chiamata API CRUD
    // GET (READ)
    get = async (path: string, params: (any) = null, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null, axiosConfig?: any | null): Promise<ResponseType> => {
        return this.executeGenericRequest(RequestMethods.GET, path, undefined, params, loadingMessage, successMessage, errorMessage, partialSuccessMessage, axiosConfig);
    };

    // POST (Create)
    post = async (path: string, obj = {}, params: any = null, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null): Promise<ResponseType> => {
        return this.executeGenericRequest(RequestMethods.POST, path, obj, params, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    };

    // PUT (Update)
    put = async (path: string, obj = {}, params: any = null, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null): Promise<ResponseType> => {
        return this.executeGenericRequest(RequestMethods.PUT, path, obj, params, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    };

    // DELETE 
    delete = async (path: string, obj = {}, params: any = null, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null): Promise<ResponseType> => {
        return this.executeGenericRequest(RequestMethods.DELETE, path, obj, params, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    };

    private buildSuccessResponse = (response: AxiosResponse<any>, loadingMessage: string | null = null, successMessage: string | null = null, partialSuccessMessage: string | null = null) => {
        if (successMessage !== null) {
            if (response.status === 207) {
                if (partialSuccessMessage !== null) {
                    toast.update(this.toastId, { type: "warning", isLoading: false, render: partialSuccessMessage, autoClose: 3000 });
                } else {
                    toast.update(this.toastId, { isLoading: false, render: partialSuccessMessage, autoClose: 3000 });
                }
            } else {
                toast.update(this.toastId, { type: "success", isLoading: false, render: successMessage, autoClose: 3000 });
            }
        }
        return { success: true, data: response.data, statusCode: response.status, error: null, toastId: this.toastId }
    };

    private buildErrorResponse = (error: any, loadingMessage: string | null = null, errorMessage: string | null = null) => {
        console.log('error', error);

        if (error?.data?.Code !== null) {
            if (loadingMessage !== null) {
                toast.update(this.toastId, { type: "warning", isLoading: false, render: error?.data?.detail, autoClose: 3000 });
            }
        } else {
            if (errorMessage !== null) {
                toast.update(this.toastId, { type: "error", isLoading: false, render: errorMessage, autoClose: 3000 });
            } else {
                toast.error("There was an unexpected error");
            }
        }

        return { success: false, data: error.data, error: error, statusCode: error.status, toastId: this.toastId }
    }

    private executeGenericRequest = async (method: string, path: string, obj = {}, params: any = null, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null, axiosConfig: any | null = null) => {
        if (successMessage !== null) {
            this.toastId = toast.loading(loadingMessage);
        }

        switch (method.toLowerCase()) {
            case "get":
                return await this.instance.get(path + this.createParams(params), axiosConfig)
                    .then((response) => {
                        return this.buildSuccessResponse(response, loadingMessage, successMessage, partialSuccessMessage);
                    }).catch((error) => {
                        return this.buildErrorResponse(error, loadingMessage, errorMessage);
                    });
            case "post":
                return await this.instance.post(path + this.createParams(params), obj).then((response) => {
                    return this.buildSuccessResponse(response, loadingMessage, successMessage, partialSuccessMessage);
                }).catch((error) => {
                    return this.buildErrorResponse(error, loadingMessage, errorMessage);
                });
            case "put":
                return await this.instance.put(path + this.createParams(params), obj).then((response) => {
                    return this.buildSuccessResponse(response, loadingMessage, successMessage, partialSuccessMessage);
                }).catch((error) => {
                    return this.buildErrorResponse(error, loadingMessage, errorMessage);
                });
            case "delete":
                return await this.instance.delete(path + this.createParams(params), {
                    data: obj
                }).then((response) => {
                    return this.buildSuccessResponse(response, loadingMessage, successMessage, partialSuccessMessage);
                }).catch((error) => {
                    return this.buildErrorResponse(error, loadingMessage, errorMessage);
                });
            default:
                break;
        }

        return this.buildErrorResponse(undefined);
    };
}

export default basicRequest;