import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../_metronic/_helpers";
import { setNewBookmark } from "../../../redux/features/bookmarks/bookmarksSlice";
import { ordersNavigationActions } from "../../../redux/features/navigation/navigationSlice";
import { hasPlanningToolAccess } from "../../../utils/AuthUtils";
import { planningToolRouteUrls } from "../../../planning-tool/configs/planningToolRouteUrls";
import usePermissions from "../../../planning-tool/utils/hooks/usePermissions";
import {
  PermissionAreas,
  PermissionOperations,
} from "../../../planning-tool/types/permissions";

const AsideMenuListContainer = props => {
  const { layoutProps } = props;

  const location = useLocation();

  const getMenuItemActive = url => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const { hasPermission } = usePermissions();

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* PLANNING TOOL */}
        {hasPlanningToolAccess() && (
          <>
            {hasPermission(PermissionAreas.Dashboard) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.dashboard.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.dashboard.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-dashboard"></div>
                    <p>Dashboard</p>
                  </div>
                  <span className="menu-text">Dashboard</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.TakeInCharge) && (
              <li
                className={`menu-item group-charge ${getMenuItemActive(
                  planningToolRouteUrls.groupsCharge.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.groupsCharge.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-groups-charge"></div>
                    <p>Get a group</p>
                  </div>
                  <span className="menu-text">Get a group</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.ConfigurationHub) && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/configuration-hub", true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/planning-tool/configuration-hub">
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-configuration-hub"></div>
                    <p>Configuration Hub</p>
                  </div>
                  <span className="menu-text">Configuration Hub</span>
                  <i className="menu-arrow" />
                </NavLink>

                {/* Submenu */}
                <div className="menu-submenu">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav" style={{ paddingLeft: "20px" }}>
                    {hasPermission(PermissionAreas.ConfigurationHub_TripCombinations) && (
                      <li className="menu-item" aria-haspopup="true">
                        <NavLink
                          className="menu-link"
                          to={planningToolRouteUrls.configurationHub.tripCombinations.extended}
                        >
                          <span className="menu-text">Trip Combinations</span>
                        </NavLink>
                      </li>
                    )}
                    {hasPermission(PermissionAreas.ConfigurationHub_TripRules) && (
                      <li className="menu-item" aria-haspopup="true">
                        <NavLink
                          className="menu-link"
                          to={planningToolRouteUrls.configurationHub.tripRules.extended}
                        >
                          <span className="menu-text">Trip Rules</span>
                        </NavLink>
                      </li>
                    )}
                    {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations) && (
                      <li className="menu-item" aria-haspopup="true">
                        <NavLink
                          className="menu-link"
                          to={planningToolRouteUrls.configurationHub.leg1Combinations.extended}
                        >
                          <span className="menu-text">Leg 1 Combinations</span>
                        </NavLink>
                      </li>
                    )}
                    {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations) && (
                      <li className="menu-item" aria-haspopup="true">
                        <NavLink
                          className="menu-link"
                          to={planningToolRouteUrls.configurationHub.leg2Combinations.extended}
                        >
                          <span className="menu-text">Leg 2 Combinations</span>
                        </NavLink>
                      </li>
                    )}
                    {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations) && (
                      <li className="menu-item" aria-haspopup="true">
                        <NavLink
                          className="menu-link"
                          to={planningToolRouteUrls.configurationHub.leg3Combinations.extended}
                        >
                          <span className="menu-text">Leg 3 Combinations</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
            {hasPermission(PermissionAreas.Planning) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.exportExcel.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.exportExcel.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-export-excel"></div>
                    <p>Export Planning</p>
                  </div>
                  <span className="menu-text">Export Planning</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.Triage) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.triage.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.triage.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-triage"></div>
                    <p>Triage</p>
                  </div>
                  <span className="menu-text">Triage</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.RoutingCondition) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.defaultRoutingAssignment.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.defaultRoutingAssignment.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-routing-assignment"></div>
                    <p>Manage Routing</p>
                  </div>
                  <span className="menu-text">Manage Routing</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.GroupCondition) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.groupsAssociation.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.groupsAssociation.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-groups-association"></div>
                    <p>Manage Groups Association</p>
                  </div>
                  <span className="menu-text">Manage Groups Association</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.Cutoff) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.cutoff.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.cutoff.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-cutoff"></div>
                    <p>Manage Cutoff</p>
                  </div>
                  <span className="menu-text">Manage Cutoff</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.ManageDepartment) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.manageDepartments.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.manageDepartments.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-manage-departments"></div>
                    <p>Manage Departments</p>
                  </div>
                  <span className="menu-text">Manage Departments</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.ManageGroup) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.manageGroups.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.manageGroups.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-manage-groups"></div>
                    <p>Manage Groups</p>
                  </div>
                  <span className="menu-text">Manage Groups</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.AreasConfiguration, PermissionOperations.View) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.areasConfiguration.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.areasConfiguration.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-areas-configuration"></div>
                    <p>Areas Configuration</p>
                  </div>
                  <span className="menu-text">Areas Configuration</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.ForceTakeOut) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.manageCharges.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.manageCharges.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-manage-charges"></div>
                    <p>Manage Charges</p>
                  </div>
                  <span className="menu-text">Manage Charges</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.InfoOrdersAndTrips, PermissionOperations.View) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.orderInfo.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.orderInfo.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-order-info"></div>
                    <p>Order Info</p>
                  </div>
                  <span className="menu-text">Order Info</span>
                </NavLink>
              </li>
            )}
            {hasPermission(PermissionAreas.InfoOrdersAndTrips, PermissionOperations.View) && (
              <li
                className={`menu-item ${getMenuItemActive(
                  planningToolRouteUrls.tripInfo.extended
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={planningToolRouteUrls.tripInfo.extended}>
                  <div className="menu-icon-container">
                    <div className="menu-icon menu-svg-trip-info"></div>
                    <p>Trip Info</p>
                  </div>
                  <span className="menu-text">Trip Info</span>
                </NavLink>
              </li>
            )}
          </>
        )}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    bookmark: state.bookmark.newBookmark,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewBookmark: value => {
      dispatch(setNewBookmark(value));
    },
    setNavigatedMenu: menu => {
      dispatch(ordersNavigationActions.setNavigatedMenu(menu));
    },
  };
};

export const AsideMenuList = connect(
  mapStateToProps,
  mapDispatchToProps
)(AsideMenuListContainer);
