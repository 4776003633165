import React from 'react'

const AllTextColumn: React.FC<{ text: string | undefined }> = ({
    text
}) => {
    return (
        <div className="flex items-center text-[13px] leading-[17px] py-2">
            <p className="text-[12px]">{text}</p>
        </div>
    )
}

export default AllTextColumn;