import React from "react";
import { toAbsoluteUrl } from "../_metronic/_helpers";

export function formatContentCardOrdersReceived() {
  return (
    <>
      <p>
        Based on the selected timespan and customer(s) the number of received
        (and cancelled) orders are shown.
        <br />
        The <u>timespan</u> refers to the date the order was received by Arcese.
        The number does not change if the order has been imported in the TMS.
      </p>
    </>
  );
}

export function formatContentCardPickedUpOrders() {
  return (
    <>
      <img
        src={toAbsoluteUrl("/media/png/tooltips/pickedup.png")}
        alt="Picked up orders formula"
      ></img>
      <p>
        The <u>timespan</u>, in this case, refers to the requested pick-up date
        &amp; (latest) time window of the order.
        <br />
        The <u>customer</u>(s) depend on the filters above.
      </p>
    </>
  );
}

export function formatContentCardDeliveredOrders() {
  return (
    <>
      <img
        src={toAbsoluteUrl("/media/png/tooltips/delivered.png")}
        alt="Delivered orders formula"
      ></img>
      <p>
        The <u>timespan</u>, in this case, refers to the requested delivery date
        &amp; (latest) time window of the order.
        <br />
        The <u>customer</u>(s) depend on the filters above.
      </p>
    </>
  );
}

export function formatContentCardDeliveredOrdersInDelay() {
  return (
    <>
      <p>
        This number represents the amount of delivered orders (previous widget)
        that have been delivered in delay. Only orders with a requested delivery
        date can be taken into account.
      </p>
    </>
  );
}

export function formatContentCardTrips() {
  return (
    <>
      <p>
        Total number of trips on which the legs of an order certain timespan and
        selected customers are present.
        <br />
        <br />
        Total number of trips performed in a selected <u>timespan</u> (departure
        date). The <u>customer</u> can be filtered to display the trips of your
        customer's orders.
      </p>
    </>
  );
}

export function formatContentDebugTooltip() {
  return (
    <>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        molestie pellentesque vulputate. Vivamus luctus at mauris vel finibus
      </p>
    </>
  );
}

export function formatContentActiveTripsCard() {
  return (
    <>
      <p>
        Active trips have a status that ranges from “Start Loading” (Inizio
        Carico) until “Start Unloading” (Inizio Scarico). The “Arrived”
        (Arrivato) and “Start Unloading” (Inizio Scarico) status are only taken
        into account for trips destined to an Arcese depot.
      </p>
    </>
  );
}
export function formatContentBookingTripsCard() {
  return (
    <>
      <p>
        Only Active trips that have at least one “Confirmed Booking” event (at
        loading and/or at unloading) are taken into account.
      </p>
    </>
  );
}
export function formatContentInTransitTripsCard() {
  return (
    <>
      <p>Only trips with last status “Departed” (Partito) are shown.</p>
    </>
  );
}
export function formatContentAtLoadingUnloadingTripsCard() {
  return (
    <>
      <p>
        All active trips which are not “In Transit” are shown. Therefore, only
        the statuses that refer to the Loading or Unloading phase are shown.
      </p>
    </>
  );
}
export function formatContentGPSTripsCard() {
  return (
    <>
      <p>
        Trips that are “In Transit” (last status “Departed” [Partito]) for which
        a Telematics position has been received in the last 60 minutes.
      </p>
    </>
  );
}
export function formatContentNoGPSTripsCard() {
  return (
    <>
      <p>
        Trips that are “In Transit” (last status “Departed” [Partito]) but the
        Telematics position is either absent or the last position is older than
        60 minutes.
      </p>
    </>
  );
}

export function formatContentCardAnomaliesReported() {
  return (
    <>
      <p>
        The number represents the amount of anomaly events (code "ERR") which
        have been inserted in the orders that belong to the selected{" "}
        <u>customer</u>(s) within the filtered timespan.
        <br />
        The <u>timespan</u> date, in this case, refers to the insert date of the
        anomaly.
      </p>
    </>
  );
}

export function formatContentCardSO() {
  return (
    <>
      <p>
        Based on the selected timespan, owner branch(es) and customer(s) this
        indicator shows the number of received (and cancelled) Sales Orders. The
        number does not refer to how many orders were imported in the TMS.
        <br />
        The timespan refers to the date the orders was received by Arcese.
      </p>
    </>
  );
}

export function formatContentBarCharts() {
  return (
    <>
      <p>
        The amount of not delivered orders (or not picked up orders) are
        represented in a bar chart. Distinction is made between orders without
        events and orders with events.
        <br />
        <br />
        The <u>timespan</u>, for not delivered orders, refers to the requested
        delivery date &amp; (latest) time window of the order.
        <br />
        <br />
        The <u>timespan</u>, for not picked-up orders, refers to the requested
        pick-up date &amp; (latest) time window of the order.
        <br />
        <br />
        Orders are filtered based on the selected <u>customer</u>(s) in the
        filter bar.
      </p>
    </>
  );
}

export function formatContentWarningsGrid() {
  return (
    <>
      <p>
        All warnings generated on TOs and Trips are shown in this screen.
        <br />
        <br />
        The <u>timespan</u> refers to the opening date of the warning (e.g.
        'Current Date' will show all warnings generated today).
        <br />
        <br />
        Orders are filtered based on the selected <u>customer</u>(s) in the
        filter bar.
      </p>
    </>
  );
}

export function formatContentAnomaliesGrid() {
  return (
    <>
      <p>
        This view contains all anomalies (event code "ERR") which have been
        inserted in the orders that belong to the selected <u>customer</u>(s)
        within the filtered timespan.
        <br />
        <br />
        The <u>timespan</u> date, in this case, refers to the insert date of the
        anomaly.
        <br />
        <br />
        The anomalies are not shown anymore when the TO has been delivered.
      </p>
    </>
  );
}

export function formatContentEventMonitoringGrid() {
  return (
    <>
      <p>
        This view contains all elemets (event code "ERR") which have been
        inserted in the orders that belong to the selected <u>customer</u>(s)
        within the filtered timespan.
        <br />
        <br />
        The <u>timespan</u> date, in this case, refers to the insert date of the
        element.
      </p>
    </>
  );
}

export function formatContentStoragesGrid() {
  return (
    <>
      <p>
        Based on the selected timespan, owner branch(es) and customer(s) this
        indicator shows the number of storages that are present.
        <br />
        <br />
        The timespan refers to the date the storage was created.
      </p>
    </>
  );
}

export function formatContentTOExecutionGrid() {
  return (
    <>
      <p>
        This view contains all Transport Orders (TOs) with their Collection ID
        (nr. ritiro) and TMS Order ID (nr. spedizione) together with the main
        order details.
        <br />
        <br />
        The <u>timespan</u> refers to the Date Type selected in the filter.
        <br />
        <br />
        Orders are filtered based on the selected <u>customer</u>(s).
      </p>
    </>
  );
}

export function formatContentTOCustomerGrid() {
  return (
    <>
      <p>
        This view contains all Transport Orders Customer (TOsC) with their
        Collection ID (nr. ritiro) and TMS Order ID (nr. spedizione) together
        with the main order details.
        <br />
        <br />
        The <u>timespan</u> refers to the Date Type selected in the filter.
        <br />
        <br />
        Orders are filtered based on the selected <u>customer</u>(s).
      </p>
    </>
  );
}

export function formatContentSOGrid() {
  return (
    <>
      <p>
        This view contains all Sales Orders (SOs).
        <br />
        <br />
        The <u>timespan</u> refers to the Date Type selected in the filter.
        <br />
        <br />
        Orders are filtered based on the selected <u>customer</u>(s).
      </p>
    </>
  );
}

export function formatContentAllWarningsGrid() {
  return (
    <>
      <p>All warnings generated on TOs and Trips are shown in this screen.</p>
    </>
  );
}

export function formatContentBookmarksPage() {
  return (
    <>
      <p>
        All TO and Trips that have been added to the bookmark section appear in
        this screen.
        <br />
        You can edit the description of the bookmark or delete the bookmark from
        the list by clicking the red label at the end of each row.
      </p>
    </>
  );
}

export function formatContentProfileCustomersButton() {
  return (
    <>
      <p>
        In the "Manage Customers" section you can activate or deactivate the
        customers you want to manage in the Order Management portal.
      </p>
    </>
  );
}

export function formatContentProfileCVButton() {
  return (
    <>
      <p>
        In the "Manage Custom views" section you can manage the custom views for
        your customers.
      </p>
    </>
  );
}

export function formatContentSearchBar() {
  return (
    <>
      <div>
        In the search bar you can look up the following values:
        <ul>
          <li>Shipment (TO)</li>
          <li>Shipment References</li>
          <li>Shipment Transport Document</li>
          <li>Trip</li>
          <li>Sales Order</li>
          <li>Customer Handling Unit Barcode</li>
        </ul>
        Also, you can add some keywords:
        <ul>
          <li>
            <b>#LASTXX</b> to filter within the last XX days TOs and Trips
            depending on their TmsCompetenceDate
          </li>
        </ul>
      </div>
    </>
  );
}

export function formatContentCardOrdersToBeCollected() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders where a "Collected" event is still missing
          in the selected timespan (which checks the Pick-up date)
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardOrdersWithoutCollectionLeg() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders for which a collection leg is still missing
          in the selected timespan (which checks the Pick-up date)
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardOrdersCollectionInProgress() {
  return (
    <>
      <p>
        Based on the selected timespan, owner branch(es) and customer(s) this
        indicator shows the number of Transport Orders for which the status is
        "Collection Planned", "Out for Collection" or "Missed Collection".
        <br />
        <br />
        The timespan checks the Pick-up date or Pick-up date window.
      </p>
    </>
  );
}

export function formatContentCardOrdersToBeDelivered() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders not delivered in the selected timespan
          (which checks the Delivery date)
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardOrdersWithoutDMI() {
  return (
    <>
      <p>
        Based on the selected timespan, owner branch(es) and customer(s) this
        indicator shows the number of Transport orders that have been collected
        but the collection leg is not managed by Basiano branch AND no shuttle
        to Basiano is present.
        <br />
        <br />
        The timespan checks the (late) Pick-up date.
      </p>
    </>
  );
}

export function formatContentCardOrdersInXdock() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders that are currently in a crossdock
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardOrdersOutForCollection() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders that are out for collection in the selected
          timespan (which checks the Pick-up date)
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardOrdersOutForDelivery() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders that are out for delivery in the selected
          timespan (which checks the Delivery date)
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardOrdersMissColl() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders that have "MISSED COLLECTION" in the
          selected timespan
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardOrdersWithoutDelLeg() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders for which a delivery leg is still missing
          in the selected timespan (which checks the Delivery date)
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardMissDel() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders that have "MISSED DELIVERY" in the selected
          timespan
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardOrdersParDel() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders partially delivered in the selected
          timespan (which checks the Delivery date)
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardOrdersInTransit() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders that are in transit towards a crossdock
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardWithoutDE() {
  return (
    <>
      <p>
        This indicator shows the{" "}
        <strong>
          number of Transport Orders for which a shipment has not yet been
          created in the selected timespan (which checks the Delivery date)
        </strong>{" "}
        - based on the selected owner branch(es) and customer(s).
      </p>
    </>
  );
}

export function formatContentCardCarico() {
  return (
    <>
      <p>
        The shown data only refers to customers managed on the Order Management
        platform.
        <br />
        Actual load data may differ.
      </p>
    </>
  );
}

export function formatContentSearchTooltipLs() {
  return (
    <>
      <p>Search with shipment or reference number.</p>
    </>
  );
}

export function formatContentSODetailsOrderVersion() {
  return (
    <>
      The Sales Order version can be:
      <br />
      <ul>
        <li>
          <b>Original</b> if the Sales Order has no associated revisions and is
          not cancelled
        </li>
        <li>
          <b>Updated (number of available revisions)</b> if the Sales Order was
          updated, thus its previous revisions are available in the UDM
        </li>
        <li>
          <b>Cancelled</b> if the Sales Order was cancelled
        </li>
      </ul>
    </>
  );
}

export function formatContentTripGrid() {
  return (
    <>
      <p>
        This view contains all Trips with their Trip ID (nr. viaggio) together
        with the main trip details.
        <br />
        <br />
        The <u>timespan</u> refers to the Date Type selected in the filter.
        <br />
        <br />
        Trips are filtered based on the selected <u>customer</u>(s) and{" "}
        <u>carrier</u>(s)
      </p>
    </>
  );
}
