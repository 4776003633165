import axios from "axios";
import config from "../../settings";
import { CustomerIdsToCarrierCodesSubstitution } from "../translators/commonAPI.translators";

export const mapAPI = {
  // API for coords for selected trips card
  getActiveTripsForMap: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataTripsForMap.getActiveTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getBookingTripsForMap: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataTripsForMap.getBookingTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getInTransitTripsForMap: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataTripsForMap.getInTransitTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getLoadUnloadTripsForMap: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataTripsForMap.getLoadUnloadTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getGPSTripsForMap: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataTripsForMap.getGPSTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getNoGPSTripsForMap: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataTripsForMap.getNoGPSTrips}`,
        CustomerIdsToCarrierCodesSubstitution(filters)
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  // API for marker popup trip data
  getTripDataForPopupMap: (tripId, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.dataTripsForMap.getTripDataPopup}`,
        { params: { tripId: tripId } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  // API for coords for selected orders card
  getMapOrderToBeCollected: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getMapOrderToBeCollected}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderNoCollectionLeg: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderNoCollectionLeg}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getCollectionInProgress: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getCollectionInProgress}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderToBeDelivered: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderToBeDelivered}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getCollectedNoDMIOnLegs: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getCollectedNoDMIOnLegs}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderInXdock: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderInXdock}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderWithoutDataEntry: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderWithoutDataEntry}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderOutForCollection: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderOutForCollection}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderOutForDelivery: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderOutForDelivery}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderMissedCollections: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderMissedCollections}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderWithoutDeliveryLeg: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderWithoutDeliveryLeg}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderMissedDeliveries: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderMissedDeliveries}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderPartialDeliveries: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderPartialDeliveries}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getOrderInTransit: (filters, thenCallback) => {
    axios
      .post(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderInTransit}`,
        filters
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  // API for marker popup order data
  getOrderDataForPopupMap: (toId, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.dataOrdersForMap.getOrderDataPopup}`,
        { params: { toId: toId } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
};
