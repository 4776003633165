import React, { useEffect } from "react";
import config from "../../../settings";

export default function LogoutPage() {
  useEffect(() => {
    var idToken = window.sessionStorage.getItem("idToken");

    window.sessionStorage.clear();

    // Costruisco chiamata verso Authorize endpoint
    var logoutUrl =
      config.baseIdpUrl +
      config.endSessionIdpUrl +
      "?id_token_hint=" +
      idToken +
      "&post_logout_redirect_uri=" +
      encodeURIComponent(window.location.origin + config.logoutCallbackUrl);

    window.location.href = logoutUrl;
  }, []);

  return <></>;
}
