export type ObjParamsBaseDate = {
    operator?: null | 'AND' | 'OR',
    conditions: {
        type?: "equals" | "before" | "after" | "between" | "blank",
        value?: any,
        dateFrom?: Date,
        dateTo?: Date
    }[]
}

export const getFilterParamsDateSSR = (colId: 'earlyPickup' | 'latePickup' | 'earlyDelivery' | 'lateDelivery', filterModel: any) => {
    if(!filterModel[colId]) {
        return null;
    }

    let objBase: ObjParamsBaseDate = {
        operator: null,
        conditions: []
    }

    if (!!filterModel[colId].conditions && filterModel[colId].conditions.length > 0 && !!filterModel[colId].operator) {
        objBase.operator = filterModel[colId].operator;
        objBase.conditions = filterModel[colId].conditions.map((condition: any) => {
            return {
                type: condition.type,
                value: condition.filter,
            }
        })
    } else {
        objBase.conditions = [{
            value: filterModel[colId].filter,
            dateFrom: filterModel[colId].dateFrom,
            dateTo: filterModel[colId].dateTo,
            type: filterModel[colId].type
        }];
    }

    return objBase;
}