import React from "react";
import Button from "../../../components/ui/button/Button";

type ColumnVisibilityDialogProps = {
  isDialogOpen: boolean;
  toggleDialog: () => void;
  visibilityOptions: React.ReactNode;
};

const ColumnVisibilityDialog: React.FC<ColumnVisibilityDialogProps> = ({
  isDialogOpen,
  toggleDialog,
  visibilityOptions,
}) => {
  return (
    <>
      <div>
        <button
          type="button"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            borderRadius: "8px",
            border: "1px solid #D1D5DB",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
            padding: "8px 16px",
            backgroundColor: "white",
            fontSize: "14px",
            fontWeight: "500",
            color: "#4B5563",
            cursor: "pointer",
          }}
          onClick={toggleDialog}
        >
          <span style={{ flexGrow: 1 }}>Show/Hide Columns</span>
          <svg
            style={{ height: "20px", width: "20px", marginLeft: "8px" }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              transform="rotate(90 12 12)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 15l7-7 7 7"
            />
          </svg>
        </button>
      </div>
      {isDialogOpen && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 235,
            minWidth: 300,
          }}
        >
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h4 className="text-lg font-bold mb-4">
              Select Columns to Show/Hide
            </h4>
            {visibilityOptions}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <Button fontSize={13} callback={toggleDialog}>
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ColumnVisibilityDialog;
