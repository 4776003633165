import React, { createContext, useEffect, useRef, useState } from "react";
import { IOrder, Leg } from "../types/orders";
import { ITrip } from "../types/trips";
import { AdvancedFiltersType, tripsService } from "../api/tripsService/tripsService";
import { ResponseType } from "../api/basicRequest";
import { toast } from "react-toastify";

type TripFilterOptionType = {
    value: number;
    label: string;
    type: "all" | "my" | "groups" | "owned";
}

export const TRIP_PAGINATION_COUNT = 5;

const tripFilterOptions: TripFilterOptionType[] = [
    {
        value: 1,
        label: "All trips",
        type: "all"
    },
    {
        value: 2,
        label: "Created by me",
        type: "my"
    },
    {
        value: 3,
        label: "With LEG by my groups",
        type: "groups"
    },
    {
        value: 4,
        label: "Owned by me",
        type: "owned"
    }
];

export type UpdatedLeg = {
    orderId: number;
    legs: Leg[];
}
export interface ITripsContext {
    currentTripDraft: null | IOrder[];
    setCurrentTripDraft: React.Dispatch<React.SetStateAction<IOrder[] | null>>;
    updatedLegs: null | UpdatedLeg[];
    setUpdatedLegs: React.Dispatch<React.SetStateAction<UpdatedLeg[] | null>>;
    trips: ITrip[] | null;
    setTrips: React.Dispatch<React.SetStateAction<ITrip[] | null>>;
    activeTripId: number | undefined | null;
    setActiveTripId: React.Dispatch<React.SetStateAction<number | undefined | null>>;
    setDepartmentId: React.Dispatch<React.SetStateAction<number | null>>;
    activeTrip: ITrip | null;
    updateTrips: (type: 'all' | 'my' | 'groups' | 'owned', searchFilter?: string) => Promise<ResponseType>;
    isOpenSidebarTrips: boolean;
    setIsOpenSidebarTrips: React.Dispatch<React.SetStateAction<boolean>>;
    tripFilterOptions: TripFilterOptionType[];
    isVisibleSidebarTrips: boolean;
    setIsVisibleSidebarTrips: React.Dispatch<React.SetStateAction<boolean>>;

    isOpenSidebarDev: boolean;
    setIsOpenSidebarDev: React.Dispatch<React.SetStateAction<boolean>>;

    // Filters and pagination
    querySearchTripRef: React.MutableRefObject<string>;
    setQuerySearchTripRef: (searchFilter: string) => void;
    currentTripOptionValueRef: React.MutableRefObject<number>;
    setCurrentTripOptionValueRef: (optionValue: number) => void;
    advancedFiltersRef: React.MutableRefObject<AdvancedFiltersType>;
    setAdvancedFiltersRef: (advancedFilters: AdvancedFiltersType) => void;
    loadingTripsRef: React.MutableRefObject<boolean>;
    setLoadingTripsRef: (isLoading: boolean) => void;
    currentPageRef: React.MutableRefObject<number>;
    setCurrentPageRef: (pageNumber: number) => void;
    lengthLastRequestRef: React.MutableRefObject<number | null>;
    setLengthLastRequestRef: (pageNumber: number | null) => void;

    forceRefreshOrders: boolean | undefined;
    refreshOrders: () => void;
    refreshTripWithParams: (optionVal?: string | number, querySearchFilter?: string, advancedFilters?: AdvancedFiltersType) => Promise<void>;
    updateTripWithParamsAndPagination: () => Promise<void>;
    updateTripWithCurrentParamsAndPagination: () => Promise<void>;
}

export const TripsContext = createContext<null | ITripsContext>(null);

interface Props {
    children: React.ReactNode
}

export const advancedFiltersInitObj = {
    pickupDate: undefined,
    deliveryDate: undefined,
    tripDate: (new Date()).toISOString().split('T')[0],
    sender: undefined,
    senderCity: undefined,
    consignee: undefined,
    consigneeCity: undefined,
    maxShippingUnits: undefined,
    minShippingUnits: undefined,
    description: undefined,
    generalId: undefined,
    tripStatus: undefined
}

export const TripsProvider: React.FC<Props> = ({ children }) => {
    const [currentTripDraft, setCurrentTripDraft] = useState<null | IOrder[]>(null);
    const [updatedLegs, setUpdatedLegs] = useState<null | UpdatedLeg[]>(null);
    const [trips, setTrips] = useState<ITrip[] | null>(null);
    const [activeTripId, setActiveTripId] = useState<null | undefined | number>(undefined);
    const [isOpenSidebarTrips, setIsOpenSidebarTrips] = useState(false);
    const [forceRefreshOrders, setForceRefreshOrders] = useState<boolean | undefined>(undefined);
    const [isVisibleSidebarTrips, setIsVisibleSidebarTrips] = useState<boolean>(false);
    const [departmentId, setDepartmentId] = useState<number | null>(null);

    const [isOpenSidebarDev, setIsOpenSidebarDev] = useState(false);

    // ---- START Filters and pagination handling ---- //
    // Now here is managed only the pagination inside sidebatTrips
    const [loadingTrips, setLoadingTrips] = useState<boolean>(false);
    const loadingTripsRef = React.useRef(loadingTrips);
    const setLoadingTripsRef = (isLoading: boolean) => {
        loadingTripsRef.current = isLoading;
        setLoadingTrips(isLoading);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const currentPageRef = React.useRef(currentPage);
    const setCurrentPageRef = (data: number) => {
        currentPageRef.current = data;
        setCurrentPage(data);
    };

    const [lengthLastRequest, setLengthLastRequest] = useState<number | null>(null);
    const lengthLastRequestRef = React.useRef(lengthLastRequest);
    const setLengthLastRequestRef = (data: null | number) => {
        lengthLastRequestRef.current = data;
        setLengthLastRequest(data);
    };

    const [querySearchTrip, setQuerySearchTrip] = useState<string>('');
    const querySearchTripRef = React.useRef<string>(querySearchTrip);
    const setQuerySearchTripRef = (searchString: string) => {
        querySearchTripRef.current = searchString;
        setQuerySearchTrip(searchString);
    };

    const [currentTripOptionValue, setCurrentTripOptionValue] = useState(2);
    const currentTripOptionValueRef = React.useRef<number>(currentTripOptionValue);
    const setCurrentTripOptionValueRef = (value: number) => {
        currentTripOptionValueRef.current = value;
        setCurrentTripOptionValue(value);
    };

    const [advancedFilters, setAdvancedFilters] = useState<AdvancedFiltersType>(advancedFiltersInitObj);

    const advancedFiltersRef = React.useRef<AdvancedFiltersType>(advancedFilters);
    const setAdvancedFiltersRef = (advancedFilters: AdvancedFiltersType) => {
        advancedFiltersRef.current = advancedFilters;
        setAdvancedFilters(advancedFilters);
    };
    // ---- END Filters and pagination handling ---- //

    const activeTrip = React.useMemo(() => {
        if (trips !== null) {
            const active = trips.find(t => t.id === activeTripId);
            return !!active ? active : null;
        }

        return null;
    }, [activeTripId, trips]);

    const canGo = useRef(true);
    useEffect(() => {
        if (!!canGo && !!canGo.current) {
            canGo.current = false;
            updateTrips();
        }
    }, []);

    useEffect(() => {
        refreshTripWithParams();
    }, [departmentId]);

    const updateTrips = async (type: 'all' | 'my' | 'groups' | 'owned' = "my", searchFilter: string = '', advancedFilters: AdvancedFiltersType | null = null, page: number = 1, isPagination: boolean = false) => {
        const response = await tripsService.getTrips(type, searchFilter, advancedFilters !== null ? advancedFilters : advancedFiltersInitObj, departmentId, page);
        if (response.success) {
            setLengthLastRequestRef(response.data.length);
            const newData = !isPagination ? response.data : !!trips ? [...trips, ...response.data] : [];
            setTrips(newData);
            setCurrentPageRef(page + 1);
        } else {
            toast.error('Error retrieving trips');
        }

        return response;
    }

    /** ---- MANAGE UPDATE TRIPS ---- */
    // This funcion needs to be called when the pagination restarts from scratch (from page 1) - eg: when changes one of the current values:
    // - currentOptionValueRef 
    // - querySearch
    // - advancedFilters
    const refreshTripWithParams = async (optionVal?: string | number, querySearchFilter?: string, advancedFilters?: AdvancedFiltersType) => {
        const optionValLocal = optionVal !== undefined ? optionVal : currentTripOptionValueRef.current;
        const querySearchFilterLocal = querySearchFilter !== undefined ? querySearchFilter : querySearchTripRef.current;
        const advancedFiltersLocal = advancedFilters !== undefined ? advancedFilters : advancedFiltersRef.current;

        const option = tripFilterOptions.find(tO => tO.value === parseInt(optionValLocal.toString()));
        if (!!option) {
            setLoadingTripsRef(true);
            const response = await updateTrips(option.type, querySearchFilterLocal, advancedFiltersLocal, 1);
            setLoadingTripsRef(false);

            if (!response.success) {
                console.error('error');
            }
        }
    };

    const canGoNext = () => {
        return (lengthLastRequestRef.current === null || (lengthLastRequestRef.current !== null && (lengthLastRequestRef.current >= TRIP_PAGINATION_COUNT)))
    }

    // This function handles the progression of pagination
    const updateTripWithParamsAndPagination = async () => {
        if (!loadingTripsRef.current && canGoNext()) {
            setLoadingTripsRef(true);
            const option = tripFilterOptions.find(tO => tO.value === parseInt(currentTripOptionValueRef.current.toString()));
            const response = await updateTrips(option?.type, querySearchTripRef.current, advancedFiltersRef.current, currentPageRef.current, true);
            setLoadingTripsRef(false);

            if (!response.success) {
                console.error('error');
            }
        }
    }

    // This function refreshes the data of trips, but mantaining the pagination 
    const updateTripWithCurrentParamsAndPagination = async () => {
        setLoadingTripsRef(true);
        const option = tripFilterOptions.find(tO => tO.value === parseInt(currentTripOptionValueRef.current.toString()));
        const tripsLength = !!trips ? trips.length : TRIP_PAGINATION_COUNT;

        if (!!option) {
            const response = await tripsService.getTrips(option.type, querySearchTripRef.current, advancedFiltersRef.current, departmentId, 1, tripsLength);
            if (response.success) {
                const lastRequestLength = ((tripsLength % TRIP_PAGINATION_COUNT === 0) ? TRIP_PAGINATION_COUNT : (tripsLength % TRIP_PAGINATION_COUNT));
                const currentPage = Math.ceil(tripsLength as number / (TRIP_PAGINATION_COUNT as number))
                setLengthLastRequestRef(lastRequestLength);
                setTrips(response.data);
                setCurrentPageRef(currentPage + 1);
            } else {
                toast.error('Error retrieving trips');
            }
        }

        setLoadingTripsRef(false);
    };

    const refreshOrders = () => {
        setForceRefreshOrders(forceRefreshOrders === undefined ? true : !forceRefreshOrders);
    };

    const value: ITripsContext = {
        currentTripDraft,
        setCurrentTripDraft,
        updatedLegs,
        setUpdatedLegs,
        trips,
        setTrips,
        activeTripId,
        setActiveTripId,
        activeTrip,
        setDepartmentId,
        updateTrips,
        isOpenSidebarTrips,
        setIsOpenSidebarTrips,
        isVisibleSidebarTrips,
        setIsVisibleSidebarTrips,

        isOpenSidebarDev,
        setIsOpenSidebarDev,

        // Filters for trips
        tripFilterOptions,
        currentTripOptionValueRef,
        loadingTripsRef,
        querySearchTripRef,
        setCurrentTripOptionValueRef,
        advancedFiltersRef,
        setAdvancedFiltersRef,
        setLoadingTripsRef,
        setQuerySearchTripRef,
        currentPageRef,
        setCurrentPageRef,
        lengthLastRequestRef,
        setLengthLastRequestRef,

        forceRefreshOrders,
        refreshOrders,
        refreshTripWithParams,
        updateTripWithParamsAndPagination,
        updateTripWithCurrentParamsAndPagination
    };

    return (
        <TripsContext.Provider
            value={value}
        >
            {children}
        </TripsContext.Provider>
    );
};