import React from "react";
import Select from "../../ui/select/Select";
import Button from "../../ui/button/Button";
import styles from "./SelectedTripsComponentsStyle";

interface SelectedTripsComponentProps {
  selectedTrips: any[];
  actions: { value: string; label: string }[];
  selectedAction: string | null;
  setSelectedAction: (action: string) => void;
  handleActionOnSelectedTrips: () => void;
}

const SelectedTripsComponent: React.FC<SelectedTripsComponentProps> = ({
  selectedTrips,
  actions,
  selectedAction,
  setSelectedAction,
  handleActionOnSelectedTrips
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.tripsInfo}>
          <p style={styles.textBlue}>SELECTED TRIPS:{" "}</p>
          <p style={styles.tripsCount}>{selectedTrips.length}</p>
        </div>
        <div style={styles.actionsContainer}>
          <Select
            options={actions}
            placeholder="Select an action"
            value={selectedAction}
            defaultValue={""}
            callbackOnChange={(e: any) => setSelectedAction(e.target.value)}
            disabled={selectedTrips.length === 0}
          />
          <Button
            disabled={!selectedAction || selectedTrips.length === 0}
            callback={handleActionOnSelectedTrips}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectedTripsComponent;
