import React, { useCallback, useMemo, useRef, useState } from "react";
import Select from "../../components/ui/select/Select";
import Button from "../../components/ui/button/Button";
import { GridApi, RowNode, ValueSetterParams } from "@ag-grid-community/core";
import CommonTable, { TableRef } from "../../components/commonTable/CommonTable";
import AuthorizedComponent from "../../components/permissions/AuthorizedComponent";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import usePermissions from "../../utils/hooks/usePermissions";
import { toast } from "react-toastify";
import { tripsService } from "../../api/tripsService/tripsService";
import { LegTypesEnum } from "../../types/leg";
import { TripCombination } from "../../types/trips";
import { AddressTypesIdsEnum } from "../../types/address";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import ModalShowTripCombinationsConflicts from "./ModalShowTripCombinationsConflicts";

const PlanningToolTripCombination: React.FC = () => {

    const { hasPermission } = usePermissions();

    // #region State
    const [inAdding, setInAdding] = useState<boolean>(false);
    const [conflictRows, setConflictRows] = useState<TripCombination[]>([]);
    const [openModalShowConflicts, setOpenModalShowConflicts] = useState<boolean>(false);

    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo
    const legTypesOptions = useMemo(() => {
        return [
            { value: LegTypesEnum.Collection.valueOf(), label: "Collection" },
            { value: LegTypesEnum.Delivery.valueOf(), label: "Delivery" },
            { value: LegTypesEnum.Direct.valueOf(), label: "Direct" },
            { value: LegTypesEnum.Shuttle.valueOf(), label: "Shuttle" }
        ];
    }, []);

    const addressTypesOptions = useMemo(() => {
        return [
            { value: AddressTypesIdsEnum.ToOrigin.valueOf(), label: "To Origin" },
            { value: AddressTypesIdsEnum.ToDestination.valueOf(), label: "To Destination" },
            { value: AddressTypesIdsEnum.Depot.valueOf(), label: "Depot" },
            { value: AddressTypesIdsEnum.Partner.valueOf(), label: "Partner" }
        ];
    }, []);

    const legCountryITOptions = useMemo(() => {
        return [
            { value: 1, label: "IT" },
            { value: 0, label: "NON IT" }
        ];
    }, []);

    const immediateDispatchOptions = useMemo(() => {
        return [
            { value: 1, label: "YES" },
            { value: 0, label: "NO" }
        ];
    }, []);

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs: any = useMemo(() => [
        {
            field: 'combinationCode',
            headerName: "COMBINATION",
            editable: false,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: any) => {
                var numA = Number(valueA.replace("C", ""));
                var numB = Number(valueB.replace("C", ""));
                if (numA == numB) return 0;
                return (numA > numB) ? 1 : -1;
            }
        },
        {
            field: 'legTypeId',
            headerName: "LEG TYPE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: legTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => legTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeNumericValue(e.target.value, props.node, props.api, "legTypeId") }}
                                options={legTypesOptions}
                                value={props.data.legTypeId}
                                defaultOptionEnabled={true}
                                placeholder={"Select Leg Type"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <>{legTypesOptions.find(x => x.value === props.data.legTypeId)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'legOriginId',
            headerName: "LEG ORIGIN",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: addressTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => addressTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeNumericValue(e.target.value, props.node, props.api, "legOriginId") }}
                                options={addressTypesOptions}
                                value={props.data.legOriginId}
                                defaultOptionEnabled={true}
                                placeholder={"Select Leg Origin"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <>{addressTypesOptions.find(x => x.value === props.data.legOriginId)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'legDestinationId',
            headerName: "LEG DESTINATION",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: addressTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => addressTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeNumericValue(e.target.value, props.node, props.api, "legDestinationId") }}
                                options={addressTypesOptions}
                                value={props.data.legDestinationId}
                                defaultOptionEnabled={true}
                                placeholder={"Select Leg Destination"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <>{addressTypesOptions.find(x => x.value === props.data.legDestinationId)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'legOriginIT',
            headerName: "LEG ORIGIN COUNTRY",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: legCountryITOptions.map(x => x.value === 1 ? true : false),
                valueFormatter: (params: any) => legCountryITOptions.find(x => (x.value === 1 ? true : false) === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination, node: RowNode, api: GridApi }) => {
                let selectedValue = props.data.legOriginIT != undefined ? (props.data.legOriginIT ? 1 : 0) : "";
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeCountryIT(e.target.value, props.node, props.api, "legOriginIT") }}
                                options={legCountryITOptions}
                                value={selectedValue}
                                defaultOptionEnabled={true}
                                placeholder={"Select Leg Origin Country"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <>{legCountryITOptions.find(x => (x.value === 1 ? true : false) === props.data.legOriginIT)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'legDestinationIT',
            headerName: "LEG DESTINATION COUNTRY",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: legCountryITOptions.map(x => x.value === 1 ? true : false),
                valueFormatter: (params: any) => legCountryITOptions.find(x => (x.value === 1 ? true : false) === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination, node: RowNode, api: GridApi }) => {
                let selectedValue = props.data.legDestinationIT != undefined ? (props.data.legDestinationIT ? 1 : 0) : "";
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeCountryIT(e.target.value, props.node, props.api, "legDestinationIT") }}
                                options={legCountryITOptions}
                                value={selectedValue}
                                defaultOptionEnabled={true}
                                placeholder={"Select Leg Destination Country"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <>{legCountryITOptions.find(x => (x.value === 1 ? true : false) === props.data.legDestinationIT)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'immediateDispatch',
            headerName: "IMMEDIATE DISPATCH",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: immediateDispatchOptions.map(x => x.value === 1 ? true : false),
                valueFormatter: (params: any) => immediateDispatchOptions.find(x => (x.value === 1 ? true : false) === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination, node: RowNode, api: GridApi }) => {
                let selectedValue = props.data.immediateDispatch != undefined ? (props.data.immediateDispatch ? 1 : 0) : "";
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeImmediateDispatch(e.target.value, props.node, props.api) }}
                                options={immediateDispatchOptions}
                                value={selectedValue}
                                defaultOptionEnabled={true}
                                placeholder={"Select Immediate Dispatch"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify) &&
                            <>{immediateDispatchOptions.find(x => (x.value === 1 ? true : false) === props.data.immediateDispatch)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'actions',
            headerName: "ACTIONS",
            editable: false,
            filter: false,
            minWidth: 150,
            hide: !hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify),
            cellRenderer: (props: { data: TripCombination, node: RowNode }) => {
                return (
                    <>
                        {
                            <div className="flex">
                                {
                                    props.node.rowPinned === "top" &&
                                    <div
                                        onClick={onClickCancelNewRow}
                                        className={"flex text items-center cursor-pointer"}
                                    >
                                        <span className="mr-[8px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#3987E2" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z" /></svg>
                                        </span>
                                        <span className="hover:underline not-italic ">Remove</span>
                                    </div>
                                }
                            </div>
                        }
                    </>
                )
            },
        }
    ], []);
    // #endregion

    // #region UI Handlers
    const handleChangeNumericValue = async (value: any, node: RowNode, api: GridApi, column: string) => {
        value = Number(value);

        node.setDataValue(column, value);
    };

    const handleChangeCountryIT = async (value: any, node: RowNode, api: GridApi, column: string) => {
        value = value != "" ? (value == 1 ? true : false) : undefined;

        node.setDataValue(column, value);
        node.data[column] = value;
        api.redrawRows({ rowNodes: [node] });
    };

    const handleChangeImmediateDispatch = async (value: any, node: RowNode, api: GridApi) => {
        value = value != "" ? (value == 1 ? true : false) : undefined;

        node.setDataValue("immediateDispatch", value);
        node.data["immediateDispatch"] = value;
        api.redrawRows({ rowNodes: [node] });
    };

    const onClickAddNewCombination = () => {
        addNewCombination();
    };

    const onClickDeleteButton = async () => {
        await confirmDeleteCombinations();
    };

    const onClickSaveButton = async () => {
        await confirmSaveCombinations();
    };

    const onClickCancelNewRow = () => {
        cancelNewRow();
    };
    // #endregion

    // #region Util Functions
    const getTripCombinations = async () => {
        commonTableRef.current?.showLoadingOverlay();
        const response = await tripsService.getTripCombinations();
        commonTableRef.current?.hideOverlay();

        if (response.success) {
            commonTableRef.current?.setRowData(response.data);
        } else {
            toast.error('Error retrieving trip combinations');
        }
    };

    const addNewCombination = () => {
        let newRow: any = {};
        newRow.rowId = undefined;
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const confirmDeleteCombinations = async () => {
        let selectedRows: TripCombination[] = commonTableRef.current?.getSelectedRows() as TripCombination[];
        let idsToRemove: string[] = selectedRows.map(x => {
            return x.combinationCode as string;
        });

        if (idsToRemove.length > 0) {
            const wantToDelete = await customConfirmAlert({
                message: "Are you sure to delete the selected combinations?",
                title: "Delete combinations"
            });

            if (wantToDelete) {
                await deleteConditions(idsToRemove);
                commonTableRef.current?.redrawTableRows();
            }
        } else {
            toast.info("No combinations selected");
        }
    };

    const deleteConditions = async (idsToRemove: string[]) => {
        let selectedRows: TripCombination[] = commonTableRef.current?.getSelectedRows() as TripCombination[];

        const response = await tripsService.deleteTripCombinations(idsToRemove, "Removing combinations...", "Combinations deleted successfully", "Error while deleting combinations");
        if (response.success) {
            commonTableRef.current?.deleteTableRows(selectedRows);
        }
    };

    const confirmSaveCombinations = async () => {
        commonTableRef.current?.setStopEditing();
        const wantToSave = await customConfirmAlert({
            message: "Are you sure to save the combinations?",
            title: "Save combinations"
        });

        if (wantToSave) {
            await saveCombinations();
        }
    };

    const saveCombinations = async () => {
        let tableRows = commonTableRef.current?.getTableRows() as TripCombination[];
        let newRowToInsert = commonTableRef.current?.getNewRowToInsert();
        tableRows = newRowToInsert !== undefined ? [newRowToInsert, ...tableRows] : tableRows;

        if (tableRows.length === 0) {
            toast.info("No combinations to save");
            return;
        }

        // Check some value null
        let foundNullValue = false;
        Object.keys(new TripCombination()).forEach(field => {
            if (tableRows.some((row: any) => {
                let value = row[field];
                if (value === "" || value === null) {
                    return true;
                }

                if (field === "departmentId" || field === "groupId") {
                    if (value === undefined || value === 0) {
                        return true;
                    }
                }
                return false
            })) {
                foundNullValue = true;
            }
        });

        if (foundNullValue) {
            toast.warning("There are some null values");
            return;
        }

        const response = await tripsService.updateTripCombinations(tableRows, "Saving combinations...", "Combinations saved successfully", "Error while saving combinations");
        if (response.success) {
            commonTableRef.current?.cancelNewRowInsertion();
            commonTableRef.current?.setUnsavedCells([]);
            commonTableRef.current?.setRowsInError([]);

            setConflictRows([]);
            setInAdding(false);
            getTripCombinations();
        } else if (response.statusCode === 400) {
            if (!!response.data.detailObject) {
                commonTableRef.current?.setRowsInError(response.data.detailObject);
            }
        } else if (response.statusCode === 409) {
            setConflictRows(!!response.data.detailObject ? response.data.detailObject : []);
        }

        setTimeout(function () { commonTableRef.current?.redrawTableRows() }, 0)
    };

    const customValueSetter = (params: ValueSetterParams) => {
        let newValue = params.newValue;
        let field = params.colDef.field as string;
        params.data[field] = newValue;

        return true;
    };

    const cancelNewRow = () => {
        commonTableRef.current?.cancelNewRowInsertion();
        setInAdding(false);
    };
    // #endregion

    // #region CallBack
    const onGridReady = useCallback(() => {
        getTripCombinations();
    }, []);
    // #endregion

    // #region Use Effects

    // #endregion

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Trip Combinations
                </div>
            </div>

            <AuthorizedComponent area={PermissionAreas.ConfigurationHub_TripCombinations} operation={PermissionOperations.Modify}>
                <div className="mb-6 flex items-center">
                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px]"
                        callback={onClickAddNewCombination}
                        disabled={inAdding}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3333 9.16602V9.66602H10.8333H15.3333V10.3327H10.8333H10.3333V10.8327V15.3327H9.66667V10.8327V10.3327H9.16667H4.66667V9.66602H9.16667H9.66667V9.16602V4.66602H10.3333V9.16602Z" fill="#3987E2" stroke="white" />
                        </svg>
                    </Button>

                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px] ml-[12px]"
                        callback={onClickDeleteButton}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z" /></svg>
                    </Button>

                    <div className="ml-[12px]">
                        <Button
                            callback={onClickSaveButton}
                            className="bg-transparent text-black border-[1.4px] border-black"
                        >
                            Save configuration
                        </Button>
                    </div>

                    {
                        conflictRows.length > 0 &&
                        <div className="ml-7 flex cursor-pointer" onClick={() => setOpenModalShowConflicts(true)}>
                            <div className="border-red pt-[10px] pl-[15px] pr-[15px] pb-[10px] border-[3px] rounded-[10px] flex">
                                <svg className="text-red" xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 10 10"><path fill="currentColor" d="M10 5A5 5 0 1 0 0 5a5 5 0 0 0 10 0M9 5a4 4 0 0 1-6.453 3.16L8.16 2.547C8.686 3.224 9 4.076 9 5M7.453 1.84L1.84 7.453A4 4 0 0 1 7.453 1.84" /></svg>
                                <span className="ml-3">Some trip combinations are overlapping</span>
                            </div>
                        </div>
                    }
                </div>
            </AuthorizedComponent>

            <div style={containerStyle}>
                <CommonTable
                    ref={commonTableRef}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    valueSetter={customValueSetter}
                    options={{
                        pageSize: 10,
                        showSelectionCheckbox: hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify),
                        showRowsNumber: true,
                        editable: hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify)
                    }}
                />
            </div>

            {openModalShowConflicts === true &&
                <ModalShowTripCombinationsConflicts
                    callbackCloseModal={() => setOpenModalShowConflicts(false)}
                    conflicts={conflictRows}
                />
            }
        </div>
    )
}

export default PlanningToolTripCombination