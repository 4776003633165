import { createSlice } from "@reduxjs/toolkit";

export const smartSearchInitialState = {
  smartSearchKeywordList: [],
};

const smartSearchSlice = createSlice({
  name: "smartSearch",
  initialState: smartSearchInitialState,
  reducers: {
    setSmartSearchKeywordList(state, action) {
      state.smartSearchKeywordList = action.payload;
    },
  },
});

export const { setSmartSearchKeywordList } = smartSearchSlice.actions;

export default smartSearchSlice.reducer;
