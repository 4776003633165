import React from "react";
import ReactDOM from "react-dom";
import { PositionElement } from "../view/components/details-tabs/details-common/PositionElement";
import { WayPointTooltip } from "../view/components/details-tabs/details-common/WayPointTooltip";
import { LateOnTime } from "../view/components/late-ontime/LateOnTime";
import { toAbsoluteUrl } from "../_metronic/_helpers";
import { expectedDate } from "../view/components/order-progress/requested/OrderProgressRequested";
import moment from "moment/moment";

export const DetailsMapService = {
  events: {
    resize: map => map.getViewPort().resize(),

    hoverCallback: (map, ui, element, evt) => {
      ui.getBubbles().forEach(bub => ui.removeBubble(bub));

      var geometry = evt.target.getGeometry();
      var pos = { lat: geometry.lat, lng: geometry.lng };
      map.setCenter(pos, true);

      let bubble = new window.H.ui.InfoBubble(evt.target.getGeometry(), {
        content: element,
      });

      ui.addBubble(bubble);
    },

    positionHoverCallback: (map, ui, position, evt, positionElements) => {
      ui.getBubbles().forEach(bub => ui.removeBubble(bub));
      var geometry;

      if (evt.target.lat !== undefined && evt.target.lng !== undefined) {
        geometry = { lat: evt.target.lat, lng: evt.target.lng };
      } else {
        geometry = evt.target.getGeometry();
      }

      var pos = { lat: geometry.lat, lng: geometry.lng };
      map.setCenter(pos, true);

      const positionElement = positionElements.find(el => {
        return el.lat === position.latitude && el.lng === position.longitude;
      });
      let bubble;

      if (positionElement) {
        bubble = new window.H.ui.InfoBubble(geometry, {
          content: positionElement.element,
        });
      } else {
        const newPositionElement = {
          lat: position.latitude,
          lng: position.longitude,
          dateTime: position.positionDetection,
          provider: position.trailerSourceGPSProviderName,
        };
        newPositionElement.element = DetailsMapService.markers.buildPositionTooltip(
          newPositionElement
        );
        positionElements.push(newPositionElement);

        bubble = new window.H.ui.InfoBubble(geometry, {
          content: newPositionElement.element,
        });
      }
      ui.addBubble(bubble);
    },

    positionEventsHoverCallback: (map, ui, position, evt, positionElements) => {
      ui.getBubbles().forEach(bub => ui.removeBubble(bub));

      var geometry = evt.target.getGeometry();
      var pos = { lat: geometry.lat, lng: geometry.lng };
      map.setCenter(pos, true);

      const positionElement = positionElements.find(el => {
        return el.lat === position.latitude && el.lng === position.longitude;
      });
      let bubble;
      if (positionElement) {
        bubble = new window.H.ui.InfoBubble(evt.target.getGeometry(), {
          content: positionElement.element,
        });
      } else {
        const newPositionElement = {
          lat: position.latitude,
          lng: position.longitude,
          dateTime: position.positionDetection,
        };
        newPositionElement.element = DetailsMapService.markers.buildPositionTooltip(
          newPositionElement
        );

        positionElements.push(newPositionElement);
        bubble = new window.H.ui.InfoBubble(evt.target.getGeometry(), {
          content: newPositionElement.element,
        });
      }
      ui.addBubble(bubble);
    },

    outCallback: ui => {
      ui.getBubbles().forEach(bub => ui.removeBubble(bub));
    },

    baseRender: result => {
      const sections = result.routes[0].sections;
      const lineStrings = [];
      sections.forEach(section => {
        // convert Flexible Polyline encoded string to geometry
        lineStrings.push(
          window.H.geo.LineString.fromFlexiblePolyline(section.polyline)
        );
      });
      const multiLineString = new window.H.geo.MultiLineString(lineStrings);

      return multiLineString;
    },

    renderLineRoute: (hMap, coordArr, useSimpleLine, actualView) => {
      const result = [];
      const map = new Map();

      coordArr.forEach(item => {
        if (!map.has(item.lat)) {
          map.set(item.lat);
        }

        if (!!item && !!item.lat && !!item.lng) {
          result.push({
            lat: item.lat,
            lng: item.lng,
          });
        }
      });

      ;
      //DA SISTEMAREEE!!! Se ho solo una coordinata, la pagina va in errore.
      if (result.length == 1) {
        result.push(result[0]);
      }

      let lineString = new window.H.geo.LineString();

      //result.pop();
      result.forEach(item => lineString.pushPoint(item));
      //eslint-disable-next-line
      let styleDash = {
        strokeColor: actualView ? "#5aa1f3" : "#13457e",
        fillColor: "#1560B7",
        lineWidth: 4,
        lineTailCap: "arrow-tail",
        lineHeadCap: "arrow-head",
        lineDash: [2],
      };

      if (coordArr.length > 0) {
        let line = new window.H.map.Polyline(lineString, {
          style: styleDash,
        });

        // VA in errore
        // let line = new window.H.map.Polyline(lineString,
        //     {
        //         style: !useSimpleLine ? styleDash : {
        //             strokeColor: "#1560B7",
        //             fillColor: '#1560B7',
        //             lineWidth: 2,
        //         },
        //         zIndex: 1
        //     }
        // );
        hMap.addObject(line);
      }
    },

    renderTruckRoute: (hMap, coordArr, mapRouterService, H, wayPointList) => {
      const baseRoutingParams = {
        mode: "fastest",
        transportMode: "truck",
        return: "polyline",
      };

      if (coordArr.length > 1) {
        var origin = null;
        var destination = null;
        var coords = [];

        for (var i = 0; i < coordArr.length; i++) {
          var el = "" + coordArr[i].lat + "," + coordArr[i].lng;

          if (i == 0) origin = el;
          else if (i == coordArr.length - 1) destination = el;
          else if (!coords.includes(el) && el != origin && el != destination) {
            coords.push(el);
          }
        }

        let traveledRoutingParams = {};

        if (wayPointList?.length > 0) {
          traveledRoutingParams = {
            ...baseRoutingParams,
            origin: origin,
            via: new H.service.Url.MultiValueQueryParameter(
              coords.map(el => el)
            ),
            snapRadius: new H.service.Url.MultiValueQueryParameter(
              wayPointList.map(el =>
                parseInt(el.radius) != 0 ? el.radius : 100
              )
            ),
            destination: destination,
          };
        } else {
          traveledRoutingParams = {
            ...baseRoutingParams,
            origin: origin,
            via: new H.service.Url.MultiValueQueryParameter(
              coords.map(el => el)
            ),
            destination: destination,
          };
        }

        const renderTraveledRoute = result =>
          DetailsMapService.events.renderTraveledRoute(hMap, result);

        mapRouterService.calculateRoute(
          traveledRoutingParams,
          renderTraveledRoute,
          console.error
        );
      }
    },

    renderTraveledRoute: (hMap, result) => {
      const multiLineString = DetailsMapService.events.baseRender(result);
      // render route on the map
      hMap.addObject(
        new window.H.map.Polyline(multiLineString, {
          style: {
            strokeColor: "#1560B7",
            fillColor: "#1560B7",
            lineWidth: 4,
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
            lineDash: [2],
          },
          zIndex: 2,
        })
      );
    },

    renderDistanceRoute: (hMap, result) => {
      const multiLineString = DetailsMapService.events.baseRender(result);
      // render route on the map
      const polyline = hMap.addObject(
        new window.H.map.Polyline(multiLineString, {
          style: {
            strokeColor: "#000000",
            fillColor: "#000000",
            lineWidth: 4,
            lineCap: "round",
            lineDash: [0, 2],
          },
          zIndex: 2,
        })
      );
      return polyline;
    },

    renderRemainingRoute: (hMap, result) => {
      const multiLineString = DetailsMapService.events.baseRender(result);
      // render route on the map
      hMap.addObject(
        new window.H.map.Polyline(multiLineString, {
          style: {
            lineWidth: 3,
            strokeColor: "#9f9f9f",
            fillColor: "#9f9f9f",
            lineDash: [2],
            lineCap: "square",
          },
          zIndex: 1,
        })
      );
    },

    zoomToMarkers: (hMap, markers) => {
      // const group = new window.H.map.Group();
      // group.addObjects(markers);
      // hMap.addObject(group);
      // if (group.getBoundingBox())
      //     hMap.getViewModel().setLookAtData({
      //         bounds: group.getBoundingBox()
      //     });
      const lineString = new window.H.geo.LineString();
      markers.forEach(el => lineString.pushPoint(el.getGeometry()));
      if (lineString.getBoundingBox()) {
        hMap.getViewModel().setLookAtData({
          bounds: lineString.getBoundingBox(),
        });
        setTimeout(() => {
          if (hMap)
            hMap.getViewModel().setLookAtData({
              zoom: hMap.getZoom() - 0.5,
            });
        }, 1000);
      }
    },

    zoomMapOnMarker: (hMap, point) => {
      const markerPoint = new window.H.geo.Point(point.lat, point.lon, 0);
      hMap.setCenter(markerPoint, true);

      const checkAndZoom = () => {
        const currentCenter = hMap.getCenter();
        const distance = markerPoint.distance(currentCenter);
        if (distance >= 50) {
          setTimeout(checkAndZoom, 500);
          return;
        }
        hMap.setZoom(20);
      };
      checkAndZoom();
    },

    positionListeners: [],

    // Metodo per registrare un listener per l'evento positionMarkerSelected
    registerPositionChangeListener: function(callback) {
      this.positionListeners.push(callback);
    },

    // Metodo per rimuovere un listener per l'evento positionMarkerSelected
    unregisterPositionChangeListener: function(callback) {
      this.positionListeners = this.positionListeners.filter(
        listener => listener !== callback
      );
    },

    // Metodo per notificare tutti i listener registrati quando viene chiamato positionMarkerSelected
    positionMarkerSelected: function(position) {
      if (
        !position ||
        typeof position.latitude === "undefined" ||
        typeof position.longitude === "undefined"
      )
        return null;
      const positionCurrent = {
        lat: position.latitude.toFixed(5),
        lon: position.longitude.toFixed(5),
      };

      // Notifica tutti i listener registrati
      this.positionListeners.forEach(listener => {
        listener(positionCurrent);
      });
    },

    calculateDistance: (hMap, lastPosition, mapRouterService, H, dropOff) => {
      return new Promise((resolve, reject) => {
        const baseRoutingParams = {
          mode: "fastest",
          transportMode: "truck",
          return: ["polyline", "summary"],
        };

        let coords = [];
        let traveledRoutingParams = {
          ...baseRoutingParams,
          origin: lastPosition,
          via: new H.service.Url.MultiValueQueryParameter(coords.map(el => el)),
          destination: dropOff,
        };

        const renderDistanceRoute = result => {
          // Effettua il rendering del percorso sulla mappa
          const polyline = DetailsMapService.events.renderDistanceRoute(
            hMap,
            result
          );

          // Restituisci la lunghezza del riepilogo
          if (result && result.routes && result.routes.length > 0) {
            const route = result.routes[0];
            if (route.sections && route.sections.length > 0) {
              const section = route.sections[0];
              const length = section.summary.length;
              resolve({ distance: length, polyline: polyline });
            } else {
              reject(new Error("No route sections found."));
            }
          } else {
            reject(new Error("No routes found."));
          }
        };

        mapRouterService.calculateRoute(
          traveledRoutingParams,
          renderDistanceRoute,
          reject
        );
      });
    },
  },

  markers: {
    buildStopTooltip: (stop, isTrip) => {
      const address = [
        stop.address,
        stop.city,
        stop.zipCode,
        stop.provinceCode,
        stop.countryCode === "--" ? null : stop.countryCode,
      ]
        .filter(Boolean)
        .join(", ");
      const domElement = document.createElement("div");
      domElement.id = "trip-details-infobubble";
      return ReactDOM.render(
        <div className="col details-tooltip">
          <div className="row first-row">
            <div className="flex-grow-1">
              <h1>{stop?.invPartyName}</h1>
              <h3>{address}</h3>
            </div>
            <LateOnTime delayed={stop.delayed} />
          </div>
          <div className="row">
            <div
              className={`time-section ${
                (stop.activity !== "LOAD" && stop.activity !== "UNLOAD") ||
                isTrip
                  ? "half-width"
                  : ""
              }`}
            >
              {isTrip ? (
                <h2>Actual Arrival</h2>
              ) : stop.activity === "LOAD" ? (
                <h2>Expected PickUp</h2>
              ) : stop.activity === "UNLOAD" ? (
                <h2>Expected Delivery</h2>
              ) : stop.activity === "PICKUP" ? (
                <h2>Planned Pickup</h2>
              ) : stop.activity === "STOP" ? (
                <h2>Actual Arrival</h2>
              ) : stop.activity === "DROPOFF" ? (
                <h2>Planned Delivery</h2>
              ) : stop.invPartyQual === "ORIGIN" ? (
                <h2>Expected Departure</h2>
              ) : stop.invPartyQual === "DESTINATION" ? (
                <h2>Expected Delivery</h2>
              ) : stop.invPartyQual === "STOP" ? (
                <h2>Start Activity</h2>
              ) : (
                <h2>Start Date</h2>
              )}
              {isTrip && stop.actualArrival ? (
                <h6>{moment(stop.actualArrival).format("DD/MM/yyyy HH:mm")}</h6>
              ) : isTrip && stop.actualArrival === null ? (
                <>--</>
              ) : stop.activity === "LOAD" || stop.activity === "UNLOAD" ? (
                <h6>{expectedDate(stop.actualArrival, stop.actualArrival)}</h6>
              ) : stop.activity === "PICKUP" && stop.plannedPickup ? (
                <h6>{moment(stop.plannedPickup).format("DD/MM/yyyy HH:mm")}</h6>
              ) : stop.activity === "STOP" && stop.actualArrival ? (
                <h6>{moment(stop.actualArrival).format("DD/MM/yyyy HH:mm")}</h6>
              ) : stop.activity === "DROPOFF" && stop.plannedDelivery ? (
                <h6>
                  {moment(stop.plannedDelivery).format("DD/MM/yyyy HH:mm")}
                </h6>
              ) : stop.invPartyQual === "ORIGIN" ? (
                <h6>{moment(stop.expectedDate).format("DD/MM/yyyy HH:mm")}</h6>
              ) : stop.invPartyQual === "DESTINATION" ? (
                <h6>{moment(stop.expectedDate).format("DD/MM/yyyy HH:mm")}</h6>
              ) : stop.invPartyQual === "STOP" ? (
                <h6>{moment(stop.startLoading).format("DD/MM/yyyy HH:mm")}</h6>
              ) : (
                <>--</>
              )}
            </div>
            {((stop.activity !== "LOAD" && stop.activity !== "UNLOAD") ||
              isTrip) && (
              <div className={`time-section half-width`}>
                {isTrip ? (
                  <h2>Actual Departure</h2>
                ) : stop.activity === "PICKUP" ? (
                  <h2>Actual Pickup</h2>
                ) : stop.activity === "STOP" ? (
                  <h2>Actual Departure</h2>
                ) : stop.activity === "DROPOFF" ? (
                  <h2>Actual Delivery</h2>
                ) : stop.invPartyQual === "ORIGIN" ? (
                  <h2>Actual Departure</h2>
                ) : stop.invPartyQual === "DESTINATION" ? (
                  <h2>Actual Delivery</h2>
                ) : stop.invPartyQual === "STOP" ? (
                  <h2>End Activity</h2>
                ) : (
                  <h2>End Date</h2>
                )}
                {isTrip && stop.actualDeparture ? (
                  <h6>
                    {moment(stop.actualDeparture).format("DD/MM/yyyy HH:mm")}
                  </h6>
                ) : isTrip && stop.actualDeparture === null ? (
                  <>--</>
                ) : stop.activity === "PICKUP" && stop.actualPickup ? (
                  <h6>
                    {moment(stop.actualPickup).format("DD/MM/yyyy HH:mm")}
                  </h6>
                ) : stop.activity === "STOP" && stop.actualDeparture ? (
                  <h6>
                    {moment(stop.actualDeparture).format("DD/MM/yyyy HH:mm")}
                  </h6>
                ) : stop.activity === "DROPOFF" && stop.actualDelivery ? (
                  <h6>
                    {moment(stop.actualDelivery).format("DD/MM/yyyy HH:mm")}
                  </h6>
                ) : stop.invPartyQual === "ORIGIN" ? (
                  <h6>{moment(stop.actualDate).format("DD/MM/yyyy HH:mm")}</h6>
                ) : stop.invPartyQual === "DESTINATION" ? (
                  <h6>{moment(stop.actualDate).format("DD/MM/yyyy HH:mm")}</h6>
                ) : stop.invPartyQual === "STOP" ? (
                  <h6>{moment(stop.endLoading).format("DD/MM/yyyy HH:mm")}</h6>
                ) : (
                  <>--</>
                )}
              </div>
            )}
            {stop.departureTime && (
              <div
                className={`time-section ${
                  stop.activity !== "LOAD" && stop.activity !== "UNLOAD"
                    ? "half-width"
                    : ""
                }`}
              >
                <h2>Departure</h2>
                {stop.expectedEarlyDate ? (
                  <h6>
                    {moment(stop.expectedLateDate).format("DD/MM/yyyy HH:mm")}
                  </h6>
                ) : stop.actualArrival ? (
                  <h6>
                    {moment(stop.actualArrival).format("DD/MM/yyyy HH:mm")}
                  </h6>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>,
        domElement
      );
    },

    buildEventTooltip: stop => {
      const address =
        stop.address +
        ", " +
        stop.city +
        ", " +
        stop.zipCode +
        ", " +
        stop.provinceCode +
        ", " +
        stop.countryCode;
      const domElement = document.createElement("div");
      domElement.id = "trip-details-infobubble";
      return ReactDOM.render(
        <div className="col details-tooltip">
          <div className="row first-row">
            <div className="flex-grow-1">
              <h1>
                {stop.event} - {stop?.invPartyName}
              </h1>
              <h3>{address}</h3>
            </div>
          </div>
          <div className="row">
            <div className="time-section first-time-section">
              <h2>Expected</h2>
              {<p>{formattedDate(stop.expectedDate)}</p>}
            </div>
            <div className="time-section">
              <h2>Actual</h2>
              {<p>{formattedDate(stop.eventDate)}</p>}
            </div>
          </div>
        </div>,
        domElement
      );
    },

    buildPositionTooltip: position => {
      const domElement = document.createElement("div");
      domElement.id = "trip-details-infobubble";
      ReactDOM.render(<PositionElement position={position} />, domElement);
      return domElement;
    },

    buildWayPointTooltip: wayPoint => {
      const address = "wayPoint.latitude, wayPoint.longitude";
      const domElement = document.createElement("div");
      domElement.id = "trip-details-infobubble";
      ReactDOM.render(<WayPointTooltip wayPoint={wayPoint} />, domElement);
      return domElement;
    },

    buildLoadingMarker: (position, hoverCallback, outCallback, reached) => {
      const svg = document.createElement("img");
      svg.width = 24;
      svg.height = 24;
      // svg.src = toAbsoluteUrl('/media/svg/new/details/map/loading-icon.svg');
      svg.src = toAbsoluteUrl("/media/svg/map/marker-load.svg");
      svg.style.marginLeft = "-12px";
      svg.style.marginTop = "-12px";
      svg.className = "loading-marker";

      const domIcon = new window.H.map.DomIcon(svg);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 1,
      });
      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);

      return marker;
    },

    buildDepotMarker: (position, hoverCallback, outCallback, reached) => {
      const svg = document.createElement("img");
      svg.width = 24;
      svg.height = 24;
      // svg.src = toAbsoluteUrl('/media/svg/new/details/map/loading-icon.svg');
      svg.src = toAbsoluteUrl("/media/svg/map/depot.svg");
      svg.style.marginLeft = "-12px";
      svg.style.marginTop = "-12px";
      svg.className = "loading-marker";

      const domIcon = new window.H.map.DomIcon(svg);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 1,
      });
      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);

      return marker;
    },

    buildUnloadingMarker: (
      position,
      hoverCallback,
      outCallback,
      reached,
      isOriginEqualToDestination
    ) => {
      const svg = document.createElement("img");
      svg.width = 24;
      svg.height = 24;
      // svg.src = toAbsoluteUrl('/media/svg/new/details/map/unloading-icon.svg');
      svg.src = toAbsoluteUrl("/media/svg/map/marker-unload.svg");
      svg.style.marginLeft = "-12px";
      svg.style.marginTop = "-12px";
      svg.className = "unloading-marker";

      const domIcon = new window.H.map.DomIcon(svg);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 1,
      });
      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);

      return marker;
    },

    buildStopMarker: (
      position,
      hoverCallback,
      outCallback,
      reached,
      isOriginEqualToDestination
    ) => {
      const svg = document.createElement("img");
      svg.width = 18; // 24;
      svg.height = 18; // 24;
      // svg.src = toAbsoluteUrl('/media/svg/new/details/map/unloading-icon.svg');
      svg.src = toAbsoluteUrl("/media/svg/map/stop.svg");
      svg.style.marginLeft = "-12px";
      svg.style.marginTop = "-12px";
      svg.className = "unloading-marker";

      const domIcon = new window.H.map.DomIcon(svg);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 1,
      });
      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);

      return marker;
    },

    buildFromMarker: (
      position,
      hoverCallback,
      outCallback,
      reached,
      isOriginEqualToDestination
    ) => {
      const svg = document.createElement("img");
      //            if (!isOriginEqualToDestination) svg.src = toAbsoluteUrl(reached ? '/media/svg/new/order-progress/sender-completed.svg' : '/media/svg/new/order-progress/sender.svg');
      if (!isOriginEqualToDestination)
        svg.src = toAbsoluteUrl(
          "/media/svg/new/order-progress/sender-completed.svg"
        );
      else
        svg.src = toAbsoluteUrl(
          reached
            ? "/media/svg/new/origin-to-destination/union-orig-dest-color.svg"
            : "/media/svg/new/origin-to-destination/union-orig-dest-no-color.svg"
        );
      svg.style.marginLeft = "-12px";
      svg.style.marginTop = "-12px";
      svg.style.height = "24px";
      svg.style.width = "24px";

      const domIcon = new window.H.map.DomIcon(svg);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 1,
      });
      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);

      return marker;
    },

    buildFromOrderMarker: (
      position,
      hoverCallback,
      outCallback,
      reached,
      isOriginEqualToDestination
    ) => {
      const svg = document.createElement("img");
      //             if (!isOriginEqualToDestination) svg.src = toAbsoluteUrl(reached ? '/media/svg/new/order-progress/sender-completed.svg' : '/media/svg/new/order-progress/sender.svg');
      if (!isOriginEqualToDestination)
        svg.src = toAbsoluteUrl(
          "/media/svg/new/order-progress/sender-completed.svg"
        );
      else
        svg.src = toAbsoluteUrl(
          reached
            ? "/media/svg/new/origin-to-destination/union-orig-dest-color.svg"
            : "/media/svg/new/origin-to-destination/union-orig-dest-no-color.svg"
        );
      svg.style.marginLeft = "-12px";
      svg.style.marginTop = "-12px";
      svg.style.height = "24px";
      svg.style.width = "24px";

      const domIcon = new window.H.map.DomIcon(svg);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 1,
      });
      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);

      return marker;
    },

    buildToMarker: (
      position,
      hoverCallback,
      outCallback,
      reached,
      isOriginEqualToDestination
    ) => {
      const svg = document.createElement("img");
      //             if (!isOriginEqualToDestination) svg.src = toAbsoluteUrl(reached ? '/media/svg/new/order-progress/consignee-completed.svg' : '/media/svg/new/order-progress/consignee.svg');
      if (!isOriginEqualToDestination)
        svg.src = toAbsoluteUrl(
          "/media/svg/new/order-progress/consignee-completed.svg"
        );
      else
        svg.src = toAbsoluteUrl(
          reached
            ? "/media/svg/new/origin-to-destination/union-orig-dest-color.svg"
            : "/media/svg/new/origin-to-destination/union-orig-dest-no-color.svg"
        );
      svg.style.marginLeft = "-12px";
      svg.style.marginTop = "-12px";
      svg.style.height = "24px";
      svg.style.width = "24px";

      const domIcon = new window.H.map.DomIcon(svg);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 1,
      });
      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);

      return marker;
    },

    buildWayPointMarker: (position, hoverCallback) => {
      const svg = document.createElement("img");
      svg.src = toAbsoluteUrl("/media/svg/map/marker-way-point.svg");
      svg.style.marginLeft = "-24px";
      svg.style.marginTop = "-24px";
      svg.style.height = "48px";
      svg.style.width = "48px";

      const domIcon = new window.H.map.DomIcon(svg);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 1,
      });
      marker.addEventListener("tap", hoverCallback);

      return marker;
    },

    buildPositionHistoryMarker: (position, hoverCallback, outCallback) => {
      const domElement = document.createElement("div");
      domElement.className = "position-history-icon";

      const domIcon = new window.H.map.DomIcon(domElement);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 2,
      });

      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);
      return marker;
    },

    startClustering: (data, hMap, ui, positionElements) => {
      const markers = {
        clusterMarkers: [],
        noiseMarkers: [],
      };

      //Custom Theme per HERE
      var CUSTOM_THEME = {
        getClusterPresentation: function(cluster) {
          var div = document.createElement("div");
          div.className = "custom-clusterMarker";
          div.textContent = cluster.getWeight();

          var clusterMarker = new window.H.map.DomMarker(
            cluster.getPosition(),
            {
              icon: new window.H.map.DomIcon(div),
              min: cluster.getMinZoom(),
              max: cluster.getMaxZoom(),
            }
          );

          clusterMarker.addEventListener("tap", event => {
            if (hMap.getZoom() < 20) {
              hMap.setCenter(cluster.getPosition());
              hMap.setZoom(hMap.getZoom() + 3);
            } else {
              const tolerance = 0.00001;

              const similarElements = data.filter(
                item =>
                  Math.abs(Number(item.latitude) - cluster.getPosition().lat) <
                    tolerance &&
                  Math.abs(Number(item.longitude) - cluster.getPosition().lng) <
                    tolerance
              );

              if (similarElements.length > 0) {
                const positionDetections = similarElements.map(
                  el => el.positionDetection
                );
                const mergedPositionDetections = positionDetections.flat();
                const uniquePositionDetections = [
                  ...new Set(mergedPositionDetections),
                ]; // Rimuovi duplicati

                similarElements.forEach(el => {
                  el.positionDetection = uniquePositionDetections;
                });

                similarElements.forEach(el => {
                  DetailsMapService.events.positionHoverCallback(
                    hMap,
                    ui,
                    el,
                    event,
                    positionElements.current
                  );
                  DetailsMapService.events.positionMarkerSelected(el, () => {});
                });
              }
            }
          });

          setTimeout(() => {
            hMap.addObject(clusterMarker);
          }, 500);
          markers.clusterMarkers.push(clusterMarker);

          return clusterMarker;
        },
        getNoisePresentation: function(noisePoint) {
          var div = document.createElement("div");
          div.className = "custom-noiseMarker";

          var noiseMarker = new window.H.map.DomMarker(
            noisePoint.getPosition(),
            {
              icon: new window.H.map.DomIcon(div),
              min: noisePoint.getMinZoom(),
            }
          );

          noiseMarker.addEventListener("tap", event => {
            const tolerance = 0.000001;

            const similarElements = data.filter(
              item =>
                Math.abs(
                  Number(item.latitude) - noisePoint.getPosition().lat
                ) <= tolerance &&
                Math.abs(
                  Number(item.longitude) - noisePoint.getPosition().lng
                ) < tolerance
            );

            if (similarElements.length > 0) {
              const positionDetections = similarElements.map(
                el => el.positionDetection
              );
              const mergedPositionDetections = positionDetections.flat();
              const uniquePositionDetections = [
                ...new Set(mergedPositionDetections),
              ]; // Rimuovi duplicati

              similarElements.forEach(el => {
                el.positionDetection = uniquePositionDetections;
              });

              similarElements.forEach(el => {
                DetailsMapService.events.positionHoverCallback(
                  hMap,
                  ui,
                  el,
                  event,
                  positionElements.current
                );
                DetailsMapService.events.positionMarkerSelected(el, () => {});
              });
            }
          });

          setTimeout(() => {
            hMap.addObject(noiseMarker);
          }, 500);
          markers.noiseMarkers.push(noiseMarker);

          return noiseMarker;
        },
      };

      const dataPoints = data.map(item => {
        return new window.H.clustering.DataPoint(item.latitude, item.longitude);
      });

      const clusteredDataProvider = new window.H.clustering.Provider(
        dataPoints,
        {
          clusteringOptions: {
            eps: 32,
            minWeight: 2,
          },
          theme: CUSTOM_THEME,
        }
      );

      /* const clusteringLayer = new window.H.map.layer.ObjectLayer(clusteredDataProvider);
            
            hMap.addLayer(clusteringLayer);  */

      return markers;
    },

    buildCurrentPositionMarker: (position, hoverCallback, outCallback) => {
      const domElement = document.createElement("div");
      const svgUrl = toAbsoluteUrl("/media/svg/map/actual-position.svg");
      const image = document.createElement("img");
      const pulsating = document.createElement("div");

      image.src = svgUrl;
      domElement.className = "icon-container";
      pulsating.className = "pulsating";
      domElement.appendChild(image);
      domElement.appendChild(pulsating);

      const domIcon = new window.H.map.DomIcon(domElement);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 9999,
      });

      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);
      return marker;
    },

    buildEventPositionMarker: (position, hoverCallback, outCallback) => {
      const domElement = document.createElement("div");
      const svgUrl = toAbsoluteUrl("/media/svg/map/map-event.svg");
      const image = document.createElement("img");

      image.src = svgUrl;
      domElement.className = "icon-container-map-event";
      domElement.appendChild(image);

      const domIcon = new window.H.map.DomIcon(domElement);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 9999,
      });

      marker.addEventListener("tap", hoverCallback);
      // marker.addEventListener('pointerleave', outCallback);
      return marker;
    },

    buildTerminalPositionMarker: (position, hoverCallback, outCallback) => {
      const domElement = document.createElement("div");
      const svgUrl = toAbsoluteUrl("/media/svg/map/map-terminal.svg");
      const image = document.createElement("img");

      image.src = svgUrl;
      domElement.className = "icon-container-map-terminal";
      domElement.appendChild(image);

      const domIcon = new window.H.map.DomIcon(domElement);

      const marker = new window.H.map.DomMarker(position, {
        icon: domIcon,
        zIndex: 9999,
      });
      return marker;
    },
  },
};

const formattedDate = date => {
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format("DD/MM/yyyy HH:mm") : "--";
};
