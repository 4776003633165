import React, { useEffect, useMemo, useRef } from 'react';
import CommonTable, { TableRef } from '../../components/commonTable/CommonTable';
import { Leg3Rules, LegPlanningTypesEnum } from '../../types/leg';

type Props = {
    conflicts: Leg3Rules[];
    callbackCloseModal: () => any;
    businessFlowTypesOptions: { value: number, label: string }[];
    legPlanningTypesOptions: { value: LegPlanningTypesEnum, label: string }[];
    legTypesOptions : { value: number, label: string }[];
    collectionTemplatesOptions : { value: string, label: string }[];
    deliveryTemplatesOptions : { value: string, label: string }[];
};

const ModalShowLeg3CombinationsConflicts: React.FC<Props> = ({
    conflicts,
    callbackCloseModal,
    businessFlowTypesOptions,
    legPlanningTypesOptions,
    legTypesOptions,
    collectionTemplatesOptions,
    deliveryTemplatesOptions
}) => {

    // #region State and Refs
    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo 
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs: any = useMemo(() => [
        {
            field: 'businessFlowTypeId',
            headerName: "BUSINESS FLOW TYPE",
            editable: false,
            cellRenderer: (props: { data: Leg3Rules}) => {
                return (
                    <>
                        <>{businessFlowTypesOptions.find(x => x.value === props.data.businessFlowTypeId)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'firstPlanningTypeId',
            headerName: "OUTPUT (PLANNING TYPE)",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: Leg3Rules}) => {
                return (
                    <>
                        <>{legPlanningTypesOptions.find(x => x.value === props.data.firstPlanningTypeId)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'firstTypeId',
            headerName: "LEG TYPE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: Leg3Rules }) => {
                return (
                    <>
                        <>{legTypesOptions.find(x => x.value === props.data.firstTypeId)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'secondPlanningTypeId',
            headerName: "OUTPUT (PLANNING TYPE)",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: Leg3Rules}) => {
                return (
                    <>
                        <>{legPlanningTypesOptions.find(x => x.value === props.data.secondPlanningTypeId)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'secondTypeId',
            headerName: "LEG TYPE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: Leg3Rules }) => {
                return (
                    <>
                        <>{legTypesOptions.find(x => x.value === props.data.secondTypeId)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'thirdPlanningTypeId',
            headerName: "OUTPUT (PLANNING TYPE)",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: Leg3Rules}) => {
                return (
                    <>
                        <>{legPlanningTypesOptions.find(x => x.value === props.data.thirdPlanningTypeId)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'thirdTypeId',
            headerName: "LEG TYPE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: Leg3Rules }) => {
                return (
                    <>
                        <>{legTypesOptions.find(x => x.value === props.data.thirdTypeId)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'tR',
            headerName: "COLLECTION TEMPLATE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: any }) => {
                return (
                    <>
                        <>{collectionTemplatesOptions.find(x => x.value === props.data.tr)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'tS',
            headerName: "DELIVERY TEMPLATE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: any }) => {
                return (
                    <>
                        <>{deliveryTemplatesOptions.find(x => x.value === props.data.ts)?.label}</>
                    </>
                )
            }
        }
    ], []);
    // #endregion

    // #region Util Functions
    
    // #endregion

    // #region Use Effects
    useEffect(() => {
        let insertionRow: any = conflicts.find(x => x.id === null);
        if (!!insertionRow) {
            insertionRow.isHighligthed = true;
        }
        
        commonTableRef.current?.setTableDataState(conflicts);
    }, [conflicts]);
    // #endregion

    return (
        <div style={{ zIndex: 9999 }} className="fixed top-0 left-0 h-screen w-screen bg-[#00000080] flex items-center justify-center">
            <div className="bg-white max-h-[75%] w-[95%] lg:w-[80%] max-w-[1920px] flex flex-col">
                <div className="shrink-0 flex justify-between border-b p-[30px]">
                    <div className="text-lg font-bold">
                        Conflict rows
                    </div>
                    <div onClick={callbackCloseModal} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>

                {/* MAIN CONTENT */}
                <div className="grow py-8 px-[30px] select-none overflow-auto relative">
                    <div className="mt-5" style={containerStyle}>
                        <CommonTable
                            ref={commonTableRef}
                            columnDefs={columnDefs}
                            options={{
                                showRowsNumber: false,
                                filter: false,
                                floatingFilter: false,
                                editable: false,
                                pageSize: 10
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModalShowLeg3CombinationsConflicts;