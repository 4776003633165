import axios from "axios";
import config from "../../settings";
import { type } from "os";

export const detailAPI = {
  getSalesOrderDetailsById: (id, thenCallback) => {
    axios
      .get(`${config.baseWebApiUrl}${config.getSalesOrderDetailsById}`, {
        params: { id: id },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderBySalesOrderIdAndPhase: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderBySalesOrderIdAndPhase}`,
        {
          params: {
            id: filters.id,
            phase: filters.phase,
            limit: filters.Limit,
          },
        }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderExecutionBySalesOrderId: (id, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderExecutionBySalesOrderId}`,
        { params: { id: id, limit: -1 } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderCustomerBySalesOrderId: (id, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderCustomerBySalesOrderId}`,
        { params: { id: id, limit: -1 } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderDetailsById: (id, thenCallback) => {
    axios
      .get(`${config.baseWebApiUrl}${config.getTransportOrderDetailsById}`, {
        params: { id: id },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderCustomerDetailsById: (id, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderCustomerDetailsById}`,
        { params: { id: id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsLastPositionById: (id, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsLastPositionById}`,
        { params: { id: id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsEventsById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTransportOrderDetailsEventsById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsTransportDocById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsTransportDocById}`,
        { params: { id: filters.id, limit: filters.Limit } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsTransportDocDetailsById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsTransportDocDetailsById}`,
        { params: { id: filters.id, limit: filters.Limit } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsShipUnitsById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsShipUnitsById}`,
        { params: { id: filters.id, limit: filters.Limit } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsWarningsById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTransportOrderDetailsWarningsById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsAnomaliesById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTransportOrderDetailsAnomaliesById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsAttributesById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsAttributesById}`,
        { params: { id: filters.id, limit: filters.Limit } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsAdditionalServiceById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseUrl}${config.getTransportOrderDetailsAdditionalServiceById}`,
        { params: { id: filters.id, limit: filters.Limit } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsNotesById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsNotesById}`,
        { params: { id: filters.id, limit: filters.Limit } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsInvPartyById: (id, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTransportOrderDetailsInvPartyById}`, {
        params: { id: id },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsContactDetailsById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsContactDetailsById}`,
        {
          params: {
            id: filters.invPartyId,
            extlocid: filters.invPartyExtLocId,
          },
        }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderDetailsLegsById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTransportOrderDetailsLegsById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderDetailsLegDetailsById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseUrl}${config.getTransportOrderDetailsLegDetailsById}`,
        {
          params: {
            id: filters.toId,
            legid: filters.legId,
            tripid: filters.tripId,
          },
        }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderDetailsTripsById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTransportOrderDetailsTripsById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTripById: (id, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTripById}`, { params: { id: id } })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTripDetailsById: (id, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTripDetailsById}`, {
        params: { id: id },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTripStopsById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTripStopsById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTripContainedLegsById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTripContainedLegsById}`, {
        params: {
          id: filters.id,
          includeTechnicalCustomers: filters.includeTechnicalCustomers,
          limit: filters.Limit,
        },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTripStatusHistoryById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTripStatusHistoryById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTripWarningsById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTripWarningsById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTripAnomaliesById: (filters, thenCallback) => {
    axios
      .get(`${config.baseUrl}${config.getTripAnomaliesById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTripAttributesById: (filters, thenCallback) => {
    axios
      .get(`${config.baseWebApiUrl}${config.getTripAttributesById}`, {
        params: { id: filters.id, limit: filters.Limit },
      })
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderDetailsStatusHistoryById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsStatusHistoryById}`,
        { params: { id: filters.id, limit: filters.Limit } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderDetailsTimelineTOLegById: (id, thenCallback) => {
    axios
      .get(
        `${config.baseUrl}${config.getTransportOrderDetailsTimelineTOLegById}`,
        { params: { id: id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderDetailsLegsTimelineMilestoneByIdAndType: (
    filters,
    thenCallback
  ) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsLegsTimelineMilestoneByIdAndType}`,
        {
          params: {
            id: filters.id,
            type: filters.type,
            toLegIntercompanyId: filters.toLegIntercompanyId,
            isIntraCompany: filters.isIntraCompany,
          },
        }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderDetailsHasStoragesById: (id, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsHasStoragesById}`,
        { params: { id: id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTransportOrderDetailsStoragesById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsStoragesById}`,
        { params: { id: filters.id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  /*   getTransportOrderDetailsComparisonByIdAndSection: (filters, thenCallback) => {
          axios.get(`${config.baseWebApiUrl}${config.getTransportOrderDetailsComparisonByIdAndSection}`, { params: { id: filters.id, section: filters.section } })
              .then(res => {
                  thenCallback(res.data);
              })
              .catch(err => {
                  console.log(err);
              })
      }, */

  getTransportOrderExecutionDetailsInvpartyComparisonById: (
    filters,
    thenCallback
  ) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderExecutionDetailsInvpartyComparisonById}`,
        { params: { id: filters.id, section: filters.section } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderExecutionDetailsComparisonById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderExecutionDetailsComparisonById}`,
        { params: { id: filters.id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderExecutionDetailsVolumetricComparisonById: (
    filters,
    thenCallback
  ) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderExecutionDetailsVolumetricComparisonById}`,
        { params: { id: filters.id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderExecutionDetailsdatesComparisonById: (
    filters,
    thenCallback
  ) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderExecutionDetailsdatesComparisonById}`,
        { params: { id: filters.id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderCustomerDetailsInvpartyComparisonById: (
    filters,
    thenCallback
  ) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderCustomerDetailsInvpartyComparisonById}`,
        { params: { id: filters.id, section: filters.section } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderCustomerDetailsComparisonById: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderCustomerDetailsComparisonById}`,
        { params: { id: filters.id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderCustomerDetailsVolumetricComparisonById: (
    filters,
    thenCallback
  ) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderCustomerDetailsVolumetricComparisonById}`,
        { params: { id: filters.id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderCustomerDetailsdatesComparisonById: (
    filters,
    thenCallback
  ) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderCustomerDetailsdatesComparisonById}`,
        { params: { id: filters.id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsAdrDetailsByIdAsync: (filters, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsAdrDetailsByIdAsync}`,
        { params: { id: filters.id, limit: filters.Limit } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsPhoneBookingInfoById: (id, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsPhoneBookingInfoById}`,
        { params: { id: id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsIsWasteTransportById: (id, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsIsWasteTransportById}`,
        { params: { id: id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getTransportOrderDetailsIsHighSecurityById: (id, thenCallback) => {
    axios
      .get(
        `${config.baseWebApiUrl}${config.getTransportOrderDetailsIsHighSecurityById}`,
        { params: { id: id } }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  // New Order Details section
  orderDetails: {
    getTransportOrderDetailsRemarkById: (id, invPartyQual, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsRemarkById}`,
          { params: { id: id, invPartyQual: invPartyQual } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderDetailsSplitById: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsSplitById}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderDetailsSUCountById: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsSUCountById}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderDetailsIsHACCPById: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsIsHACCPById}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderDetailsIconVisibilitybyId: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsIconVisibilitybyId}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderDetailsLastStatusById: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsLastStatusById}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderDetailsLastEventById: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsLastEventById}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderDetailsEventsById: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsEventsById}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderDetailsHasSplittedLegs: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsHasSplittedLegs}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderDetailsReferencesById: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderDetailsReferencesById}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTransportOrderCustomerDetailsReferencesById: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getTransportOrderCustomerDetailsReferencesById}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getCustomerData: (id, thenCallback) => {
      axios
        .get(`${config.baseWebApiUrl}${config.orderDetails.getCustomerData}`, {
          params: { id: id },
        })
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getStops: (id, thenCallback) => {
      axios
        .get(`${config.baseWebApiUrl}${config.orderDetails.getStops}`, {
          params: { id: id },
        })
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getPositionHistory: (params, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getPositionHistory}`,
          { params: params }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getGeneralDetailsTOLegTimeline: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getGeneralDetailsTOLegTimeline}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getRequestedTimeline: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getRequestedTimeline}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getRequestedTimelineCustomer: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getRequestedTimelineCustomer}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getActualTimeline: (filters, thenCallback) => {
      axios
        .post(
          `${config.baseWebApiUrl}${config.orderDetails.getActualTimeline}`,
          filters
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getOrderActivitiesTimeline: (requestData, thenCallback) => {
      axios
        .post(
          `${config.baseWebApiUrl}${config.orderDetails.getOrderActivitiesTimeline}`,
          requestData
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getOrderRelatedTrips: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getOrderRelatedTrips}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getCurrentMilestone: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getCurrentMilestone}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getInvParties: (id, thenCallback) => {
      axios
        .get(`${config.baseWebApiUrl}${config.orderDetails.getInvParties}`, {
          params: { id: id },
        })
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    askEnrichmentByIdAndType: (filters, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.askEnrichmentByIdAndType}`,
          { params: { id: filters.id, type: filters.type } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getRelatedOrders: (id, thenCallback) => {
      axios
        .get(`${config.baseWebApiUrl}${config.orderDetails.getRelatedOrders}`, {
          params: { id: id },
        })
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getRelatedOrdersExecutionToTOCustomer: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getRelatedOrdersExecutionToTOCustomer}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getRelatedOrdersCustomerToTOCustomer: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getRelatedOrdersCustomerToTOCustomer}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getRelatedOrdersExecutionToTOExecution: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getRelatedOrdersExecutionToTOExecution}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getRelatedOrdersCustomerToTOExecution: (id, thenCallback) => {
      axios
        .get(
          `${config.baseWebApiUrl}${config.orderDetails.getRelatedOrdersCustomerToTOExecution}`,
          { params: { id: id } }
        )
        .then(res => {
          thenCallback(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
