import React, { useEffect, useState } from "react";
import config from "../../../../settings";
import { getTimeFromDate, formatDate } from "../../../../utils/DateUtils";

export const WayPointTooltip = ({ wayPoint, delayMultiplier }) => {
  const [address, setAddress] = useState({ place: "--", address: "" });
  const [latitudine, setLatitudine] = useState("--");
  const [longitudine, setLongitudine] = useState("--");

  useEffect(() => {
    if (wayPoint.latitude && wayPoint.longitude) {
      setLatitudine(wayPoint.latitude.toFixed(5));
      setLongitudine(wayPoint.longitude.toFixed(5));
    }

    const H = window.H;
    const platform = new H.service.Platform({
      apikey: config.HMap_AppKey,
    });

    const searchService = platform.getSearchService();

    setTimeout(
      () =>
        searchService.reverseGeocode(
          { at: "" + wayPoint.latitude + "," + wayPoint.longitude },
          result => {
            const place = result.items.find(
              el => el.resultType == "locality" || el.resultType == "place"
            );
            if (place) {
              // GI: introdotto controllo su title !== da address.label per evitare di mostrare un'informazione doppia
              setAddress({
                place: place.title,
                address:
                  place.title !== place.address.label
                    ? place.address.label
                    : "",
              });
            } else if (result.items.length > 0) {
              const item = result.items[0];
              // GI: introdotto controllo su title !== da address.label per evitare di mostrare un'informazione doppia
              setAddress({
                place: item.title,
                address:
                  item.title !== item.address.label ? item.address.label : "",
              });
            }
          },
          console.error
        ),
      delayMultiplier * 100
    );
  }, []);

  return (
    <div>
      <div className="position-event">
        <div>
          <h1>{address.place}</h1>
          {/* <h2>{address.address}</h2> */}
        </div>
        <div className="date-time-container">
          <div className="date-time">
            <div>
              <h3>{wayPoint?.description ? wayPoint?.description : "--"}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="lat-lon">
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <p className="upper-gray">Lat:</p>
            <p>{latitudine}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <p className="upper-gray" style={{ marginLeft: "10px" }}>
              Lon:
            </p>
            <p>{longitudine}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
