import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import Select from "../../components/ui/select/Select";
import Button from "../../components/ui/button/Button";
import { GridApi, RowNode, ValueSetterParams } from "@ag-grid-community/core";
import CommonTable, { TableRef } from "../../components/commonTable/CommonTable";
import AuthorizedComponent from "../../components/permissions/AuthorizedComponent";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import usePermissions from "../../utils/hooks/usePermissions";
import { toast } from "react-toastify";
import { Leg2Rules, LegPlanningTypesEnum, LegTypesEnum } from "../../types/leg";
import { TripCombination } from "../../types/trips";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import { IMasterDataContext, MasterDataContext } from "../../context/MasterDataContext";
import { legsService } from "../../api/legsService/legsService";
import { distinct } from "../../utils/utilFunctions";
import ModalShowLeg2CombinationsConflicts from "./ModalShowLeg2CombinationsConflicts";
import { templatesConfigurationService } from "../../api/templatesConfigurationService/templatesConfigurationService";

const PlanningToolLeg2Combinations: React.FC = () => {

    const { hasPermission } = usePermissions();
    const { businessFlowTypes } = useContext(MasterDataContext) as IMasterDataContext;

    // #region State
    const [inAdding, setInAdding] = useState<boolean>(false);
    const [conflictRows, setConflictRows] = useState<Leg2Rules[]>([]);
    const [collectionTemplates, setCollectionTemplates] = useState<string[]>([]);
    const [deliveryTemplates, setDeliveryTemplates] = useState<string[]>([]);
    const [openModalShowConflicts, setOpenModalShowConflicts] = useState<boolean>(false);

    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo
    const businessFlowTypesOptions = useMemo(() => {
        return businessFlowTypes.map((e) => {
            return {
                value: e.id,
                label: e.name
            }
        });
    }, [businessFlowTypes]);

    const legPlanningTypesOptions = useMemo(() => {
        return Object.keys(LegPlanningTypesEnum).filter((x: any) => isNaN(x)).map((key: string) => {
            return {
                value: LegPlanningTypesEnum[key as keyof typeof LegPlanningTypesEnum],
                label: key
            }
        });
    }, []);

    const legTypesOptions = useMemo(() => {
        return [
            { value: LegTypesEnum.Collection.valueOf(), label: "Collection" },
            { value: LegTypesEnum.Delivery.valueOf(), label: "Delivery" },
            { value: LegTypesEnum.Direct.valueOf(), label: "Direct" },
            { value: LegTypesEnum.Shuttle.valueOf(), label: "Shuttle" }
        ];
    }, []);

    const collectionTemplatesOptions = useMemo(() => {
        return collectionTemplates.map(x => {
            return {
                value: x,
                label: x
            }
        });
    }, [collectionTemplates]);

    const deliveryTemplatesOptions = useMemo(() => {
        return deliveryTemplates.map(x => {
            return {
                value: x,
                label: x
            }
        });
    }, [deliveryTemplates]);

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs: any = useMemo(() => [
        {
            field: 'businessFlowTypeId',
            headerName: "BUSINESS FLOW TYPE",
            editable: false,
            filter: "agSetColumnFilter",
            filterParams: {
                values: businessFlowTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => businessFlowTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: Leg2Rules, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeNumericValue(e.target.value, props.node, props.api, "businessFlowTypeId") }}
                                options={businessFlowTypesOptions}
                                value={props.data.businessFlowTypeId}
                                defaultOptionEnabled={true}
                                placeholder={"Select Business Flow Type"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <>{businessFlowTypesOptions.find(x => x.value === props.data.businessFlowTypeId)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'firstPlanningTypeId',
            headerName: "OUTPUT 1 (PLANNING TYPE)",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: legPlanningTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => legPlanningTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: Leg2Rules, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeNumericValue(e.target.value, props.node, props.api, "firstPlanningTypeId") }}
                                options={legPlanningTypesOptions}
                                value={props.data.firstPlanningTypeId}
                                defaultOptionEnabled={true}
                                placeholder={"ND"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <>{legPlanningTypesOptions.find(x => x.value === props.data.firstPlanningTypeId)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'firstTypeId',
            headerName: "LEG TYPE 1",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: legTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => legTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: Leg2Rules, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeNumericValue(e.target.value, props.node, props.api, "firstTypeId") }}
                                options={legTypesOptions}
                                value={props.data.firstTypeId}
                                defaultOptionEnabled={true}
                                placeholder={"Select Leg Type"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <>{legTypesOptions.find(x => x.value === props.data.firstTypeId)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'secondPlanningTypeId',
            headerName: "OUTPUT 2 (PLANNING TYPE)",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: legPlanningTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => legPlanningTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: Leg2Rules, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeNumericValue(e.target.value, props.node, props.api, "secondPlanningTypeId") }}
                                options={legPlanningTypesOptions}
                                value={props.data.secondPlanningTypeId}
                                defaultOptionEnabled={true}
                                placeholder={"ND"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <>{legPlanningTypesOptions.find(x => x.value === props.data.secondPlanningTypeId)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'secondTypeId',
            headerName: "LEG TYPE 2",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: legTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => legTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: Leg2Rules, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeNumericValue(e.target.value, props.node, props.api, "secondTypeId") }}
                                options={legTypesOptions}
                                value={props.data.secondTypeId}
                                defaultOptionEnabled={true}
                                placeholder={"Select Leg Type"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <>{legTypesOptions.find(x => x.value === props.data.secondTypeId)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'tR',
            headerName: "COLLECTION TEMPLATE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: collectionTemplatesOptions.map(x => x.value),
                valueFormatter: (params: any) => collectionTemplatesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: Leg2Rules, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeValue(e.target.value, props.node, props.api, "tR") }}
                                options={collectionTemplatesOptions}
                                value={props.data.tR}
                                defaultOptionEnabled={true}
                                placeholder={"ND"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <>{collectionTemplatesOptions.find(x => x.value === props.data.tR)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'tS',
            headerName: "DELIVERY TEMPLATE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: deliveryTemplatesOptions.map(x => x.value),
                valueFormatter: (params: any) => deliveryTemplatesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: Leg2Rules, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeValue(e.target.value, props.node, props.api, "tS") }}
                                options={deliveryTemplatesOptions}
                                value={props.data.tS}
                                defaultOptionEnabled={true}
                                placeholder={"ND"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify) &&
                            <>{deliveryTemplatesOptions.find(x => x.value === props.data.tS)?.label}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'actions',
            headerName: "ACTIONS",
            editable: false,
            filter: false,
            minWidth: 150,
            hide: !hasPermission(PermissionAreas.ConfigurationHub_TripCombinations, PermissionOperations.Modify),
            cellRenderer: (props: { data: TripCombination, node: RowNode }) => {
                return (
                    <>
                        {
                            <div className="flex">
                                {
                                    props.node.rowPinned === "top" &&
                                    <div
                                        onClick={onClickCancelNewRow}
                                        className={"flex text items-center cursor-pointer"}
                                    >
                                        <span className="mr-[8px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#3987E2" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z" /></svg>
                                        </span>
                                        <span className="hover:underline not-italic ">Remove</span>
                                    </div>
                                }
                            </div>
                        }
                    </>
                )
            },
        }
    ], [collectionTemplatesOptions, deliveryTemplatesOptions]);
    // #endregion

    // #region UI Handlers
    const handleChangeValue = async (value: any, node: RowNode, api: GridApi, column: string) => {
        node.setDataValue(column, value);
    };

    const handleChangeNumericValue = async (value: any, node: RowNode, api: GridApi, column: string) => {
        value = Number(value);

        node.setDataValue(column, value);
    };

    const onClickAddNewCombination = () => {
        addNewCombination();
    };

    const onClickDeleteButton = async () => {
        await confirmDeleteCombinations();
    };

    const onClickSaveButton = async () => {
        await confirmSaveCombinations();
    };

    const onClickCancelNewRow = () => {
        cancelNewRow();
    };
    // #endregion

    // #region Util Functions
    const getLeg2Combinations = async () => {
        commonTableRef.current?.showLoadingOverlay();
        const response = await legsService.getLeg2Combinations();
        commonTableRef.current?.hideOverlay();

        if (response.success) {
            commonTableRef.current?.setRowData(response.data);
        } else {
            toast.error('Error retrieving legs combinations');
        }
    };

    const getTemplates = async () => {
        const response = await templatesConfigurationService.getCollectionTemplates({ pageSize: 100 });
        setCollectionTemplates(distinct(response.data.records.map((x: any) => x.templateID)));

        const response2 = await templatesConfigurationService.getDeliveryTemplates({ pageSize: 100 });
        setDeliveryTemplates(distinct(response2.data.records.map((x: any) => x.templateID)));
    };

    const addNewCombination = () => {
        let newRow: any = {};
        newRow.rowId = undefined;
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const confirmDeleteCombinations = async () => {
        let selectedRows: Leg2Rules[] = commonTableRef.current?.getSelectedRows() as Leg2Rules[];
        let idsToRemove: number[] = selectedRows.map(x => {
            return x.id as number;
        });

        if (idsToRemove.length > 0) {
            const wantToDelete = await customConfirmAlert({
                message: "Are you sure to delete the selected combinations?",
                title: "Delete combinations"
            });

            if (wantToDelete) {
                await deleteConditions(idsToRemove);
                commonTableRef.current?.redrawTableRows();
            }
        } else {
            toast.info("No combinations selected");
        }
    };

    const deleteConditions = async (idsToRemove: number[]) => {
        let selectedRows: Leg2Rules[] = commonTableRef.current?.getSelectedRows() as Leg2Rules[];

        const response = await legsService.deleteLeg2Combinations(idsToRemove, "Removing combinations...", "Combinations deleted successfully", "Error while deleting combinations");
        if (response.success) {
            commonTableRef.current?.deleteTableRows(selectedRows);
        }
    };

    const confirmSaveCombinations = async () => {
        commonTableRef.current?.setStopEditing();
        const wantToSave = await customConfirmAlert({
            message: "Are you sure to save the combinations?",
            title: "Save combinations"
        });

        if (wantToSave) {
            await saveCombinations();
        }
    };

    const saveCombinations = async () => {
        let tableRows = commonTableRef.current?.getTableRows() as Leg2Rules[];
        let newRowToInsert = commonTableRef.current?.getNewRowToInsert();
        tableRows = newRowToInsert !== undefined ? [newRowToInsert, ...tableRows] : tableRows;

        if (tableRows.length === 0) {
            toast.info("No combinations to save");
            return;
        }

        // Check some value null
        let foundNullValue = false;
        let nullableFields = ["tR", "tS", "firstPlanningTypeId", "secondPlanningTypeId"];

        Object.keys(new Leg2Rules()).filter(field => !nullableFields.includes(field)).forEach(field => {
            if (tableRows.some((row: any) => {
                let value = row[field];
                if (value === "" || value === null) {
                    return true;
                }

                return false
            })) {
                foundNullValue = true;
            }
        });

        if (foundNullValue) {
            toast.warning("There are some null values");
            return;
        }

        const response = await legsService.updateLeg2Combinations(tableRows, "Saving combinations...", "Combinations saved successfully", "Error while saving combinations");
        if (response.success) {
            commonTableRef.current?.cancelNewRowInsertion();
            commonTableRef.current?.setUnsavedCells([]);
            commonTableRef.current?.setRowsInError([]);

            setConflictRows([]);
            setInAdding(false);
            getLeg2Combinations();
        } else if (response.statusCode === 400) {
            if (!!response.data.detailObject) {
                commonTableRef.current?.setRowsInError(response.data.detailObject);
            }
        } else if (response.statusCode === 409) {
            setConflictRows(!!response.data.detailObject ? response.data.detailObject : []);
        }

        setTimeout(function () { commonTableRef.current?.redrawTableRows() }, 0)
    };

    const customValueSetter = (params: ValueSetterParams) => {
        let newValue = params.newValue;
        let field = params.colDef.field as string;
        params.data[field] = newValue;

        return true;
    };

    const cancelNewRow = () => {
        commonTableRef.current?.cancelNewRowInsertion();
        setInAdding(false);
    };
    // #endregion

    // #region CallBack
    const onGridReady = useCallback(() => {
        getTemplates();
        getLeg2Combinations();
    }, []);
    // #endregion

    // #region Use Effects

    // #endregion

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Leg 2 Combinations
                </div>
            </div>

            <AuthorizedComponent area={PermissionAreas.ConfigurationHub_LegCombinations} operation={PermissionOperations.Modify}>
                <div className="mb-6 flex items-center">
                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px]"
                        callback={onClickAddNewCombination}
                        disabled={inAdding}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3333 9.16602V9.66602H10.8333H15.3333V10.3327H10.8333H10.3333V10.8327V15.3327H9.66667V10.8327V10.3327H9.16667H4.66667V9.66602H9.16667H9.66667V9.16602V4.66602H10.3333V9.16602Z" fill="#3987E2" stroke="white" />
                        </svg>
                    </Button>

                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px] ml-[12px]"
                        callback={onClickDeleteButton}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z" /></svg>
                    </Button>

                    <div className="ml-[12px]">
                        <Button
                            callback={onClickSaveButton}
                            className="bg-transparent text-black border-[1.4px] border-black"
                        >
                            Save configuration
                        </Button>
                    </div>

                    {
                        conflictRows.length > 0 &&
                        <div className="ml-7 flex cursor-pointer" onClick={() => setOpenModalShowConflicts(true)}>
                            <div className="border-red pt-[10px] pl-[15px] pr-[15px] pb-[10px] border-[3px] rounded-[10px] flex">
                                <svg className="text-red" xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 10 10"><path fill="currentColor" d="M10 5A5 5 0 1 0 0 5a5 5 0 0 0 10 0M9 5a4 4 0 0 1-6.453 3.16L8.16 2.547C8.686 3.224 9 4.076 9 5M7.453 1.84L1.84 7.453A4 4 0 0 1 7.453 1.84" /></svg>
                                <span className="ml-3">Some legs combinations are overlapping</span>
                            </div>
                        </div>
                    }
                </div>
            </AuthorizedComponent>

            <div style={containerStyle}>
                <CommonTable
                    ref={commonTableRef}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    valueSetter={customValueSetter}
                    options={{
                        pageSize: 10,
                        showSelectionCheckbox: hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify),
                        showRowsNumber: true,
                        editable: hasPermission(PermissionAreas.ConfigurationHub_LegCombinations, PermissionOperations.Modify)
                    }}
                />
            </div>

            {openModalShowConflicts === true &&
                <ModalShowLeg2CombinationsConflicts
                    callbackCloseModal={() => setOpenModalShowConflicts(false)}
                    conflicts={conflictRows}
                    businessFlowTypesOptions={businessFlowTypesOptions}
                    collectionTemplatesOptions={collectionTemplatesOptions}
                    deliveryTemplatesOptions={deliveryTemplatesOptions}
                    legPlanningTypesOptions={legPlanningTypesOptions}
                    legTypesOptions={legTypesOptions}
                />
            }
        </div>
    )
}

export default PlanningToolLeg2Combinations