export enum PermissionAreas {
    GroupCondition = "GroupCondition",
    TakeInCharge = "TakeInCharge",
    RoutingCondition = "RoutingCondition",
    Planning = "Planning",
    Dashboard = "Dashboard",
    Triage = "Triage",
    Cutoff = "Cutoff",
    ManageDepartment = "ManageDepartment",
    ManageGroup = "ManageGroup",
    DeveloperOperations = "DeveloperOperations",
    InfoOrdersAndTrips = "InfoOrdersAndTrips",
    ConfigurationHub = "ConfigurationHub",
    ConfigurationHub_TripCombinations = "ConfigurationHub_TripCombinations",
    ConfigurationHub_TripRules = "ConfigurationHub_TripRules",
    ConfigurationHub_LegCombinations = "ConfigurationHub_LegCombinations",
    AreasConfiguration = "AreasConfiguration"
};

export enum PermissionOperations {
    ForceTakeOut = "ForceTakeOut",
    Modify = "Modify",
    TakeInCharge = "TakeInCharge",
    View = "View",
    SendTrip = "SendTrip",
    TestDebugActions = "TestDebugActions"
};

export interface IPermission {
    role: string;
    area: PermissionAreas;
    opeation: PermissionOperations;
};