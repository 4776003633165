import React, { useEffect, useRef, useState } from "react";
import Button from "../../ui/button/Button";
import Modal from "../../ui/modal/Modal";
import { ordersService } from "../../../api/ordersService/ordersService";
import { Table } from "react-bootstrap";
import dayjs from "dayjs";
import InputText from "../../ui/inputText/InputText";
import { toast } from "react-toastify";
import { OrderErrorsHandlingEnum } from "../../../types/orders";

export type LegErrorType = {
  orderNumber: string;
  errors: {
    isCollection: boolean;
    info: {
      id: number;
      source: string;
      errCode: string;
      errDesc: string;
      timestamp: string;
      errorHandling: string;
      handledText: string;
      handledUser: string;
      handledTimeStamp: Date;
    }[];
  }[];
};

type Props = {
  callbackCloseModal: () => any;
  orderId: number;
};

const ModalOrderLegsErrors: React.FC<Props> = ({
  callbackCloseModal,
  orderId,
}) => {
  const [errors, setErrors] = useState<LegErrorType[]>([]);
  const [errorHandling, SetErrorHandling] = useState<Record<string, string>>(
    {}
  );

  const canGo = useRef(true);
  useEffect(() => {
    if (canGo.current) {
      canGo.current = false;
      getSetErrors(orderId);
    }
  }, [orderId]);

  const getSetErrors = async (orderId: number) => {
    const response = await ordersService.getOrderErrors(orderId);

    if (response.success) {
      setErrors(response.data);
    }
  };

  const handleSetErrorHandling = (
    e: string,
    mainIndex: number,
    subIndex: number
  ) => {
    const key = `${mainIndex}-${subIndex}`;
    SetErrorHandling((prev) => ({
      ...prev,
      [key]: e,
    }));
  };

  const saveErrorHandling = async (
    id: number,
    mainIndex: number,
    subIndex: number
  ) => {
    const key = `${mainIndex}-${subIndex}`;
    const errorHandlingText = errorHandling[key];

    if (!errorHandlingText) {
      toast.info("Please enter a non-empty message");
      return;
    }

    const response = await ordersService.saveErrorHandling(
      id,
      errorHandlingText,
      "Saving error handling",
      "Error handling saved",
      "Error saving error handling"
    );

    if (response.success) {
      getSetErrors(orderId);
    }
  };

  return (
    <Modal
      callbackCloseModal={callbackCloseModal}
      title="Order legs errors"
      footer={
        <div className="w-full flex items-center justify-end">
          <Button callback={callbackCloseModal}>Close</Button>
        </div>
      }
      customStyleChildrenContainer={{ overflow: "auto" }}
      customStyleContainer={{
        maxHeight: "75%",
        width: "80%",
        maxWidth: "1920px",
      }}
    >
      <div className="h-full overflow-auto">
        {/* @ts-ignore */}
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="w-[10%]">Order Number</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {errors.map((error, index) => (
              <tr key={"1-" + index}>
                <td>{error.orderNumber}</td>

                <td>
                  {/* @ts-ignore */}
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th className="w-[10%]">Leg Type</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {error.errors.map((subError, j) => (
                        <tr key={"2-" + j}>
                          <td>
                            {subError.isCollection ? "Collection" : "Delivery"}
                          </td>

                          <td>
                            {/* @ts-ignore */}
                            <Table
                              style={{ tableLayout: "fixed" }}
                              bordered
                              responsive
                            >
                              <thead>
                                <tr>
                                  <th>Source</th>
                                  <th>Error Code</th>
                                  <th>Error Description</th>
                                  <th>Error Date</th>
                                  <th>Error Handling</th>
                                </tr>
                              </thead>
                              <tbody>
                                {subError.info.map((info, k) => (
                                  <React.Fragment key={"3-" + k}>
                                    <tr>
                                      <td>{info.source}</td>
                                      <td>{info.errCode}</td>
                                      <td>{info.errDesc}</td>
                                      <td>
                                        {dayjs(info.timestamp).format(
                                          "DD/MM/YYYY HH:mm"
                                        )}
                                      </td>
                                      <td>{info.errorHandling}</td>
                                    </tr>
                                    {[
                                      OrderErrorsHandlingEnum.USER_OPTIONAL_ACTION.valueOf(),
                                      OrderErrorsHandlingEnum.USER_ACTION_REQUIRED_EXTERNAL_SYSTEM.valueOf(),
                                    ].includes(info.errorHandling) && (
                                      <tr>
                                        <td colSpan={5}>
                                          {!info.handledText && (
                                            <div className="flex mt-2 w-[100%]">
                                              <InputText
                                                callbackOnChange={(e) =>
                                                  handleSetErrorHandling(
                                                    e,
                                                    index,
                                                    k
                                                  )
                                                }
                                                containerClass="w-[100%]"
                                                name="order_number"
                                                placeholder="Enter handling message"
                                                value={
                                                  errorHandling[
                                                    `${index}-${k}`
                                                  ] ?? ""
                                                }
                                              />
                                              <Button
                                                callback={() =>
                                                  saveErrorHandling(
                                                    info.id,
                                                    index,
                                                    k
                                                  )
                                                }
                                                className="ml-2 text-white bg-blue border border-[1.4px] border-blue"
                                              >
                                                Save
                                              </Button>
                                            </div>
                                          )}
                                          {info.handledText && (
                                            <>
                                              <div>
                                                <span className="font-bold">
                                                  Handled error message:{" "}
                                                </span>{" "}
                                                &nbsp; {info.handledText}
                                              </div>
                                              <div>
                                                <span className="font-bold">
                                                  Handled error user:{" "}
                                                </span>{" "}
                                                &nbsp; {info.handledUser}
                                              </div>
                                              <div>
                                                <span className="font-bold">
                                                  Handled error timestamp:{" "}
                                                </span>{" "}
                                                &nbsp;{" "}
                                                {dayjs(
                                                  info.handledTimeStamp
                                                ).format("DD/MM/YYYY HH:mm")}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Modal>
  );
};

export default ModalOrderLegsErrors;
