import React from "react";
import ReactDOM from "react-dom";
import { CardConsts } from "../consts/cardConst";
import { MapConsts } from "../consts/mapConsts";
import { tripStatus } from "../consts/tripStatusConsts";
import { orderStatus } from "../consts/orderStatusConsts";
import { mapAPI } from "../api/map/mapAPI";

import { MapPopupContainer } from "../view/containers";

export const MapService = {
  getAPIBySelectedCard: selected => {
    var api = "";
    //eslint-disable-next-line
    switch (selected) {
      case CardConsts.cardIDs.trips.active:
        api = mapAPI.getActiveTripsForMap;
        break;
      case CardConsts.cardIDs.trips.booking:
        api = mapAPI.getBookingTripsForMap;
        break;
      case CardConsts.cardIDs.trips.trInTransit:
        api = mapAPI.getInTransitTripsForMap;
        break;
      case CardConsts.cardIDs.trips.loadUnload:
        api = mapAPI.getLoadUnloadTripsForMap;
        break;
      case CardConsts.cardIDs.trips.gps:
        api = mapAPI.getGPSTripsForMap;
        break;
      case CardConsts.cardIDs.trips.noGps:
        api = mapAPI.getNoGPSTripsForMap;
        break;
      case CardConsts.cardIDs.orders.collWithoutDMI:
        api = mapAPI.getCollectedNoDMIOnLegs;
        break;
      // case CardConsts.cardIDs.orders.inProgress: 18/04/2023 - LOGICA DA RIVEDERE. IN ATTESA DI CAPIRE SE SI SOVRAPPONE ALLA OUT FOR COLLECTION, LA NASCONDIAMO TRA LE CARD SELEZIONABILI
      //     api = mapAPI.getCollectionInProgress;
      //     break;
      case CardConsts.cardIDs.orders.inXdock:
        api = mapAPI.getOrderInXdock;
        break;
      case CardConsts.cardIDs.orders.missColl:
        api = mapAPI.getOrderMissedCollections;
        break;
      case CardConsts.cardIDs.orders.missDelivery:
        api = mapAPI.getOrderMissedDeliveries;
        break;
      case CardConsts.cardIDs.orders.orInTransit:
        api = mapAPI.getOrderInTransit;
        break;
      case CardConsts.cardIDs.orders.outForColl:
        api = mapAPI.getOrderOutForCollection;
        break;
      case CardConsts.cardIDs.orders.outForDelivery:
        api = mapAPI.getOrderOutForDelivery;
        break;
      case CardConsts.cardIDs.orders.partDelivery:
        api = mapAPI.getOrderPartialDeliveries;
        break;
      case CardConsts.cardIDs.orders.toBeColl:
        api = mapAPI.getMapOrderToBeCollected;
        break;
      case CardConsts.cardIDs.orders.toBeDelivered:
        api = mapAPI.getOrderToBeDelivered;
        break;
      case CardConsts.cardIDs.orders.withoutCollLeg:
        api = mapAPI.getOrderNoCollectionLeg;
        break;
      case CardConsts.cardIDs.orders.withoutDE:
        api = mapAPI.getOrderWithoutDataEntry;
        break;
      case CardConsts.cardIDs.orders.withoutDeliveryLeg:
        api = mapAPI.getOrderWithoutDeliveryLeg;
        break;
    }
    //eslint-disable-next-line
    if (api === "") throw "Cannot detect API for selected card " + selected;

    return api;
  },

  getPopupAPIByMapType: type => {
    var api = "";
    //eslint-disable-next-line
    switch (type) {
      case MapConsts.mapType.trip:
        api = mapAPI.getTripDataForPopupMap;
        break;
      case MapConsts.mapType.to:
        api = mapAPI.getOrderDataForPopupMap;
        break;
    }
    //eslint-disable-next-line
    if (api === "") throw "Cannot detect API for map type " + type;

    return api;
  },

  getMarkerColorByTripStatus: tripSt => {
    var color = "";
    //eslint-disable-next-line
    switch (tripSt) {
      case tripStatus.inTime:
        color = MapConsts.markerColors.trips.inTime;
        break;
      case tripStatus.inTransit:
        color = MapConsts.markerColors.trips.inTransit;
        break;
      case tripStatus.delayed:
        color = MapConsts.markerColors.trips.delayed;
        break;
      case tripStatus.noGPS:
        color = MapConsts.markerColors.trips.noGPS;
        break;
      case tripStatus.noSignal:
        color = MapConsts.markerColors.trips.noSignal;
        break;
    }
    //eslint-disable-next-line
    if (color === "")
      throw "Cannot detect marker color for trip status " + tripSt;

    return color;
  },

  getMarkerColorByOrderStatus: orderSt => {
    var color = "";
    //eslint-disable-next-line
    switch (orderSt) {
      case orderStatus.onTime:
        color = MapConsts.markerColors.orders.onTime;
        break;
      case orderStatus.runningLate:
        color = MapConsts.markerColors.orders.runningLate;
        break;
      case orderStatus.updated:
        color = MapConsts.markerColors.orders.updated;
        break;
      case orderStatus.notUpdated:
        color = MapConsts.markerColors.orders.notUpdated;
        break;
      case orderStatus.noSignal:
        color = MapConsts.markerColors.orders.noSignal;
        break;
    }
    //eslint-disable-next-line
    if (color === "")
      throw "Cannot detect marker color for order status " + orderSt;

    return color;
  },

  setBaseTileLayer(pois, map, mapTileType, apiKey) {
    const H = window.H;
    var hd = window.devicePixelRatio > 1;
    let tileSize = hd ? 512 : 256;
    let prmPois = pois ? "&pois=" + pois : "";
    var provider = new H.map.provider.ImageTileProvider({
      min: 0,
      max: 20,
      getURL: function(x, y, z) {
        return (
          "https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/" +
          mapTileType +
          "/" +
          z +
          "/" +
          x +
          "/" +
          y +
          "/" +
          tileSize +
          "/png8?apiKey=" +
          apiKey +
          prmPois
        );
      },
    });
    let layer = new H.map.layer.TileLayer(provider);
    map.setBaseLayer(layer);
    /*var baseService = platform.getMapTileService({type: 'base'});
        map.setBaseLayer(baseService.createTileLayer(
            'maptile',
            'normal.day', // 'hybrid.traffic.day' for satellite + traffic
            hd ? 512 : 256, // tileSize,
            'png8',
            {
              'ppi': hd ? 320 : 72,
              'pois': '!00000000F' //bug for ! symbol - should not urlencode
            }
          )); */
  },

  getBaseTileLayer(pois, mapTileType, apiKey) {
    const H = window.H;
    var hd = window.devicePixelRatio > 1;
    let tileSize = hd ? 512 : 256;
    let prmPois = pois ? "&pois=" + pois : "";
    var provider = new H.map.provider.ImageTileProvider({
      min: 0,
      max: 20,
      getURL: function(x, y, z) {
        return (
          "https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/" +
          mapTileType +
          "/" +
          z +
          "/" +
          x +
          "/" +
          y +
          "/" +
          tileSize +
          "/png8?apiKey=" +
          apiKey +
          prmPois
        );
      },
    });
    return new H.map.layer.TileLayer(provider);
  },

  events: {
    resize: map => {
      return () => {
        try {
          map.getViewPort().resize();
        } catch (e) {
          // console.log(e)
        }
      };
    },

    pointerMove: (map, H) => {
      return event => {
        if (
          event.target instanceof H.map.Marker ||
          event.target instanceof H.map.DomMarker
        ) {
          map.getViewPort().element.style.cursor = "pointer";
        } else {
          map.getViewPort().element.style.cursor = "auto";
        }
      };
    },

    clusterClick: (clusteredDataProvider, map, H, mapType, ui) => {
      return evt => {
        // get the data of the object clicked
        let cnt = evt.target.getData();
        // if those data contain a data object it was a marker to be clicked
        // mine has a string (not yet set in the code above) which I show inside an InfoBubble
        if (cnt.dm && typeof cnt.dm.data !== "undefined") {
          var geometry = evt.target.getGeometry();
          var pos = { lat: geometry.lat, lng: geometry.lng };
          map.setCenter(pos, true);

          const element = (
            <MapPopupContainer mapType={mapType} tmsId={cnt.dm.data.id} />
          );
          ReactDOM.render(
            element,
            document.getElementById("map-popup-container")
          );

          let bubble = new H.ui.InfoBubble(geometry, {
            content: document.getElementById("map-popup-container"),
          });

          ui.getBubbles().forEach(bub => ui.removeBubble(bub));

          ui.addBubble(bubble);
        } else {
          // otherwise it was a cluster icon which doesn't contain a data object
          // set the map center to the coordinates where the user clicked
          // and increase the zoom level
          // "true" is to have a smooth transition
          map.getViewModel().setLookAtData(
            {
              bounds: evt.target.getData().getBoundingBox(), // zoom is done in order to exploding the entire cluster (getBoundingBox of cluster's elements)
            },
            true
          );
        }
      };
    },

    viewChanged: (map, callbackFn) => {
      return e => {
        let bounds = map.getViewModel().getLookAtData().bounds;
        var group = map.getObjects()[0];
        if (group) group.setVisibility(true); // set visibility true so we can check visible objects within viewport
        map.getObjectsWithin(bounds, objects => {
          callbackFn(objects.length > 0 ? objects.map(o => o.getData()) : []);

          if (group) group.setVisibility(false);
        });
      };
    },

    detachAll: map => {
      window.removeEventListener("resize", MapService.events.resize(map));
      map.removeEventListener("pointermove", MapService.events.pointerMove);
    },
  },
};
