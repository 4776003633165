import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import jwt from "jsonwebtoken";

export const UOM_ROLE_ADMIN = "Admin";
export const UOM_ROLE_IT = "IT";
export const UOM_ROLE_BUSINESS = "Business";
export const UOM_ROLE = "uom_role";
export const UOM_ROLE_EVENT_MONITORING = "event_monitoring";
export const UOM_EVENT_MONITORING_VALUE = "TRUE";

export const IsAuthorized = () => {
  return TokenExist() && IsValidJwtToken() && !IsTokenExpired();
};

export const TokenExist = () => window.sessionStorage.getItem("accessToken");

export const IsValidJwtToken = () =>
  jwt.decode(window.sessionStorage.getItem("accessToken"));

export const IsTokenExpired = () =>
  jwt.decode(window.sessionStorage.getItem("accessToken")).exp * 1000 <
  Date.now();

export const GetBase64Url = string => {
  return string
    .toString(Base64)
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};

export const GenerateCodeVerifier = () => {
  const length = 128;
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export const GenerateCodeChallenge = codeVerifier => {
  return GetBase64Url(sha256(codeVerifier));
};

export const GetAccessToken = () => {
  if (IsAuthorized()) return window.sessionStorage.getItem("accessToken");

  return null;
};

export const GetUser = () => {
  if (IsAuthorized())
    return jwt.decode(window.sessionStorage.getItem("accessToken"));

  return null;
};

/*export const isAdmin = () => {
    return GetUser().feature.indexOf("PORTAL_UOM_ADMIN") > 0;
    //return 1 == 1;
}*/

export const OwnerCompany = () => {
  var user = GetUser();

  if (user == null || user == undefined) return null;

  var owner_company = user["owner_company"];
  if (!owner_company || owner_company == null) {
    return null;
  }
  return owner_company.trim();
};

export const GetFlow = () => {
  var user = GetUser();

  if (user == null || user == undefined) return null;

  var flow = user["flow"];
  if (!flow || flow == null) {
    return null;
  }
  return flow.trim();
};

export const AdditionalCompanies = separator => {
  var additional_companies = [];
  var additional_company = GetUser()["additional_company"];
  if (additional_company) {
    additional_companies = additional_company
      .split(separator)
      .map(elem => elem.trim());
  }
  return additional_companies;
};

export const hasClaim = (claimType, claimName) => {
  if (!!GetUser() && GetUser()[claimType] != undefined)
    return GetUser()[claimType] == claimName;
  else return false;
};

export const existClaim = claimType => {
  if (!!GetUser() && GetUser()[claimType] != undefined)
    return GetUser()[claimType].replaceAll(/\s/g, "") !== "";
  else return false;
};

export const includeTechnicalCustomers = claimType => {
  if (!!GetUser() && GetUser()[claimType] != undefined)
    return (
      GetUser()[claimType] == UOM_ROLE_ADMIN ||
      GetUser()[claimType] == UOM_ROLE_IT
    );
  else return false;
};

export const hasFeature = featureName => {
  if (!!GetUser() && GetUser().feature != undefined)
    return GetUser().feature.indexOf(featureName) >= 0;
  else return false;
};

export const hasScope = scope => {
  return !!GetUser() && GetUser().scope.includes(scope);
};

export const hasPlanningToolAccess = () => {
    return hasFeature("PORTAL_PT") && !!GetUser().pt_role;
};

export const getUserMailboxes = () => {
  const user = GetUser();

  if (user) {
    const mailboxes = user["uom_mailbox"];
    if (typeof mailboxes === "string" || mailboxes instanceof String)
      return [mailboxes];
    else if (mailboxes && mailboxes.constructor === Array) return mailboxes;
    else return [];
  } else return [];
};
