import React, { useEffect, useState } from "react";
import config from "../../../../settings";
import { getTimeFromDate, formatDate } from "../../../../utils/DateUtils";

export const PositionElement = ({ position, delayMultiplier }) => {
  const [address, setAddress] = useState({ place: "--", address: "" });

  useEffect(() => {
    const H = window.H;
    const platform = new H.service.Platform({
      apikey: config.HMap_AppKey,
    });

    const searchService = platform.getSearchService();

    setTimeout(
      () =>
        searchService.reverseGeocode(
          { at: "" + position.lat + "," + position.lng },
          result => {
            // console.log(result)
            const place = result.items.find(
              el => el.resultType == "locality" || el.resultType == "place"
            );
            if (place) {
              // GI: introdotto controllo su title !== da address.label per evitare di mostrare un'informazione doppia
              setAddress({
                place: place.title,
                address:
                  place.title !== place.address.label
                    ? place.address.label
                    : "",
              });
            } else if (result.items.length > 0) {
              const item = result.items[0];
              // GI: introdotto controllo su title !== da address.label per evitare di mostrare un'informazione doppia
              setAddress({
                place: item.title,
                address:
                  item.title !== item.address.label ? item.address.label : "",
              });
            }
          },
          console.error
        ),
      delayMultiplier * 100
    );
  }, []);

  return (
    <div>
      <div className="position-event">
        <div>
          <h1>{address.place}</h1>
          {/* <h2>{address.address}</h2> */}
          <p
            className="subtext"
            style={!address.address ? { marginTop: "-10px" } : {}}
          >
            ({position.provider})
          </p>
        </div>
        <div className="date-time-container">
          <div className="date-time">
            {position.dateTime.map((date, index) => (
              <div key={index}>
                <h1>{formatDate(date)}</h1>
                <h3>{getTimeFromDate(date)}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="lat-lon">
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <p className="upper-gray">Lat:</p>
            <p>{Number(position.lat.toFixed(5))}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <p className="upper-gray" style={{ marginLeft: "10px" }}>
              Lon:
            </p>
            <p>{Number(position.lng.toFixed(5))}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
