export type ObjParamsBaseFilter = {
    operator?: null | 'AND' | 'OR',
    conditions: {
        type?: "startsWith" | "contains",
        value: any,
        valueFrom?: number,
        valueTo?: number
    }[],
    valuesSet?: any[]
}

export const getFilterParamsSSR = (colId: string, filterModel: any) => {
    if(!filterModel[colId]) {
        return null;
    }

    let objBase: ObjParamsBaseFilter = {
        operator: null,
        conditions: []
    }

    let colFilterModel = filterModel[colId];

    if (!!!colFilterModel) {
        return undefined;
    }

    let valueFilterModel = !!colFilterModel.filterType && colFilterModel.filterType !== "set" ? colFilterModel : null;
    let setFilterModel = !!colFilterModel.filterType && colFilterModel.filterType === "set" ? colFilterModel : null;

    if (!!!valueFilterModel && !!!setFilterModel) {
        return colFilterModel.filter;
    }

    if (colFilterModel.filterType === "multi") {
        valueFilterModel = colFilterModel.filterModels.find((x: any) => !!x && x.filterType !== "set");
        setFilterModel = colFilterModel.filterModels.find((x: any) => !!x && x.filterType === "set");
    }

    objBase.valuesSet = setFilterModel?.values;

    if (!!valueFilterModel) {
        if (!!valueFilterModel.conditions && valueFilterModel.conditions.length > 0 && !!valueFilterModel.operator) {
            objBase.operator = valueFilterModel.operator;
            objBase.conditions = valueFilterModel.conditions.map((condition: any) => {
                return {
                    type: condition.type,
                    value: condition.filter
                }
            });
        } else {
            objBase.conditions = [{
                value: valueFilterModel.filter,
                type: valueFilterModel.type,
                valueFrom: valueFilterModel.filter,
                valueTo: valueFilterModel.filterTo
            }];
        }
    }

    return objBase;
}