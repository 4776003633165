import { createSlice } from "@reduxjs/toolkit";

export const advSearchInitialState = {
  openRequest: false,
  generalFilters: {
    TimeSpan: {
      InsertDateFrom: new Date(new Date().setDate(new Date().getDate() - 7)),
      InsertDateTo: new Date(),
    },
  },
  salesOrderFilters: { enabled: false },
  transportOrderFilters: { enabled: true },
  tripFilters: { enabled: false },
};

const clearFillGeoButton = () => {
  let geoButtons = document.querySelectorAll(".single-geo-btn");

  if (geoButtons) {
    geoButtons.forEach(el => {
      el.classList.remove("fill");
    });
  }
};

const advSearchSlice = createSlice({
  name: "advSearch",
  initialState: advSearchInitialState,
  reducers: {
    setGeneralFilters(state, action) {
      state.generalFilters[action.payload.propName] = action.payload.propValue;
    },
    setSalesOrderFilters(state, action) {
      state.salesOrderFilters[action.payload.propName] =
        action.payload.propValue;
    },
    setTransportOrderFilters(state, action) {
      state.transportOrderFilters[action.payload.propName] =
        action.payload.propValue;
    },
    setTripFilters(state, action) {
      state.tripFilters[action.payload.propName] = action.payload.propValue;
    },
    clearGeneralFilters(state, action) {
      delete state.generalFilters[action.payload.propName];
    },
    clearSalesOrderFilters(state, action) {
      delete state.salesOrderFilters[action.payload.propName];
    },
    clearTransportOrderFilters(state, action) {
      delete state.transportOrderFilters[action.payload.propName];
      clearFillGeoButton();
    },
    clearTripFilters(state, action) {
      delete state.tripFilters[action.payload.propName];
    },
    clearAllGeneralFilters(state, action) {
      // Delete every general filter except the TimeSpan
      Object.keys(state.generalFilters).map(k => {
        if (k !== "TimeSpan") {
          delete state.generalFilters[k];
        }
      });
    },
    clearAllSalesOrderFilters(state, action) {
      Object.keys(state.salesOrderFilters).map(
        k => delete state.salesOrderFilters[k]
      );
    },
    clearAllTransportOrderFilters(state, action) {
      Object.keys(state.transportOrderFilters).map(
        k => delete state.transportOrderFilters[k]
      );

      clearFillGeoButton();
    },
    clearAllTripFilters(state, action) {
      Object.keys(state.tripFilters).map(k => delete state.tripFilters[k]);
    },
    clearAllFilters(state, action) {
      Object.keys(state.generalFilters).map(
        k => delete state.generalFilters[k]
      );
      Object.keys(state.salesOrderFilters).map(
        k => delete state.salesOrderFilters[k]
      );
      Object.keys(state.transportOrderFilters).map(
        k => delete state.transportOrderFilters[k]
      );
      clearFillGeoButton();
      Object.keys(state.tripFilters).map(k => delete state.tripFilters[k]);
    },
    setOpenRequest(state, action) {
      state.openRequest = action.payload;
    },
  },
});

export const {
  setGeneralFilters,
  setSalesOrderFilters,
  setTransportOrderFilters,
  setTripFilters,
  clearGeneralFilters,
  clearSalesOrderFilters,
  clearTransportOrderFilters,
  clearTripFilters,
  clearAllGeneralFilters,
  clearAllSalesOrderFilters,
  clearAllTransportOrderFilters,
  clearAllTripFilters,
  clearAllFilters,
  setOpenRequest,
} = advSearchSlice.actions;

export default advSearchSlice.reducer;
