import { CSSProperties } from "react";

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: "flex",
    minHeight: "70px",
    flexDirection: "column",
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    columnGap: "8px",
  },
  tripsInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "8px",
  },
  textBlue: {
    fontSize: "14px",
    margin: 0,
    padding: 0,
    lineHeight: "1",
    fontWeight: "bold",
    color: "#3988e2", 
  },
  tripsCount: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    lineHeight: "1",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "8px",
  },
};

export default styles;
