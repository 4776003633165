import { GetFlow } from "../../utils/AuthUtils";
// Filters for API are built in frontend with CustomersIds property.
// But in case of API for trips, this property is named CarrierCodes in backend side,
// so we copy CustomersIds value in CarrierCodes property.
export function CustomerIdsToCarrierCodesSubstitution(filters) {
  if (filters) {
    filters.CarrierCodes = null;

    if (filters.CarriersIds) {
      filters.CarrierCodes = filters.CarriersIds;
      delete filters.CarriersIds;
    }

    filters.Flow = GetFlow();
  }

  return filters;
}

export function AddClaimsToFilters(filters) {
  if (filters) {
    filters.Flow = GetFlow();
  }

  return filters;
}
