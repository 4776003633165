import React, { useEffect, useMemo, useRef } from 'react';
import CommonTable, { TableRef } from '../../components/commonTable/CommonTable';
import { TripRule } from '../../types/trips';

type Props = {
    conflicts: TripRule[];
    callbackCloseModal: () => any;
    templateOptions: { value: string, label: string }[];
    combinationsOptions: { value: string, label: string }[];
};

const ModalShowTripRulesConflicts: React.FC<Props> = ({
    conflicts,
    callbackCloseModal,
    templateOptions,
    combinationsOptions,
}) => {

    // #region State and Refs
    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo 
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs: any = useMemo(() => [
        {
            field: 'ruleCode',
            headerName: "RULE CODE",
            editable: false,
            cellRenderer: (props: { data: TripRule}) => {
                return (
                    <>
                        <>{props.data.ruleCode}</>
                    </>
                )
            }
        },
        {
            field: 'templateCode',
            headerName: "TEMPLATE CODE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: TripRule}) => {
                return (
                    <>
                        <>{templateOptions.find(x => x.value === props.data.templateCode)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'combinations',
            headerName: "COMBINATIONS",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: TripRule }) => {
                return (
                    <>
                        {
                            !!props.data.combinations && props.data.combinations.map((comb) => {
                                return (
                                    <div className="flex items-center">
                                        <div className={"text-xs rounded-[4px] h-full border-[1.5px] p-[6px] mr-2"}>
                                            <div className={"flex items-center font-bold"}>
                                                <div className="text-[13px] leading-[16px]">
                                                    {comb}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                )
            }
        }
    ], []);
    // #endregion

    // #region Util Functions
    
    // #endregion

    // #region Use Effects
    useEffect(() => {
        let insertionRow: any = conflicts.find(x => x.ruleCode === null);
        if (!!insertionRow) {
            insertionRow.isHighligthed = true;
        }
        
        commonTableRef.current?.setTableDataState(conflicts);
    }, [conflicts]);
    // #endregion

    return (
        <div style={{ zIndex: 9999 }} className="fixed top-0 left-0 h-screen w-screen bg-[#00000080] flex items-center justify-center">
            <div className="bg-white max-h-[75%] w-[95%] lg:w-[80%] max-w-[1920px] flex flex-col">
                <div className="shrink-0 flex justify-between border-b p-[30px]">
                    <div className="text-lg font-bold">
                        Conflict rows
                    </div>
                    <div onClick={callbackCloseModal} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>

                {/* MAIN CONTENT */}
                <div className="grow py-8 px-[30px] select-none overflow-auto relative">
                    <div className="mt-5" style={containerStyle}>
                        <CommonTable
                            ref={commonTableRef}
                            columnDefs={columnDefs}
                            options={{
                                showRowsNumber: false,
                                filter: false,
                                floatingFilter: false,
                                editable: false,
                                pageSize: 10
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModalShowTripRulesConflicts;