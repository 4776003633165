import React, { useRef, useState, useMemo, useContext, useEffect } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { IOrder } from "../../../types/orders";
import dayjs from "dayjs";
import Button from "../../ui/button/Button";
import Select from "../../ui/select/Select";
import { IMasterDataContext, MasterDataContext } from "../../../context/MasterDataContext";
import Modal from "../../ui/modal/Modal";
import { ordersService } from "../../../api/ordersService/ordersService";
import { toast } from "react-toastify";
import ModalShowShipmentUnits from "../modalShowShipmentUnits/ModalShowShipmentUnits";
import CommonTable, { TableRef } from "../../commonTable/CommonTable";
import ModalOrderNotes from "../modalOrderNotes/ModalOrderNotes";

type Props = {
    callbackCloseModal: () => any;
    order: IOrder | null;
    callbackSeparateOrders: () => any;
    canEdit: boolean;
}

const ModalShowGroupedOrders: React.FC<Props> = ({
    callbackCloseModal,
    order,
    callbackSeparateOrders,
    canEdit
}) => {

    const gridContainerRef = useRef<any>(null);
    const commonTableRef = useRef<TableRef>(null);

    const { equipments } = useContext(MasterDataContext) as IMasterDataContext;

    const [isGridReady, setIsGridReady] = useState(false);
    const [orderNotes, setOrderNotes] = useState<string | null>(null);

    // const autoSizeAll = () => {
    //     const allColumnIds: any[] = [];
    //     gridRef.current.api.getColumns().forEach((column: any) => {
    //         allColumnIds.push(column.getId());
    //     });

    //     gridRef.current.api.autoSizeColumns(allColumnIds, false);
    // };

    const equipmentOptions = useMemo(() => {
        return equipments.map((e) => {
            return {
                value: e.id,
                label: e.name
            }
        });
    }, [equipments]);

    const [dataOrders, setDataOrders] = useState<IOrder[]>([]);

    const canGo = useRef(true);
    useEffect(() => {
        if (!!order && !!canGo && !!canGo.current) {
            canGo.current = false;

            getSetData();
        }
    }, [order]);

    const getSetData = async () => {
        if (!!order) {
            const response = await ordersService.getGroupedOrders(order.id);
            if (response.success) {
                commonTableRef.current?.setTableDataState(response.data);
                setDataOrders(response.data);
            } else {
                toast.error('Error retrieving grouped orders');
            }
        }
    }

    // Modal Shipment units
    const [activeOrderShipmentUnits, setActiveOrderShipmentUnits] = useState<null | {
        orderId: number;
        orderNumber: string;
    }>(null);

    const columnDefs: any = useMemo(() => [
        {
            field: 'orderNumbers[0]',
            headerName: "ORDER N°",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center justify-between">
                        <div>
                            {!!data.orderNumbers ? data.orderNumbers[0] : ''}
                        </div>
                    </div>
                )
            }
        },
        {
            field: 'consigneeDock',
            headerName: "DOCK",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {!!data.consigneeDock ? data.consigneeDock : '-'}
                    </div>
                )
            },
        },
        {
            field: 'volume',
            headerName: "VOLUME",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {data.volume}
                    </div>
                )
            }
        },
        {
            field: 'weight',
            headerName: "WEIGHT",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {data.weight}
                    </div>
                )
            }
        },        
        {
            field: 'taxWeight',
            headerName: "TAX-WEIGHT",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {data.taxWeight}
                    </div>
                )
            }
        },
        {
            field: 'linearMeters',
            headerName: "LINEAR METERS",
            cellRenderer: (props: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {
                            !!order && order.legs.length > 0 &&
                            <div className="flex items-center">
                                <input
                                    className="disabled:opacity-50 disabled:cursor-not-allowed	 text-center border border-[1.4px] border-darkGrey rounded-[4px] w-[32px] h-[30px] p-2"
                                    type="text"
                                    name="linearMeters"
                                    value={!!props.data.linearMeters ? props.data.linearMeters : 0}
                                    disabled={true}
                                />
                                <div className="ml-2 flex items-center h-full">
                                    <Select
                                        className="h-[30px] p-0 "
                                        callbackOnChange={() => { }}
                                        options={equipmentOptions}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        }
                        {
                            !!order && order.legs.length === 0 &&
                            <>
                                {props.data.linearMeters}
                            </>
                        }
                    </div>
                )
            }
        },
        {
            field: 'shipmentUnitsNumber',
            cellStyle: { overflow: 'visible' },
            maxWidth: 100,
            headerName: "SHIPPING UNIT",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <div
                        onClick={() => {
                            setActiveOrderShipmentUnits({
                                orderId: data.id,
                                orderNumber: !!data.orderNumbers ? data.orderNumbers[0] : ''
                            });
                        }}
                        className="overflow-visible h-full flex items-center justify-start z-50 relative"
                    >
                        <div className="relative z-50">
                            <div className={"text-[12px] cursor-pointer" + (!!order && order?.legs.length > 0 ? " text-underlined" : "")}>
                                {data.shipmentUnitsNumber}
                            </div>
                        </div>
                    </div>
                );
            }
        },
        {
            field: 'earlyPickup',
            minWidth: 180,
            headerName: "EARLY PICKUP",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {
                            !!data.earlyPickup &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.earlyPickup).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'earlyDelivery',
            minWidth: 180,
            headerName: "EARLY DELIVERY",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {
                            !!data.earlyDelivery &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.earlyDelivery).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'latePickup',
            minWidth: 180,
            headerName: "LATE PICKUP",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {
                            !!data.latePickup &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.latePickup).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'lateDelivery',
            minWidth: 180,
            headerName: "LATE DELIVERY",
            cellRenderer: ({ data }: { data: IOrder }) => {
                return (
                    <>
                        {
                            !!data.lateDelivery &&
                            <div className="text-[12px] flex h-full items-center">
                                {dayjs(data.lateDelivery).format('DD/MM/YYYY - HH:mm')}
                            </div>
                        }
                    </>
                )
            }
        },
        {
            field: 'customerCollectionID[0]',
            headerName: "CUSTOMER COLLECTION ID",
            cellRenderer: (props: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {!!props.data.customerCollectionID && props.data.customerCollectionID.length > 0 ? props.data.customerCollectionID[0] : '-'}
                    </div>
                )
            }
        },
        {
            field: 'customerLinehaulID[0]',
            headerName: "CUSTOMER LINEHAUL ID",
            cellRenderer: (props: { data: IOrder }) => {
                return (
                    <div className="text-[12px] flex h-full items-center">
                        {!!props.data.customerLinehaulID && props.data.customerLinehaulID?.length > 0 ? props.data.customerLinehaulID[0] : '-'}
                    </div>
                )
            }
        },
        {
            field: 'notes',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            wrapText: true,
            headerName: "NOTES",
            cellStyle: { whiteSpace: 'normal' },
            cellRenderer: (props: { data: IOrder }) => {
                return (
                    <>
                       <div onClick={() => {setOrderNotes(props.data.notes ?? null)}} className="overflow-hidden leading-5 cursor-pointer">
                            {props.data.notes}
                        </div>
                    </>
                )
            }
        }
    ], [isGridReady, dataOrders]);

    return (
        <Modal
            callbackCloseModal={callbackCloseModal}
            title="Orders group"
            footer={
                <div className="w-full flex items-center justify-end">
                    {canEdit &&
                        <Button
                            callback={callbackSeparateOrders}
                        >
                            Separate orders
                        </Button>
                    }
                </div>
            }
            customStyleChildrenContainer={{
                overflow: "visible"
            }}
            customStyleContainer={{
                maxHeight: '75%',
                width: '80%',
                maxWidth: '1920px'
            }}
        >
            <>
                <div className="grow py-8 px-[30px] select-none overflow-auto relative">
                    <div ref={gridContainerRef} className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
                        <CommonTable
                            ref={commonTableRef}
                            columnDefs={columnDefs} // Column Defs for Columns 
                            options={{
                                showRowsNumber: false,
                                filter: false,
                                floatingFilter: false,
                                editable: false,
                                pageSize: 10
                            }}
                        />
                    </div>
                </div>

                {
                    activeOrderShipmentUnits !== null &&
                    <ModalShowShipmentUnits
                        callbackCloseModal={() => setActiveOrderShipmentUnits(null)}
                        orderId={activeOrderShipmentUnits.orderId}
                        orderNumber={activeOrderShipmentUnits.orderNumber}
                        isGroupedOrdersModal={true}
                    />
                }

                {!!orderNotes &&
                    <ModalOrderNotes
                        callbackCloseModal={() => setOrderNotes(null)}
                        notes={orderNotes}
                    />
                }
            </>
        </Modal>
    )
}

export default ModalShowGroupedOrders;