// import dayjs from "dayjs";
import dayjs from "dayjs";
import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import basicRequest from "../basicRequest";
import { PlanningFilterEnum } from "../../components/filterRadio/FilterRadio";

export interface DisableOrdersRequest {
    id: number;
    disable: boolean;
};

export interface UpdateOrderRequest {
    linearMeters: number;
    customEquipmentId: number;
};

export type PaginationGetOrdersRequest = {
    pageSize: number;
    pageIndex: number;
    PlanningStatus: PlanningFilterEnum | null;
    OrderBy?: string | null,
    OrderDirection?: string | null,
    Number?: string | null;
    Origin?: string | null;
    OriginName?: string | null;
    OriginStreet?: string | null;
    OriginCountryCode?: string | null;
    OriginZipCode?: string | null;
    OriginCity?: string | null;

    Destination?: string | null;
    DestinationName?: string | null;
    DestinationStreet?: string | null;
    DestinationCountryCode?: string | null;
    DestinationZipCode?: string | null;
    DestinationCity?: string | null;
    ConsigneeDock?: string | null;
    
    Volume?: string | null;
    Weight?: string | null;
    TaxWeight?: string | null;
    LinearMeters?: string | null;
    ShippingUnits?: string | null;
    EarlyPickup?: string | null;
    LatePickup?: string | null;
    EarlyDelivery?: string | null;
    LateDelivery?: string | null;
    CustomerCollectionId?: string | null;
    CustomerLinehaulId?: string | null;
    Notes?: string | null;
    OriginArea?: string | null;
    DestinationArea?: string | null;
    BusinessFlowType?: number | null;
}

// const objFilter = {
//     operator: 'OR',
//     conditions: [
//         {
//             type: "inRange",
//             value: null,             
//         },
//         {
//             type: "contains",
//             value: 'bla'
//         }
//     ]
// }

export const voidPaginationOrdersRequest = {
    pageSize: null,
    pageIndex: null,
    PlanningStatus: null,
    OrderBy: null,
    OrderDirection: null,
    Number: null,
    Origin: null,
    Destination: null,
    Volume: null,
    Weight: null,
    TaxWeight: null,
    LinearMeters: null,
    ShippingUnits: null,
    EarlyPickup: null,
    LatePickup: null,
    EarlyDelivery: null,
    LateDelivery: null,
    CustomerCollectionId: null,
    CustomerLinehaulId: null,
}

export const ordersService = {
    getOrders: async (groupsIds: number[], orderIds: number[] | null = null, tripId: number | null = null, dropped: boolean = false, paginationGetOrdersRequest: PaginationGetOrdersRequest | null, startDate?: Date, endDate?: Date) => {
        const request = new basicRequest();

        let objRequest = {
            groupsIds: groupsIds.length > 0 ? groupsIds : undefined,
            orderIds: orderIds,
            tripId: tripId,
            dropped: !!dropped ? true : undefined,
            startDate: startDate !== undefined ? dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss') : undefined,
            endDate: endDate !== undefined ? dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss') : undefined
        }

        if (!!paginationGetOrdersRequest) {
            objRequest = { ...objRequest, ...paginationGetOrdersRequest }
        } else {
            objRequest = { ...objRequest, ...voidPaginationOrdersRequest }
        }

        return await request.get(planningToolApiUrls.getOrders, objRequest);
    },
    getLegsFromOrder: async (orderId: number) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getOrders + '/' + orderId + '/legs');
    },
    getGroupedOrders: async (orderId: number) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getOrders + '/' + orderId + '/groupOrders');
    },
    separateOrders: async (aggregationIds: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.post(planningToolApiUrls.separateOrders, aggregationIds, undefined, loadingMessage, successMessage, errorMessage);
    },
    getCustomerRouting: async (orderId: number) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getOrders + '/' + orderId + '/customerRouting', undefined, null, null, "Error getting cutomer routing");
    },
    getShipmentUnits: async (orderId: number, isChild = false) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getOrders + '/' + orderId + '/shipmentUnits', { isChild: isChild }, null, null, "Error getting shipment units");
    },
    disableOrders: async (requestData: DisableOrdersRequest[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.getOrders}/disable`, requestData, undefined, loadingMessage, successMessage, errorMessage);
    },
    updateOrder: async (orderId: number, requestData: UpdateOrderRequest, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.getOrders}/${orderId}`, requestData, undefined, loadingMessage, successMessage, errorMessage);
    },
    getOrderErrors: async (orderId: number) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getOrders + '/errors/' + orderId, {}, undefined, undefined, 'Error getting order errors.');
    },
    getOrderInfo: async (orderNumber: string) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.getOrders + '/orderInfo', {
            orderNumber: orderNumber
        }, undefined, undefined, 'Error getting order info.');
    },
    saveErrorHandling: async (errorId: number, handledText: string, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.getOrders}/handleError/${errorId}`, {
            handledText: handledText
        }, undefined, loadingMessage, successMessage, errorMessage);
    }
}