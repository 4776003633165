import React, { useEffect, useRef, useState } from "react";
import DensityDropdown from "./densityDropdown/DensityDropdown";
import ColumnVisibilityDialog from "./ColumnVisibilityDialog/ColumnVisibilityDialog";
import { Tooltip } from "primereact/tooltip";

type Props = {
  density: string;
  handleDensityChange: (density: string) => void;
  DENSITY_LIST: string[];
  visibilityOptions: React.ReactNode;
};

const TableOptions: React.FC<Props> = ({
  density,
  handleDensityChange,
  DENSITY_LIST,
  visibilityOptions,
}) => {
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const selectRef = useRef<null | HTMLDivElement>(null);

  const openClose = () => {
    setOpen(!open);
  };

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  useEffect(() => {
    const listener = (e: any) => {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, []);

  return (
    <div ref={selectRef} className="select-none grid-settings pt-3 pb-3">
      <div>
        <div
          className="cursor-pointer flex items-center text-darkGrey uppercase font-semibold text-[10px]"
          onClick={openClose}
        >
          <div className="text-blue">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2.5em"
              height="2.5em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 15.5A3.5 3.5 0 0 1 8.5 12A3.5 3.5 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5a3.5 3.5 0 0 1-3.5 3.5m7.43-2.53c.04-.32.07-.64.07-.97s-.03-.66-.07-1l2.11-1.63c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.31-.61-.22l-2.49 1c-.52-.39-1.06-.73-1.69-.98l-.37-2.65A.506.506 0 0 0 14 2h-4c-.25 0-.46.18-.5.42l-.37 2.65c-.63.25-1.17.59-1.69.98l-2.49-1c-.22-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64L4.57 11c-.04.34-.07.67-.07 1s.03.65.07.97l-2.11 1.66c-.19.15-.25.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1.01c.52.4 1.06.74 1.69.99l.37 2.65c.04.24.25.42.5.42h4c.25 0 .46-.18.5-.42l.37-2.65c.63-.26 1.17-.59 1.69-.99l2.49 1.01c.22.08.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64z"
              />
            </svg>
          </div>
        </div>

        {!!open && (
          <ul
            style={{
              position: "absolute",
              zIndex: 2000,
              display: "flex",
              flexDirection: "column",
              padding: "8px",
              backgroundColor: "white",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "8px",
              marginTop: "8px",
            }}
          >
            <li
              style={{
                marginBottom: "8px",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              <DensityDropdown
                density={density}
                handleDensityChange={handleDensityChange}
                DENSITY_LIST={DENSITY_LIST}
              />
            </li>

            <li
              style={{
                marginBottom: "8px",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              <ColumnVisibilityDialog
                isDialogOpen={isDialogOpen}
                toggleDialog={toggleDialog}
                visibilityOptions={visibilityOptions}
              />
            </li>
          </ul>
        )}
      </div>
      <Tooltip
        position="top"
        target=".grid-settings"
        content="Grid Settings"
      ></Tooltip>
    </div>
  );
};

export default TableOptions;
