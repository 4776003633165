import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import { AreasConfiguration } from "../../types/orders";
import basicRequest from "../basicRequest";

export const areasService = {
    getAllAreasConfiguration: async () => {
        const request = new basicRequest();
        return await request.get(`${planningToolApiUrls.areas}`, {});
    },
    deleteAreasConfiguration: async (ids: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.delete(`${planningToolApiUrls.areas}`, {
            id: ids
        }, null, loadingMessage, successMessage, errorMessage);
    },
    updateAreasConfiguration: async (areasConfiguration: AreasConfiguration[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.areas}`, {
            areasConfiguration: areasConfiguration
        }, null, loadingMessage, successMessage, errorMessage);
    }
}