import axios from "axios";
import config from "../../settings";

const convertDataToFormUrlEncoded = data => {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
};

export const authAPI = {
  getToken: (
    authorizationCode,
    loginCallbackUrl,
    grantType,
    clientId,
    codeVerifier,
    thenCallback
  ) => {
    axios
      .post(
        `${config.baseAuthUrl}${config.getToken}`,
        convertDataToFormUrlEncoded({
          code: authorizationCode,
          redirect_uri: loginCallbackUrl,
          grant_type: grantType,
          client_id: clientId,
          code_verifier: codeVerifier,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(res => {
        thenCallback(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
};
