import React from "react";
import Modal from "../../ui/modal/Modal";

type Props = {
    callbackCloseModal: () => any;
    notes: string | null;
}

const ModalOrderNotes: React.FC<Props> = ({
    callbackCloseModal,
    notes
}) => {

    return (
        <Modal
            callbackCloseModal={callbackCloseModal}
            title="Order Notes"
            customStyleChildrenContainer={{
                padding: 24
            }}
        >
            <>
                {notes}
            </>
        </Modal>
    )
}

export default ModalOrderNotes;