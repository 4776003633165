import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import { Leg1Rules, Leg2Rules, LegPlanningTypesEnum } from "../../types/leg";
import basicRequest from "../basicRequest";

export type UpdateLegPlanningRequestData = {
    id: number;
    planning: LegPlanningTypesEnum;
    partner?: number | null,
    collection?: boolean | null
};

export type AssignPartnerToLegRequestData = {
    legs: AssignPartnerLegToUpdate[];
    partnerId: number;
};

type AssignPartnerLegToUpdate = {
    legId: number;
    isCollection: boolean;
};

export const legsService = {
    updateLegPlanning: async (requestData: UpdateLegPlanningRequestData[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(planningToolApiUrls.legs, requestData, null, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    },
    getPlanningRules: async () => {
        const request = new basicRequest();
        return await request.get(`${planningToolApiUrls.legs}/planningRules`);
    },
    setDispatchable: async (requestData: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.legs}/dispatchable`, requestData, null, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    },
    assignPartner: async (requestData: AssignPartnerToLegRequestData, loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.legs}/partner`, requestData, null, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    },
    undoAction: async (requestData: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null, partialSuccessMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.legs}/undo`, requestData, null, loadingMessage, successMessage, errorMessage, partialSuccessMessage);
    },
    replanLeg: async (requestData: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.legs}/replan`, requestData, null, loadingMessage, successMessage, errorMessage);
    },
    getLeg1Combinations: async () => {
        const request = new basicRequest();
        return await request.get(`${planningToolApiUrls.legs}/combinations`);
    },
    deleteLeg1Combinations: async (ids: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.delete(`${planningToolApiUrls.legs}/combinations`, {
            ids: ids
        }, undefined, loadingMessage, successMessage, errorMessage);
    },
    updateLeg1Combinations: async (combinations: Leg1Rules[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.legs}/combinations`, {
            legCombinations: combinations
        }, undefined, loadingMessage, successMessage, errorMessage);
    },
    getLeg2Combinations: async () => {
        const request = new basicRequest();
        return await request.get(`${planningToolApiUrls.legs}/combinations2`);
    },
    deleteLeg2Combinations: async (ids: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.delete(`${planningToolApiUrls.legs}/combinations2`, {
            id: ids
        }, undefined, loadingMessage, successMessage, errorMessage);
    },
    updateLeg2Combinations: async (combinations: Leg2Rules[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.legs}/combinations2`, {
            leg2Combinations: combinations
        }, undefined, loadingMessage, successMessage, errorMessage);
    },
    getLeg3Combinations: async () => {
        const request = new basicRequest();
        return await request.get(`${planningToolApiUrls.legs}/combinations3`);
    },
    deleteLeg3Combinations: async (ids: number[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.delete(`${planningToolApiUrls.legs}/combinations3`, {
            id: ids
        }, undefined, loadingMessage, successMessage, errorMessage);
    },
    updateLeg3Combinations: async (combinations: Leg2Rules[], loadingMessage: string | null = null, successMessage: string | null = null, errorMessage: string | null = null) => {
        const request = new basicRequest();
        return await request.put(`${planningToolApiUrls.legs}/combinations3`, {
            leg3Combinations: combinations
        }, undefined, loadingMessage, successMessage, errorMessage);
    }
}