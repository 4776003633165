/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */

// NOTE: This import causes the aggridvendor chunck to be loaded at any level in the application
// NOTE: LicenseManager.setLicenseKey has been moved to /src/services/AgGridService.js
// import {LicenseManager} from "@ag-grid-enterprise/core";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import loadable from "@loadable/component";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./view/components/app/App";
import "./index.scss"; // Standard version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";

const MetronicLayoutProvider = loadable(() => import("./_metronic/layout"), {
  resolveComponent: components => components.MetronicLayoutProvider,
});

const MetronicSplashScreenProvider = loadable(
  () => import("./_metronic/layout"),
  {
    resolveComponent: components => components.MetronicSplashScreenProvider,
  }
);

const MetronicSubheaderProvider = loadable(() => import("./_metronic/layout"), {
  resolveComponent: components => components.MetronicSubheaderProvider,
});

const MetronicI18nProvider = loadable(() => import("./_metronic/i18n"), {
  resolveComponent: components => components.MetronicI18nProvider,
});

// LicenseManager.setLicenseKey("CompanyName=ARCESE TRASPORTI S.P.A.,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011823,ExpiryDate=20_November_2021_[v2]_MTYzNzM2NjQwMDAwMA==571e739532a88a573e7303c78f5543f6");

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// const mock = _redux.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

ReactDOM.render(
  <Suspense
    fallback={
      <div>
        <div className="loading-container"></div>
        <div className="loading-widget">
          <div className="loading-img"></div>
        </div>
      </div>
    }
  >
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <App store={store} persistor={persistor} basename={PUBLIC_URL} />
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  </Suspense>,
  document.getElementById("root")
);
