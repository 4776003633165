import { combineReducers } from "redux";

import {
  ordersCustomerFiltersReducer,
  ordersExecutionFiltersReducer,
  tripsFiltersReducer,
  salesOrdersFiltersReducer,
} from "./features/filters/filtersSlice";
import dataReducer from "./features/data/dataSlice";
import searchReducer from "./features/search/searchSlice";
import {
  ordersExecutionNavigationReducer,
  ordersNavigationReducer,
  tripsNavigationReducer,
  salesOrdersNavigationReducer,
} from "./features/navigation/navigationSlice";
import advSearchReducer from "./features/adv-search/advSearchSlice";
import bookmarksReducer from "./features/bookmarks/bookmarksSlice";
import reportReducer from "./features/reports/reportsSlice";
import gridConfigurationReducer from "./features/grid-configuration/gridConfigurationSlice";
import orderDetailReducer from "./features/order-detail/orderDetailSlice";
import notificationCenterReducer from "./features/notification-center/notificationCenterSlice";
import {
  TOCardsReducer,
  TripsCardsReducer,
} from "./features/card-configuration/cardConfigurationSlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import mapReducer from "./features/map/mapSlice";
import smartSearchReducer from "./features/smart-search/smartSearchSlice";
import planningToolReducer from "./features/planning-tool/planningToolSlice";

export const rootReducer = combineReducers({
  ordersCustomerFilters: ordersCustomerFiltersReducer,
  ordersExecutionFilters: ordersExecutionFiltersReducer,
  tripsFilters: tripsFiltersReducer,
  salesOrdersFilters: salesOrdersFiltersReducer,
  data: dataReducer,
  search: searchReducer,
  ordersNavigation: ordersNavigationReducer,
  ordersExecutionNavigation: ordersExecutionNavigationReducer,
  tripsNavigation: tripsNavigationReducer,
  salesOrdersNavigation: salesOrdersNavigationReducer,
  advSearch: advSearchReducer,
  bookmark: bookmarksReducer,
  report: reportReducer,
  gridConfiguration: gridConfigurationReducer,
  notificationCenter: notificationCenterReducer,
  TOCards: TOCardsReducer,
  tripsCards: TripsCardsReducer,
  dashboard: dashboardReducer,
  map: mapReducer,
  smartSearch: smartSearchReducer,
  orderDetail: orderDetailReducer,
  planningTool: planningToolReducer,
});
