import React, { useCallback, useMemo, useRef, useState } from "react";
import { groupsService } from "../../api/groupsService/groupsService";
import { AreasConfiguration } from "../../types/orders";
import Button from "../../components/ui/button/Button";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import { RowNode, ValueSetterParams } from "@ag-grid-community/core";
import Modal from "../../components/ui/modal/Modal";
import { toast } from "react-toastify";
import CommonTable, { TableRef } from "../../components/commonTable/CommonTable";
import AuthorizedComponent from "../../components/permissions/AuthorizedComponent";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import usePermissions from "../../utils/hooks/usePermissions";
import { areasService } from "../../api/areasService/areasService";
import ModalShowAreasConfigurationConflicts from "./ModalShowAreasConfigurationConflicts";

const PlanningToolAreasConfiguration: React.FC = () => {

    const { hasPermission } = usePermissions();

    // #region State
    const [inAdding, setInAdding] = useState<boolean>(false);

    const [openSpecialDuplicateModal, setOpenSpecialDuplicateModel] = useState<boolean>(false);
    const [rowToSpecialCopy, setRowToSpecialCopy] = useState<AreasConfiguration | null>(null);
    const [conflictRows, setConflictRows] = useState<AreasConfiguration[]>([]);
    const [openModalShowConflicts, setOpenModalShowConflicts] = useState<boolean>(false);

    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs: any = useMemo(() => [
        {
            field: 'country',
            headerName: "COUNTRY"
        },
        {
            field: 'postalCodeFrom',
            headerName: "POSTAL CODE FROM"
        },
        {
            field: 'postalCodeTo',
            headerName: "POSTAL CODE TO"
        },
        {
            field: 'area',
            headerName: "AREA"
        },
        {
            field: 'actions',
            headerName: "ACTIONS",
            editable: false,
            filter: false,
            minWidth: 260,
            hide: !hasPermission(PermissionAreas.AreasConfiguration, PermissionOperations.Modify),
            cellRenderer: (props: { data: AreasConfiguration, node: RowNode }) => {
                return (
                    <>
                        {
                            <div className="flex">
                                {
                                    props.data.id !== undefined &&
                                    <>
                                        <div
                                            onClick={() => {
                                                if (!inAdding) {
                                                    onClickDuplicateCondition(props.data)
                                                }
                                            }}
                                            className={"flex items-center" + (inAdding ? ' opacity-30 cursor-not-allowed' : ' cursor-pointer')}
                                        >
                                            <span className="mr-[8px]">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.33337 5.33398H2.66671C1.93137 5.33398 1.33337 5.93198 1.33337 6.66732V13.334C1.33337 14.0693 1.93137 14.6673 2.66671 14.6673H9.33337C10.0687 14.6673 10.6667 14.0693 10.6667 13.334V6.66732C10.6667 5.93198 10.0687 5.33398 9.33337 5.33398Z" fill="#3987E2" />
                                                    <path d="M13.3334 1.33398H6.66671C6.31309 1.33398 5.97395 1.47446 5.7239 1.72451C5.47385 1.97456 5.33337 2.3137 5.33337 2.66732V4.00065H10.6667C11.0203 4.00065 11.3595 4.14113 11.6095 4.39118C11.8596 4.64122 12 4.98036 12 5.33398V10.6673H13.3334C13.687 10.6673 14.0261 10.5268 14.2762 10.2768C14.5262 10.0267 14.6667 9.68761 14.6667 9.33398V2.66732C14.6667 2.3137 14.5262 1.97456 14.2762 1.72451C14.0261 1.47446 13.687 1.33398 13.3334 1.33398Z" fill="#3987E2" />
                                                </svg>
                                            </span>
                                            <span className="hover:underline">Copy</span>
                                        </div>

                                        <div
                                            onClick={() => {
                                                if (!inAdding) {
                                                    onClickSpecialDuplicateCondition(props.data)
                                                }
                                            }}
                                            className={"ml-[32px] flex items-center" + (inAdding ? ' opacity-30 cursor-not-allowed' : ' cursor-pointer')}
                                        >
                                            <span className="mr-[8px]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14"><g fill="#3987E2" fillRule="evenodd" clipRule="evenodd"><path d="M1.424.27A.75.75 0 0 1 2 0h5a.75.75 0 0 1 .738.884l-.024.131A1.5 1.5 0 0 1 9 2.5v.75H7.25a3 3 0 0 0-3 3V11H1.5A1.5 1.5 0 0 1 0 9.5v-7a1.5 1.5 0 0 1 1.286-1.485l-.024-.13a.75.75 0 0 1 .162-.616ZM2.9 1.5l.115.634a.75.75 0 0 0 .738.616h1.496a.75.75 0 0 0 .738-.616l.115-.634z" /><path d="M7.25 4.5A1.75 1.75 0 0 0 5.5 6.25v6c0 .966.784 1.75 1.75 1.75h5A1.75 1.75 0 0 0 14 12.25v-6a1.75 1.75 0 0 0-1.75-1.75zM7.875 8c0-.345.28-.625.625-.625H11a.625.625 0 1 1 0 1.25H8.5A.625.625 0 0 1 7.875 8M8.5 9.875a.625.625 0 1 0 0 1.25H11a.625.625 0 1 0 0-1.25z" /></g></svg>
                                            </span>
                                            <span className="hover:underline">Copy Smart</span>
                                        </div>
                                    </>
                                }

                                {
                                    props.node.rowPinned === "top" &&
                                    <div
                                        onClick={onClickCancelNewRow}
                                        className={"flex text items-center cursor-pointer"}
                                    >
                                        <span className="mr-[8px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#3987E2" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z" /></svg>
                                        </span>
                                        <span className="hover:underline not-italic ">Remove</span>
                                    </div>
                                }
                            </div>
                        }
                    </>
                )
            },
        }
    ], [inAdding]);
    // #endregion

    // #region UI Handlers
    const onClickSaveButton = async () => {
        await confirmSaveConditions();
    };

    const onClickDeleteButton = async () => {
        await confirmDeleteConditions();
    };

    const onClickDuplicateCondition = (dataToCopy: AreasConfiguration) => {
        duplicateCondition(dataToCopy);
    }

    const onClickSpecialDuplicateCondition = (dataToCopy: AreasConfiguration) => {
        setRowToSpecialCopy(dataToCopy);
        setOpenSpecialDuplicateModel(true);
    }

    const onClickDuplicateDifferentPostCodes = () => {
        if (rowToSpecialCopy !== null) {
            duplicateDifferentPostCodes(rowToSpecialCopy);
        }

        setOpenSpecialDuplicateModel(false);
    };

    const onClickDuplicateSameArea = () => {
        if (rowToSpecialCopy !== null) {
            duplicateSameArea(rowToSpecialCopy);
        }

        setOpenSpecialDuplicateModel(false);
    };

    const onClickCancelNewRow = () => {
        cancelNewRow();
    };

    const onClickAddNewCondition = () => {
        addNewCondition();
    };
    // #endregion

    // #region Util Functions
    const getAreasConfiguration = async () => {
        commonTableRef.current?.showLoadingOverlay();
        const response = await areasService.getAllAreasConfiguration();
        commonTableRef.current?.hideOverlay();

        if (response.success) {
            commonTableRef.current?.setRowData(response.data);
        } else {
            toast.error('Error retrieving areas configuration');
        }
    };

    const customValueSetter = (params: ValueSetterParams) => {
        let newValue = params.newValue;
        let field = params.colDef.field as string;

        // Validation
        if (newValue !== null && newValue !== undefined) {
            // ! REMOVED VALIDATION BECAUSE POSTAL CODES CAN BE ALPHANUMERIC
            // let numericFields = ["originPostCodeFrom", "originPostCodeTo", "destinationPostCodeFrom", "destinationPostCodeTo"];
            // if (numericFields.includes(field)) {
            //     if (isNaN(newValue)) {
            //         toast.warning("The value cannot include letters");
            //         return false;
            //     }
            // }

            let postCodeFields = ["originPostCodeFrom", "originPostCodeTo", "destinationPostCodeFrom", "destinationPostCodeTo"];
            if (postCodeFields.includes(field)) {
                if (newValue.length >= 12) {
                    toast.warning("The length of the Post Code must be less than 12");
                    return false;
                }
            }

            let countryFields = ["originCountry", "destinationCountry"];
            if (countryFields.includes(field)) {
                if (newValue.length !== 2) {
                    toast.warning("The length of the country must be 2");
                    return false;
                }
            }
        }

        // Transformation
        if (field === "originCountry" || field === "destinationCountry") {
            newValue = newValue?.toUpperCase();
        }

        params.data[field] = newValue;
        return true;
    };

    const confirmDeleteConditions = async () => {
        let selectedRows: AreasConfiguration[] = commonTableRef.current?.getSelectedRows() as AreasConfiguration[];
        let idsToRemove: number[] = selectedRows.filter(x => x.id !== undefined).map(x => {
            return x.id as number;
        });

        if (idsToRemove.length > 0) {
            const wantToDelete = await customConfirmAlert({
                message: "Are you sure to delete the selected conditions?",
                title: "Delete condition"
            });

            if (wantToDelete) {
                await deleteConditions(idsToRemove);
                commonTableRef.current?.redrawTableRows();
            }
        } else {
            toast.info("No conditions selected");
        }
    };

    const deleteConditions = async (idsToRemove: number[]) => {
        let selectedRows: AreasConfiguration[] = commonTableRef.current?.getSelectedRows() as AreasConfiguration[];

        const response = await areasService.deleteAreasConfiguration(idsToRemove, "Removing condition...", "Conditions deleted successfully", "Error while deleting conditions");
        if (response.success) {
            commonTableRef.current?.deleteTableRows(selectedRows);
        }
    };

    const confirmSaveConditions = async () => {
        commonTableRef.current?.setStopEditing();
        const wantToSave = await customConfirmAlert({
            message: "Are you sure to save the conditions?",
            title: "Save conditions"
        });

        if (wantToSave) {
            await saveConditions();
        }
    };

    const saveConditions = async () => {
        let tableRows = commonTableRef.current?.getTableRows() as AreasConfiguration[];
        let newRowToInsert = commonTableRef.current?.getNewRowToInsert();
        tableRows = newRowToInsert !== undefined ? [newRowToInsert, ...tableRows] : tableRows;

        if (tableRows.length === 0) {
            toast.info("No conditions to save");
            return;
        }

        // Check some value null
        let foundNullValue = false;
        Object.keys(new AreasConfiguration()).forEach(field => {
            if (tableRows.some((row: any) => {
                let value = row[field];
                if (value === "" || value === null) {
                    return true;
                }

                if (field === "departmentId" || field === "groupId") {
                    if (value === undefined || value === 0) {
                        return true;
                    }
                }
                return false
            })) {
                foundNullValue = true;
            }
        });

        if (foundNullValue) {
            toast.warning("There are some null values");
            return;
        }

        const response = await areasService.updateAreasConfiguration(tableRows, "Saving configuration...", "Configuration saved successfully", "Error while saving configuration");
        if (response.success) {
            commonTableRef.current?.cancelNewRowInsertion();
            commonTableRef.current?.setUnsavedCells([]);
            commonTableRef.current?.setRowsInError([]);
            setConflictRows([]);
            setInAdding(false);
            getAreasConfiguration();
        } else if (response.statusCode === 400) {
            if (!!response.data.detailObject) {
                commonTableRef.current?.setRowsInError(response.data.detailObject);
            }
        } else if (response.statusCode === 409) {
            setConflictRows(!!response.data.detailObject ? response.data.detailObject : []);
        }

        setTimeout(function () { commonTableRef.current?.redrawTableRows() }, 0)
    };

    const duplicateCondition = (dataToCopy: AreasConfiguration) => {
        let newRow: AreasConfiguration = { ...dataToCopy };
        newRow.id = undefined;
        newRow.rowId = undefined;
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const duplicateSameArea = (dataToCopy: AreasConfiguration) => {
        let newRow: AreasConfiguration = new AreasConfiguration();
        newRow.area = dataToCopy.area;
        newRow.rowId = undefined;
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const duplicateDifferentPostCodes = (dataToCopy: AreasConfiguration) => {
        let newRow: AreasConfiguration = { ...dataToCopy };
        newRow.id = undefined;
        newRow.postalCodeFrom = "";
        newRow.postalCodeTo = "";
        newRow.rowId = undefined;
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const addNewCondition = () => {
        let newRow: any = {};
        newRow.rowId = undefined;
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const cancelNewRow = () => {
        commonTableRef.current?.cancelNewRowInsertion();
        setInAdding(false);
    };
    // #endregion

    // #region CallBack
    const onGridReady = useCallback(() => {
        getAreasConfiguration();
    }, []);
    // #endregion

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Areas Configuration
                </div>
            </div>

            <AuthorizedComponent area={PermissionAreas.AreasConfiguration} operation={PermissionOperations.Modify}>
                <div className="mb-6 flex items-center">
                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px]"
                        callback={onClickAddNewCondition}
                        disabled={inAdding}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3333 9.16602V9.66602H10.8333H15.3333V10.3327H10.8333H10.3333V10.8327V15.3327H9.66667V10.8327V10.3327H9.16667H4.66667V9.66602H9.16667H9.66667V9.16602V4.66602H10.3333V9.16602Z" fill="#3987E2" stroke="white" />
                        </svg>
                    </Button>

                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px] ml-[12px]"
                        callback={onClickDeleteButton}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z" /></svg>
                    </Button>

                    <div className="ml-[12px]">
                        <Button
                            callback={onClickSaveButton}
                            className="bg-transparent text-black border-[1.4px] border-black"
                        >
                            Save configuration
                        </Button>
                    </div>

                    {
                        conflictRows.length > 0 &&
                        <div className="ml-7 flex cursor-pointer" onClick={() => setOpenModalShowConflicts(true)}>
                            <div className="border-red pt-[10px] pl-[15px] pr-[15px] pb-[10px] border-[3px] rounded-[10px] flex">
                                <svg className="text-red" xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 10 10"><path fill="currentColor" d="M10 5A5 5 0 1 0 0 5a5 5 0 0 0 10 0M9 5a4 4 0 0 1-6.453 3.16L8.16 2.547C8.686 3.224 9 4.076 9 5M7.453 1.84L1.84 7.453A4 4 0 0 1 7.453 1.84" /></svg>
                                <span className="ml-3">Some area conditions are overlapping</span>
                            </div>
                        </div>
                    }
                </div>
            </AuthorizedComponent>

            <div style={containerStyle}>
                <CommonTable
                    ref={commonTableRef}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    valueSetter={customValueSetter}
                    options={{
                        pageSize: 10,
                        showSelectionCheckbox: hasPermission(PermissionAreas.AreasConfiguration, PermissionOperations.Modify),
                        showRowsNumber: true,
                        editable: hasPermission(PermissionAreas.AreasConfiguration, PermissionOperations.Modify)
                    }}
                />
            </div>

            {
                openSpecialDuplicateModal &&
                <Modal
                    callbackCloseModal={() => setOpenSpecialDuplicateModel(false)}
                    title="Select copy mode"
                >
                    <div className="flex items-center justify-center">
                        <Button
                            className="bg-blue text-white p-2 ml-2"
                            callback={onClickDuplicateSameArea}
                        >
                            Area
                        </Button>

                        <Button
                            className="bg-blue text-white p-2 ml-4"
                            callback={onClickDuplicateDifferentPostCodes}
                        >
                            All but PostCode
                        </Button>
                    </div>
                </Modal>
            }

            {openModalShowConflicts === true &&
                <ModalShowAreasConfigurationConflicts
                    callbackCloseModal={() => setOpenModalShowConflicts(false)}
                    conflicts={conflictRows}
                />
            }
        </div>
    )
}

export default PlanningToolAreasConfiguration