import React, { useEffect, useMemo, useRef } from 'react';
import CommonTable, { TableRef } from '../../components/commonTable/CommonTable';
import { TripCombination } from "../../types/trips";
import { AddressTypesIdsEnum } from '../../types/address';
import { LegTypesEnum } from '../../types/leg';

type Props = {
    conflicts: TripCombination[];
    callbackCloseModal: () => any;
};

const ModalShowTripCombinationsConflicts: React.FC<Props> = ({
    conflicts,
    callbackCloseModal
}) => {

    // #region State and Refs
    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo
    const legTypesOptions = useMemo(() => {
        return [
            { value: LegTypesEnum.Collection.valueOf(), label: "Collection" },
            { value: LegTypesEnum.Delivery.valueOf(), label: "Delivery" },
            { value: LegTypesEnum.Direct.valueOf(), label: "Direct" },
            { value: LegTypesEnum.Shuttle.valueOf(), label: "Shuttle" }
        ];
    }, []);

    const addressTypesOptions = useMemo(() => {
        return [
            { value: AddressTypesIdsEnum.ToOrigin.valueOf(), label: "To Origin" },
            { value: AddressTypesIdsEnum.ToDestination.valueOf(), label: "To Destination" },
            { value: AddressTypesIdsEnum.Depot.valueOf(), label: "Depot" },
            { value: AddressTypesIdsEnum.Partner.valueOf(), label: "Partner" }
        ];
    }, []);

    const legCountryITOptions = useMemo(() => {
        return [
            { value: 1, label: "IT" },
            { value: 0, label: "NON IT" }
        ];
    }, []);

    const immediateDispatchOptions = useMemo(() => {
        return [
            { value: 1, label: "YES" },
            { value: 0, label: "NO" }
        ];
    }, []);
    
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs: any = useMemo(() => [
        {
            field: 'combinationCode',
            headerName: "COMBINATION",
            editable: false,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: any) => {
                var numA = Number(valueA.replace("C", ""));
                var numB = Number(valueB.replace("C", ""));
                if (numA == numB) return 0;
                return (numA > numB) ? 1 : -1;
            }
        },
        {
            field: 'legTypeId',
            headerName: "LEG TYPE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            cellRenderer: (props: { data: TripCombination}) => {
                return (
                    <>
                        <>{legTypesOptions.find(x => x.value === props.data.legTypeId)?.label}</>
                    </>
                )
            }
        },
        {
            field: 'legOriginId',
            headerName: "LEG ORIGIN",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: addressTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => addressTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination }) => {
                return (
                    <>
                        {addressTypesOptions.find(x => x.value === props.data.legOriginId)?.label}
                    </>
                )
            }
        },
        {
            field: 'legDestinationId',
            headerName: "LEG DESTINATION",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: addressTypesOptions.map(x => x.value),
                valueFormatter: (params: any) => addressTypesOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination }) => {
                return (
                    <>
                        {addressTypesOptions.find(x => x.value === props.data.legDestinationId)?.label}
                    </>
                )
            }
        },
        {
            field: 'legOriginIT',
            headerName: "LEG ORIGIN COUNTRY",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: legCountryITOptions.map(x => x.value === 1 ? true : false),
                valueFormatter: (params: any) => legCountryITOptions.find(x => (x.value === 1 ? true : false) === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination }) => {
                return (
                    <>
                        {legCountryITOptions.find(x => (x.value === 1 ? true : false) === props.data.legOriginIT)?.label}
                    </>
                )
            }
        },
        {
            field: 'legDestinationIT',
            headerName: "LEG DESTINATION COUNTRY",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: legCountryITOptions.map(x => x.value === 1 ? true : false),
                valueFormatter: (params: any) => legCountryITOptions.find(x => (x.value === 1 ? true : false) === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination }) => {
                return (
                    <>
                        {legCountryITOptions.find(x => (x.value === 1 ? true : false) === props.data.legDestinationIT)?.label}
                    </>
                )
            }
        },
        {
            field: 'immediateDispatch',
            headerName: "IMMEDIATE DISPATCH",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: immediateDispatchOptions.map(x => x.value === 1 ? true : false),
                valueFormatter: (params: any) => immediateDispatchOptions.find(x => (x.value === 1 ? true : false) === params.value)?.label,
                suppressSelectAll: true
            },
            cellRenderer: (props: { data: TripCombination }) => {
                return (
                    <>
                        {immediateDispatchOptions.find(x => (x.value === 1 ? true : false) === props.data.immediateDispatch)?.label}
                    </>
                )
            }
        }
    ], []);
    // #endregion

    // #region Util Functions
    
    // #endregion

    // #region Use Effects
    useEffect(() => {
        let insertionRow: any = conflicts.find(x => x.combinationCode === null);
        if (!!insertionRow) {
            insertionRow.isHighligthed = true;
        }
        
        commonTableRef.current?.setTableDataState(conflicts);
    }, [conflicts]);
    // #endregion

    return (
        <div style={{ zIndex: 9999 }} className="fixed top-0 left-0 h-screen w-screen bg-[#00000080] flex items-center justify-center">
            <div className="bg-white max-h-[75%] w-[95%] lg:w-[80%] max-w-[1920px] flex flex-col">
                <div className="shrink-0 flex justify-between border-b p-[30px]">
                    <div className="text-lg font-bold">
                        Conflict rows
                    </div>
                    <div onClick={callbackCloseModal} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>

                {/* MAIN CONTENT */}
                <div className="grow py-8 px-[30px] select-none overflow-auto relative">
                    <div className="mt-5" style={containerStyle}>
                        <CommonTable
                            ref={commonTableRef}
                            columnDefs={columnDefs}
                            options={{
                                showRowsNumber: false,
                                filter: false,
                                floatingFilter: false,
                                editable: false,
                                pageSize: 10
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ModalShowTripCombinationsConflicts;