import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select from "../../components/ui/select/Select";
import Button from "../../components/ui/button/Button";
import { GridApi, RowNode, ValueSetterParams } from "@ag-grid-community/core";
import CommonTable, { TableRef } from "../../components/commonTable/CommonTable";
import AuthorizedComponent from "../../components/permissions/AuthorizedComponent";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import usePermissions from "../../utils/hooks/usePermissions";
import { toast } from "react-toastify";
import { tripsService } from "../../api/tripsService/tripsService";
import { TripCombination, TripRule } from "../../types/trips";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import { distinct } from "../../utils/utilFunctions";
import ModalSelectCombination from "./ModalSelectCombination";
import { templatesConfigurationService } from "../../api/templatesConfigurationService/templatesConfigurationService";
import ModalShowTripRulesConflicts from "./ModalShowTripRulesConflicts";

const PlanningToolTripRules: React.FC = () => {

    const { hasPermission } = usePermissions();

    // #region State
    const [inAdding, setInAdding] = useState<boolean>(false);
    const [conflictRows, setConflictRows] = useState<TripRule[]>([]);
    const [allTemplate, setAllTemplate] = useState<string[]>([]);
    const [allCombinations, setAllCombinations] = useState<string[]>([]);
    const [ruleCodeToAddCombination, setRuleCodeToAddCombination] = useState<string>("");
    const [openSelectCombinationModal, setOpenSelectCombinationModal] = useState<boolean>(false);
    const [openModalShowConflicts, setOpenModalShowConflicts] = useState<boolean>(false);

    const commonTableRef = useRef<TableRef>(null);
    // #endregion

    // #region Memo
    const templateOptions = useMemo(() => {
        return allTemplate.map(x => {
            return {
                value: x,
                label: x
            }
        });
    }, [allTemplate]);

    const combinationsOptions = useMemo(() => {
        return allCombinations.map(x => {
            return {
                value: x,
                label: x
            }
        });
    }, [allCombinations]);

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

    const columnDefs: any = useMemo(() => [
        {
            field: 'ruleCode',
            headerName: "RULE CODE",
            editable: false,
            comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: any) => {
                var numA = Number(valueA.replace("R", ""));
                var numB = Number(valueB.replace("R", ""));
                if (numA == numB) return 0;
                return (numA > numB) ? 1 : -1;
            }
        },
        {
            field: 'templateCode',
            headerName: "TEMPLATE CODE",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: templateOptions.map(x => x.value),
                valueFormatter: (params: any) => templateOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: false
            },
            cellRenderer: (props: { data: TripRule, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.ConfigurationHub_TripRules, PermissionOperations.Modify) &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                callbackOnChange={async (e) => { await handleChangeStringValue(e.target.value, props.node, props.api, "templateCode") }}
                                options={templateOptions}
                                value={props.data.templateCode}
                                defaultOptionEnabled={true}
                                placeholder={"Select Template"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {!hasPermission(PermissionAreas.ConfigurationHub_TripRules, PermissionOperations.Modify) &&
                            <>{props.data.templateCode}</>
                        }
                    </>
                )
            }
        },
        {
            field: 'combinations',
            headerName: "COMBINATIONS",
            cellClass: "agCellAlignCenter",
            filter: "agSetColumnFilter",
            minWidth: 240,
            editable: false,
            filterParams: {
                values: combinationsOptions.map(x => x.value),
                valueFormatter: (params: any) => combinationsOptions.find(x => x.value === params.value)?.label,
                suppressSelectAll: false
            },
            cellRenderer: (props: { data: TripRule, node: RowNode, api: GridApi }) => {
                return (
                    <>
                        {
                            !!props.data.combinations && props.data.combinations.map((comb) => {
                                return (
                                    <div className="flex items-center">
                                        <div className={"text-xs rounded-[4px] h-full border-[1.5px] p-[6px] mr-2"}>
                                            <div className={"flex items-center font-bold"}>
                                                <div className="text-[13px] leading-[16px]">
                                                    {comb}
                                                </div>
                                                {hasPermission(PermissionAreas.ConfigurationHub_TripRules, PermissionOperations.Modify) &&
                                                    <div onClick={(e) => {onClickDeleteTripCombinationRuleButton(props.data.ruleCode, comb)}} className="cursor-pointer ml-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" stroke="#cf0a2c" fill="#cf0a2c" className="w-[14px] h-[14px]">
                                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                                        </svg>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                )
            }
        },
        {
            field: 'actions',
            headerName: "ACTIONS",
            editable: false,
            filter: false,
            minWidth: 150,
            hide: !hasPermission(PermissionAreas.ConfigurationHub_TripRules, PermissionOperations.Modify),
            cellRenderer: (props: { data: TripRule, node: RowNode }) => {
                return (
                    <>
                        {
                            <div className="flex">
                                {
                                    <div
                                        onClick={() => onClickAddNewCombinationRule(props.data.ruleCode)}
                                        className={"flex text items-center cursor-pointer"}
                                    >
                                        <span className="mr-[8px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32"><path fill="#3987E2" d="M16 2A14.173 14.173 0 0 0 2 16a14.173 14.173 0 0 0 14 14a14.173 14.173 0 0 0 14-14A14.173 14.173 0 0 0 16 2m8 15h-7v7h-2v-7H8v-2h7V8h2v7h7Z"/><path fill="none" d="M24 17h-7v7h-2v-7H8v-2h7V8h2v7h7z"/></svg>
                                        </span>
                                        <span className="hover:underline not-italic ">Add combination</span>
                                    </div>
                                }
                                {
                                    props.node.rowPinned === "top" &&
                                    <div
                                        onClick={onClickCancelNewRow}
                                        className={"flex text items-center cursor-pointer ml-3"}
                                    >
                                        <span className="mr-[8px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#3987E2" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z" /></svg>
                                        </span>
                                        <span className="hover:underline not-italic ">Remove</span>
                                    </div>
                                }
                            </div>
                        }
                    </>
                )
            },
        }
    ], [templateOptions, combinationsOptions]);
    // #endregion

    // #region UI Handlers
    const handleChangeNumericValue = async (value: any, node: RowNode, api: GridApi, column: string) => {
        value = Number(value);

        node.setDataValue(column, value);
    };

    const handleChangeStringValue = async (value: any, node: RowNode, api: GridApi, column: string) => {
        node.setDataValue(column, value);
    };

    const onClickAddNewCombination = () => {
        addNewCombination();
    };

    const onClickDeleteButton = async () => {
        await confirmDeleteCombinations();
    };

    const onClickSaveButton = async () => {
        await confirmSaveCombinations();
    };

    const onClickCancelNewRow = () => {
        cancelNewRow();
    };

    const onClickDeleteTripCombinationRuleButton = async (ruleCode: string, combinationCode: string) => {
        await confirmDeleteTripCombinationRule(ruleCode, combinationCode);
    };

    const onClickAddNewCombinationRule = async (ruleCode: string) => {
        setRuleCodeToAddCombination(ruleCode);
        setOpenSelectCombinationModal(true);
    };

    const onSelectRuleCombination = async (combination: string) => {
        if (!!!combination) {
            toast.info("Please select a combination");
            return;
        }

        if (!!!ruleCodeToAddCombination) {
            let pinnedRow = commonTableRef.current?.getNewRowToInsert();
            let actualCombinations = pinnedRow.combinations ?? [];
            pinnedRow["combinations"] = [...actualCombinations, combination];
            commonTableRef.current?.addNewRowInsertion(pinnedRow);
            setOpenSelectCombinationModal(false);
        } else {
            let tableNodes = commonTableRef.current?.getTableNodes() as RowNode[];
            let selectedNode = tableNodes?.find(x => x.data.ruleCode == ruleCodeToAddCombination);
            if (!!selectedNode) {
                let newValue = selectedNode.data.combinations.concat([combination]);
                setOpenSelectCombinationModal(false);
                selectedNode.setDataValue("combinations", newValue);
            }
            else {
                setOpenSelectCombinationModal(false);
            }
        }
    };
    // #endregion

    // #region Util Functions
    const getTripRules = async () => {
        commonTableRef.current?.showLoadingOverlay();
        const response = await tripsService.getTripRules();
        commonTableRef.current?.hideOverlay();

        if (response.success) {
            commonTableRef.current?.setRowData(response.data);
        } else {
            toast.error('Error retrieving trip rules');
        }
    };

    const getTripCombinations = async () => {
        const response = await tripsService.getTripCombinations();
        setAllCombinations((distinct(response.data.map((x: TripCombination) => x.combinationCode))).sort(x => x));
    };

    const getTripTemplates = async () => {
        const response = await templatesConfigurationService.getTripTemplates({ pageSize: 100 });
        setAllTemplate((distinct(response.data.records.map((x: any) => x.templateID))).sort(x => x));
    };

    const addNewCombination = () => {
        let newRow: any = {};
        newRow.rowId = undefined;
        newRow.templateCode = "";
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const confirmDeleteCombinations = async () => {
        let selectedRows: TripRule[] = commonTableRef.current?.getSelectedRows() as TripRule[];
        let idsToRemove: string[] = selectedRows.map(x => {
            return x.ruleCode as string;
        });

        if (idsToRemove.length > 0) {
            const wantToDelete = await customConfirmAlert({
                message: "Are you sure to delete the selected combinations?",
                title: "Delete combinations"
            });

            if (wantToDelete) {
                await deleteConditions(idsToRemove);
                commonTableRef.current?.redrawTableRows();
            }
        } else {
            toast.info("No rules selected");
        }
    };

    const deleteConditions = async (idsToRemove: string[]) => {
        let selectedRows: TripRule[] = commonTableRef.current?.getSelectedRows() as TripRule[];

        const response = await tripsService.deleteTripRules(idsToRemove, "Removing rules...", "Rules deleted successfully", "Error while deleting rules");
        if (response.success) {
            commonTableRef.current?.deleteTableRows(selectedRows);
        }
    };

    const confirmSaveCombinations = async () => {
        commonTableRef.current?.setStopEditing();
        const wantToSave = await customConfirmAlert({
            message: "Are you sure to save the rules?",
            title: "Save rules"
        });

        if (wantToSave) {
            await saveCombinations();
        }
    };

    const saveCombinations = async () => {
        let tableRows = commonTableRef.current?.getTableRows() as TripRule[];
        let newRowToInsert = commonTableRef.current?.getNewRowToInsert();
        tableRows = newRowToInsert !== undefined ? [newRowToInsert, ...tableRows] : tableRows;

        if (tableRows.length === 0) {
            toast.info("No rules to save");
            return;
        }

        // Check some value null
        let foundNullValue = false;
        Object.keys(new TripRule()).forEach(field => {
            if (tableRows.some((row: any) => {
                let value = row[field];
                if (value === "" || value === null) {
                    return true;
                }

                if (field === "departmentId" || field === "groupId") {
                    if (value === undefined || value === 0) {
                        return true;
                    }
                }
                return false
            })) {
                foundNullValue = true;
            }
        });

        if (foundNullValue) {
            toast.warning("There are some null values");
            return;
        }

        const response = await tripsService.updateTripRules(tableRows, "Saving rules...", "Rules saved successfully", "Error while saving rules");
        if (response.success) {
            commonTableRef.current?.cancelNewRowInsertion();
            commonTableRef.current?.setUnsavedCells([]);
            commonTableRef.current?.setRowsInError([]);

            setConflictRows([]);
            setInAdding(false);
            getTripRules();
        } else if (response.statusCode === 400) {
            if (!!response.data.detailObject) {
                commonTableRef.current?.setRowsInError(response.data.detailObject);
            }
        } else if (response.statusCode === 409) {
            setConflictRows(!!response.data.detailObject ? response.data.detailObject : []);
        }

        setTimeout(function () { commonTableRef.current?.redrawTableRows() }, 0)
    };

    const confirmDeleteTripCombinationRule = async (ruleCode: string, combinationCode: string) => {
        // const wantToSave = await customConfirmAlert({
        //     message: `Are you sure to delete combination ${combinationCode}?`,
        //     title: "Delete combination"
        // });

        if (true) {
            if (!!!ruleCode) {
                let pinnedRow = commonTableRef.current?.getNewRowToInsert();
                let actualCombinations = pinnedRow.combinations ?? [];
                pinnedRow["combinations"] = actualCombinations.filter((x: string) => x !== combinationCode);
                commonTableRef.current?.addNewRowInsertion(pinnedRow);
                setOpenSelectCombinationModal(false);
            } else {
                let tableNodes = commonTableRef.current?.getTableNodes() as RowNode[];
                let selectedNode = tableNodes?.find(x => x.data.ruleCode == ruleCode);
                if (!!selectedNode) {
                    let newValue = selectedNode.data.combinations.filter((x: string) => x !== combinationCode);
                    selectedNode.setDataValue("combinations", newValue);
                }
            }
        }
    };

    const customValueSetter = (params: ValueSetterParams) => {
        let newValue = params.newValue;
        let field = params.colDef.field as string;
        params.data[field] = newValue;

        return true;
    };

    const cancelNewRow = () => {
        commonTableRef.current?.cancelNewRowInsertion();
        setInAdding(false);
    };
    // #endregion

    // #region CallBack
    const onGridReady = useCallback(() => {
        getTripTemplates();
        getTripCombinations();
        getTripRules();
    }, []);
    // #endregion

    // #region Use Effects

    // #endregion

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Trip Rules
                </div>
            </div>

            <AuthorizedComponent area={PermissionAreas.ConfigurationHub_TripRules} operation={PermissionOperations.Modify}>
                <div className="mb-6 flex items-center">
                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px]"
                        callback={onClickAddNewCombination}
                        disabled={inAdding}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3333 9.16602V9.66602H10.8333H15.3333V10.3327H10.8333H10.3333V10.8327V15.3327H9.66667V10.8327V10.3327H9.16667H4.66667V9.66602H9.16667H9.66667V9.16602V4.66602H10.3333V9.16602Z" fill="#3987E2" stroke="white" />
                        </svg>
                    </Button>

                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px] ml-[12px]"
                        callback={onClickDeleteButton}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z" /></svg>
                    </Button>

                    <div className="ml-[12px]">
                        <Button
                            callback={onClickSaveButton}
                            className="bg-transparent text-black border-[1.4px] border-black"
                        >
                            Save configuration
                        </Button>
                    </div>

                    {
                        conflictRows.length > 0 &&
                        <div className="ml-7 flex cursor-pointer" onClick={() => setOpenModalShowConflicts(true)}>
                            <div className="border-red pt-[10px] pl-[15px] pr-[15px] pb-[10px] border-[3px] rounded-[10px] flex">
                                <svg className="text-red" xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 10 10"><path fill="currentColor" d="M10 5A5 5 0 1 0 0 5a5 5 0 0 0 10 0M9 5a4 4 0 0 1-6.453 3.16L8.16 2.547C8.686 3.224 9 4.076 9 5M7.453 1.84L1.84 7.453A4 4 0 0 1 7.453 1.84" /></svg>
                                <span className="ml-3">Some trip rules are overlapping</span>
                            </div>
                        </div>
                    }
                </div>
            </AuthorizedComponent>

            <div style={containerStyle}>
                <CommonTable
                    ref={commonTableRef}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    valueSetter={customValueSetter}
                    options={{
                        pageSize: 10,
                        showSelectionCheckbox: hasPermission(PermissionAreas.ConfigurationHub_TripRules, PermissionOperations.Modify),
                        showRowsNumber: true,
                        editable: hasPermission(PermissionAreas.ConfigurationHub_TripRules, PermissionOperations.Modify)
                    }}
                />
            </div>

            {openSelectCombinationModal === true &&
                <ModalSelectCombination
                    callbackOnClose={() => setOpenSelectCombinationModal(false)}
                    callbackOnAdd={onSelectRuleCombination}
                    combinations={combinationsOptions}
                />
            }

            {openModalShowConflicts === true &&
                <ModalShowTripRulesConflicts
                    callbackCloseModal={() => setOpenModalShowConflicts(false)}
                    conflicts={conflictRows}
                    templateOptions={templateOptions}
                    combinationsOptions={combinationsOptions}
                />
            }
        </div>
    )
}

export default PlanningToolTripRules