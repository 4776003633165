import { createSlice } from "@reduxjs/toolkit";

export const navigationInitialState = {
  primaryTimeSpan: {},
  secondaryTimeSpan: {},
  doAdvSearch: null,
  advSearchPerformed: false,
  navigatedMenu: "",

  // Used for reload Preset List
  refreshPresets: null,

  // Used for reload Preset List and select an item of it
  refreshAndSelectPreset: -1,

  // Indicates if Preset List for Dashboard DropDown Filter has been fetched
  presetsFetched: false,

  // ID of selected Preset from Dashboard Filter
  selectedPresetId: -1,

  // Used for opening modal for editing a preset
  requestUpdatePreset: {},

  // Used for keep preset dropdown open
  presetDropDownKeepOpen: false,

  // Trading partners fetched from server
  // allTradingPartners: [],

  // Carriers fetched from server
  allCarriers: [],

  // Presets fetched from server
  allPresets: [],

  // Used for TripDetails and TODetails refresh
  lastDetailsRefresh: null,
};

const commonReducers = {
  setPrimaryTimeSpan(state, action) {
    state.primaryTimeSpan = {};
    if (action.payload.from && action.payload.to) {
      state.primaryTimeSpan.from = action.payload.from;
      state.primaryTimeSpan.to = action.payload.to;
    }
  },
  setSecondaryTimeSpan(state, action) {
    state.secondaryTimeSpan = {};
    if (action.payload.from && action.payload.to) {
      state.secondaryTimeSpan.from = action.payload.from;
      state.secondaryTimeSpan.to = action.payload.to;
    }
  },
  setDoAdvSearch(state, action) {
    state.doAdvSearch = action.payload;
  },
  setAdvSearchPerformed(state, action) {
    state.advSearchPerformed = action.payload;
  },
  setNavigatedMenu(state, action) {
    state.navigatedMenu = action.payload;
  },
  setRefreshAndSelectPreset(state, action) {
    state.refreshAndSelectPreset = action.payload;
  },
  presetsFetched(state, action) {
    state.presetsFetched = action.payload;
  },
  setSelectedPresetId(state, action) {
    state.selectedPresetId = action.payload;
  },
  requestUpdatePreset(state, action) {
    state.requestUpdatePreset = action.payload;
  },
  setRefreshPresets(state, action) {
    state.refreshPresets = action.payload;
  },
  setPresetDropDownKeepOpen(state, action) {
    state.presetDropDownKeepOpen = action.payload;
  },
  //storeAllTradingPartners(state, action) {
  //  state.allTradingPartners = action.payload;
  //},
  storeAllCarriers(state, action) {
    state.allCarriers = action.payload;
  },
  storeAllPresets(state, action) {
    state.allPresets = action.payload;
  },
  setLastDetailsRefresh(state, action) {
    state.lastDetailsRefresh = action.payload;
  },
  setAllInfo(state, action) {
    state.primaryTimeSpan = action.payload.primaryTimeSpan;
    state.secondaryTimeSpan = action.payload.secondaryTimeSpan;
    state.doAdvSearch = action.payload.doAdvSearch;
    state.advSearchPerformed = action.payload.advSearchPerformed;
    state.navigatedMenu = action.payload.navigatedMenu;
    state.refreshAndSelectPreset = action.payload.refreshAndSelectPreset;
    state.presetsFetched = action.payload.presetsFetched;
    state.selectedPresetId = action.payload.selectedPresetId;
    state.requestUpdatePreset = action.payload.requestUpdatePreset;
    state.refreshPresets = action.payload.refreshPresets;
    state.presetDropDownKeepOpen = action.payload.presetDropDownKeepOpen;
    state.allCarriers = action.payload.allCarriers;
    state.allPresets = action.payload.allPresets;
    state.lastDetailsRefresh = action.payload.lastDetailsRefresh;
  },
};

const ordersNavigationSlice = createSlice({
  name: "ordersNavigation",
  initialState: navigationInitialState,
  reducers: commonReducers,
});

const ordersExecutionNavigationSlice = createSlice({
  name: "ordersExecutionNavigation",
  initialState: navigationInitialState,
  reducers: commonReducers,
});

const tripsNavigationSlice = createSlice({
  name: "tripsNavigation",
  initialState: navigationInitialState,
  reducers: commonReducers,
});

const salesOrdersNavigationSlice = createSlice({
  name: "salesOrdersNavigation",
  initialState: navigationInitialState,
  reducers: commonReducers,
});

export const selectLastTripUpdate = state =>
  state.tripsNavigation.lastDetailsRefresh;
export const selectLastTOUpdate = state =>
  state.ordersNavigation.lastDetailsRefresh;
export const selectLastTOExecutionUpdate = state =>
  state.ordersExecutionNavigationActions.lastDetailsRefresh;
export const selectLastSOUpdate = state =>
  state.ordersNavigation.lastDetailsRefresh;

export const ordersNavigationActions = ordersNavigationSlice.actions;
export const ordersExecutionNavigationActions =
  ordersExecutionNavigationSlice.actions;
export const tripsNavigationActions = tripsNavigationSlice.actions;
export const salesOrdersNavigationActions = salesOrdersNavigationSlice.actions;

export const ordersNavigationReducer = ordersNavigationSlice.reducer;
export const ordersExecutionNavigationReducer =
  ordersExecutionNavigationSlice.reducer;
export const tripsNavigationReducer = tripsNavigationSlice.reducer;
export const salesOrdersNavigationReducer = salesOrdersNavigationSlice.reducer;
