import { createSlice } from "@reduxjs/toolkit";

export const searchInitialState = {
  searchedCode: "",
  searchedType: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState: searchInitialState,
  reducers: {
    setSearched(state, action) {
      state.searchedCode = action.payload.searchedCode;
      state.searchedType = action.payload.searchedType;
    },
  },
});

export const { setSearched } = searchSlice.actions;

export default searchSlice.reducer;
