import React, { useState } from "react";
import Modal from "../../components/ui/modal/Modal";
import Button from "../../components/ui/button/Button";
import Select from "../../components/ui/select/Select";

type Props = {
    callbackOnClose: () => any;
    callbackOnAdd: (combination: string) => any;
    combinations: {
        value: string;
        label: string;
    }[]
}

const ModalSelectCombination: React.FC<Props> = ({
    callbackOnClose,
    callbackOnAdd,
    combinations
}) => {

    const [selectedCombination, setSelectedCombination] = useState<string>("");

    return (
        <>
            <Modal
                callbackCloseModal={callbackOnClose}
                title="Select combination"
                footer={
                    <div className="flex items-center justify-end">
                        <Button
                            callback={() => callbackOnAdd(selectedCombination)}
                        >
                            Add
                        </Button>
                    </div>
                }
                customStyleChildrenContainer={{
                    overflow: "visible"
                }}
            >
                <>
                    <div className="relative" style={{ zIndex: 1000 }}>
                        <Select
                            options={combinations}
                            callbackOnChange={(e) => setSelectedCombination(e.target.value)}
                            defaultOptionEnabled={true}
                            placeholder={"Select Combination"}
                        />
                    </div>
                </>
            </Modal>
        </>
    )
}

export default ModalSelectCombination;