// import dayjs from "dayjs";
import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import { TRIP_PAGINATION_COUNT } from "../../context/TripContext";
import { TripCombination, TripRule, TripStop } from "../../types/trips";
import basicRequest from "../basicRequest";

export type UpdateTripRequestData = {
  header: {
    carrierId: null | number;
    equipmentId: number;
    description: string;
    truckId: null | number;
    trailerId: null | number;
    transportMode: string;
  };
  stops: TripStop[];
};

export type CheckStopOrderRequestData = {
  stops: TripStop[];
};

export type CreateTripParams = {
  header: {
    carrierId: number | null;
    equipmentId: number | null;
    description: string;
    truckPlateNumber: string | null;
    truckPlateTrailer: string | null;
  };
  legs: number[];
};

export type UpdateTripLegsRequestData = {
  legsIds: number[];
  force?: boolean;
  description?: string;
};

export type AdvancedFiltersType = {
  pickupDate?: string;
  deliveryDate?: string;
  tripDate?: string;
  sender?: string;
  senderCity?: string;
  consignee?: string;
  consigneeCity?: string;
  maxShippingUnits?: number;
  minShippingUnits?: number;
  generalId?: string;
  tripStatus?: number;
  description?: string;
};

export const tripsService = {
  createTrip: async (
    body: CreateTripParams,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.post(
      planningToolApiUrls.createTrip,
      body,
      undefined,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  getTrips: async (
    type: "all" | "my" | "groups" | "owned",
    searchFilter: string = "",
    advancedFilters: AdvancedFiltersType | null = null,
    departmentId: number | null,
    page: number = 1,
    pageSize = TRIP_PAGINATION_COUNT
  ) => {
    const request = new basicRequest();
    let paramObj: any = {
      searchFilter: searchFilter,
      page: page,
      pageSize: pageSize,
      departmentId: departmentId,
    };

    // Add advancedFilters
    if (advancedFilters !== null) {
      paramObj = {
        ...paramObj,
        ...advancedFilters,
      };
    }

    // Add type
    paramObj[type] = true;
    return await request.get(planningToolApiUrls.createTrip, paramObj);
  },
  updateTrip: async (
    tripId: number,
    requestData: UpdateTripRequestData,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.put(
      planningToolApiUrls.createTrip + "/" + tripId,
      requestData,
      undefined,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  deleteTrip: async (
    tripId: number,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.delete(
      planningToolApiUrls.createTrip + "/" + tripId,
      undefined,
      undefined,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  checkStopOrder: async (
    tripId: number,
    requestData: CheckStopOrderRequestData
  ) => {
    const request = new basicRequest();
    return await request.put(
      `${planningToolApiUrls.createTrip}/checkStopOrder/${tripId}`,
      requestData
    );
  },
  addTripLegs: async (
    tripId: number,
    requestData: UpdateTripLegsRequestData,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.put(
      `${planningToolApiUrls.createTrip}/${tripId}/legs`,
      requestData,
      undefined,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  removeTripLegs: async (
    tripId: number,
    requestData: UpdateTripLegsRequestData,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.delete(
      `${planningToolApiUrls.createTrip}/${tripId}/legs`,
      requestData,
      undefined,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  checkConfirmTrip: async (
    tripId: number,
    checked: boolean,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.put(
      `${planningToolApiUrls.createTrip}/confirm/${tripId}`,
      checked,
      undefined,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  takeCharge: async (
    tripId: number,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.post(
      `${planningToolApiUrls.takeChargeTrip}/${tripId}`,
      {},
      {},
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  revokeCharge: async (
    tripId: number,
    userEmail: string,
    confirm: boolean,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.delete(
      `${planningToolApiUrls.revokeChargeTrip}/${tripId}`,
      {
        tripId: tripId,
        user: userEmail,
        confirm: confirm,
      },
      {},
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  sendDispatchableTrips: async (
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    await request.post(
      `${planningToolApiUrls.createTrip}/sendTripJob`,
      {},
      {},
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  replanTrip: async (
    tripId: number,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.put(
      `${planningToolApiUrls.createTrip}/replan/${tripId}`,
      {},
      {},
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  getTripErrors: async (tripId: number) => {
    const request = new basicRequest();
    return await request.get(
      planningToolApiUrls.createTrip + "/errors/" + tripId,
      {},
      undefined,
      undefined,
      "Error getting trip errors."
    );
  },
  sendTripImmediately: async (
    tripId: number,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.post(
      planningToolApiUrls.createTrip + "/sendTrip/" + tripId,
      {},
      {},
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  getTripInfo: async (tripId: number | null) => {
    const request = new basicRequest();
    return await request.get(
      planningToolApiUrls.createTrip + "/tripInfo",
      {
        TripId: tripId,
      },
      undefined,
      undefined,
      "Error getting trip info."
    );
  },
  getTripCombinations: async () => {
    const request = new basicRequest();
    return await request.get(
      planningToolApiUrls.tripCombinations,
      {},
      undefined,
      undefined,
      "Error getting trip combinations."
    );
  },
  updateTripCombinations: async (
    tripCombinations: TripCombination[],
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.put(
      planningToolApiUrls.tripCombinations,
      {
        tripCombinations: tripCombinations,
      },
      null,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  deleteTripCombinations: async (
    ids: string[],
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.delete(
      planningToolApiUrls.tripCombinations,
      {
        combinationIds: ids,
      },
      undefined,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  getTripRules: async () => {
    const request = new basicRequest();
    return await request.get(
      planningToolApiUrls.tripRules,
      {},
      undefined,
      undefined,
      "Error getting trip rules."
    );
  },
  deleteTripRules: async (
    ids: string[],
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.delete(
      planningToolApiUrls.tripRules,
      {
        tripRuleIds: ids,
      },
      undefined,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  updateTripRules: async (
    tripRules: TripRule[],
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.put(
      planningToolApiUrls.tripRules,
      {
        tripRules: tripRules,
      },
      null,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  deleteTripCombinationRule: async (
    ruleCode: string,
    combinationCode: string,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.delete(
      planningToolApiUrls.tripCombinationRule,
      {
        ruleCode: ruleCode,
        combinationCode: combinationCode,
      },
      undefined,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
  addTripCombinationRule: async (
    ruleCode: string,
    combinationCode: string,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.post(
      planningToolApiUrls.tripCombinationRule,
      {
        ruleCOde: ruleCode,
        combinationCode: combinationCode,
      },
      null,
      loadingMessage,
      successMessage,
      errorMessage
    );
  },

  _checkConfirmTrip: async (
    tripId: number[],
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.put(
      `${planningToolApiUrls.createTrip}/confirm/`,
      tripId,
      {},
      loadingMessage,
      successMessage,
      errorMessage
    );
  },

  _takeCharge: async (
    tripId: number[],
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.post(
      `${planningToolApiUrls.takeChargeTrip}`,
      tripId,
      {},
      loadingMessage,
      successMessage,
      errorMessage
    );
  },

  _sendTripImmediately: async (
    tripId: number[],
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.post(
      `${planningToolApiUrls.createTrip}/sendTrip`,
      tripId,
      {},
      loadingMessage,
      successMessage,
      errorMessage
    );
  },

  _revokeCharge: async (
    tripId: number[],
    userEmail: string,
    confirm: boolean,
    loadingMessage: string | null = null,
    successMessage: string | null = null,
    errorMessage: string | null = null
  ) => {
    const request = new basicRequest();
    return await request.delete(
      `${planningToolApiUrls.revokeChargeTrip}`,
      {
        tripId: tripId,
        user: userEmail,
        confirm: confirm,
      },
      {},
      loadingMessage,
      successMessage,
      errorMessage
    );
  },
};
