import { CSSProperties } from "react";

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #D1D5DB",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    padding: "8px 16px",
    backgroundColor: "white",
    fontSize: "14px",
    fontWeight: "500",
    color: "#4B5563",
    cursor: "pointer",
  },
  menu: {
    position: "absolute",
    right: 0,
    left: 235,
    top: 0,
    zIndex: 10,
    width: "224px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
    backgroundColor: "white",
  },
  menuItem: {
    display: "block",
    width: "100%",
    textAlign: "left",
    padding: "8px 16px",
    fontSize: "14px",
    color: "#4B5563",
    background: "none",
    border: "none",
    cursor: "pointer",
    outline: "none",
  },
};

export default styles;
