import React, { useState } from 'react'
import Button from "../../components/ui/button/Button";
import InputText from "../../components/ui/inputText/InputText";
import dayjs from 'dayjs';
import { TripInfo } from '../../types/trips';
import { tripsService } from '../../api/tripsService/tripsService';

const PlanningToolTripInfo = () => {

    // #region State
    const [tripId, setTripId] = useState<number | null>(null);
    const [tripInfo, setTripInfo] = useState<TripInfo | undefined>(undefined);
    // #endregion

    // #region Memo
    
    // #endregion

    // #region UI Handlers
    const handleChangeTripIdInput = (e: any) => {
        setTripId(e);
    };

    const handleClickSearchButton = async () => {
        var response = await tripsService.getTripInfo(tripId);

        if (response.success) {
            setTripInfo(response.data);
        }
    };
    // #endregion

    // #region Util Functions
    
    // #endregion

    // #region Use Effects
    
    // #endregion

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div className="font-light text-lg leading-[25px]">Planning Tool</div>
            <div className="flex items-center pb-3 mb-6 border-b">
                <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                    Trip Info
                </div>
            </div>

            <div>
                <label>
                    Insert Trip Id to view Info
                </label>
                
                <div className='mt-2 flex'>
                    <InputText
                        callbackOnChange={handleChangeTripIdInput}
                        type='number'
                        name="order_number"
                        placeholder=""
                        value={tripId?.toString() ?? ""}
                    />

                    <Button
                        className="bg-blue text-white !px-[12px] !py-[8px] ml-[12px]"
                        callback={handleClickSearchButton}
                    >
                        Search
                    </Button>
                </div>

                {!!tripInfo &&
                (<div className='mt-5'>
                    <table className="text-left w-full">
                        <thead className="bg-[#F9F7F6] text-black font-semibold text-[12px] uppercase border-t border-[#6F6F6F] border-b">
                            <tr>
                                <th className="p-3">Daily Id</th>
                                <th className="p-3">Creation Time</th>
                                <th className="p-3">Creation User</th>
                                <th className="p-3">Status</th>
                                <th className="p-3">Equipment</th>
                                <th className="p-3">Transport Mode</th>
                                <th className="p-3">Sga Trip Id</th>
                                <th className="p-3">Trip Sent</th>
                                <th className="p-3">Trip Replan</th>
                                <th className="p-3">Feedback</th>
                                <th className="p-3">Status History</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b border-[#F2F2F2] uppercase text-[12px] font-semibold text-[#6F6F6F] align-top">
                                <td>
                                    {tripInfo?.dailyId}
                                </td>
                                <td>
                                    {dayjs(tripInfo?.creationTime).format('DD/MM/YYYY HH:mm')}
                                </td>
                                <td>
                                    {tripInfo.creationUser}
                                </td>
                                <td>
                                    {tripInfo.status}
                                </td>
                                <td>
                                    {tripInfo.equipment}
                                </td>
                                <td>
                                    {tripInfo.transportMode}
                                </td>
                                <td>
                                    {tripInfo.sgaTripId}
                                </td>
                                <td>
                                    {tripInfo.tripSent.map((sent) => {
                                        return (
                                            <p>
                                                {dayjs(sent).format('DD/MM/YYYY HH:mm')}
                                            </p>
                                        );
                                    })}
                                </td>
                                <td>
                                    {tripInfo.tripReplan.map((replan) => {
                                        return (
                                            <p>
                                                {dayjs(replan).format('DD/MM/YYYY HH:mm')}
                                            </p>
                                        );
                                    })}
                                </td>
                                <td>
                                    {!!tripInfo.feedback &&
                                        <>
                                            <p>Success: {tripInfo.feedback?.success ? "Yes" : "No"}</p>
                                            <p>Time: {dayjs(tripInfo?.feedback?.time).format('DD/MM/YYYY HH:mm')}</p>
                                        </>
                                    }
                                </td>
                                <td>
                                    {tripInfo.statusHistory.map((status) => {
                                        return (
                                            <div>
                                                <p>Status: {status.status}</p>
                                                <p>TimeStamp: {dayjs(status?.timeStamp).format('DD/MM/YYYY HH:mm')}</p>
                                                <p>User: {status.user}</p>
                                                <hr/>
                                            </div>
                                        );
                                    })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>)}
            </div>
        </div>
    );
};

export default PlanningToolTripInfo;