import { createSlice } from "@reduxjs/toolkit";

export const notificationCenterInitialState = {
  notifications: [],
};

const notificationCenterSlice = createSlice({
  name: "notificationCenter",
  initialState: notificationCenterInitialState,
  reducers: {
    setNewNotification(state, action) {
      var actual = [...state.notifications];
      actual = actual.concat(action.payload);

      state.notifications = actual;
    },
    readAllNotifications(state, action) {
      var actual = [...state.notifications];
      actual = actual.map(a => {
        return { ...a, read: true };
      });

      state.notifications = actual;
    },
  },
});

export const {
  setNewNotification,
  readAllNotifications,
} = notificationCenterSlice.actions;

export default notificationCenterSlice.reducer;
