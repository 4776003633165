// import dayjs from "dayjs";
import { planningToolApiUrls } from "../../configs/planningToolApiUrls";
import basicRequest from "../basicRequest";

export class GetCollectionTemplateOption {
    public templateID?: string | null;
    public procedureName?: string | null;
    public useCollectionPartner?: boolean | null;
    public insertInTrip?: boolean | null;
    public isDirect?: boolean | null;
    public setDepot?: boolean | null;
    public lineCodeProcedure?: string | null;
    public orderBy?: string | null;
    public orderDirection?: string | null = "asc";
    public pageSize?: number | null;
    public pageIndex?: number | null;
}

export class GetDeliveryTemplateOption {
    public templateID?: string | null;
    public procedureName?: string | null;
    public useCollectionPartner?: boolean | null;
    public lineCodeProcedure?: string | null;
    public arrivalType?: string | null;
    public deliveryType?: string | null;
    public setDepot?: boolean | null;
    public setPartner?: boolean | null;
    public orderBy?: string | null;
    public orderDirection?: string | null = "asc";
    public pageSize?: number | null;
    public pageIndex?: number | null;
}

export class GetTripTemplateOption {
    public templateID?: string | null;
    public procedureName?: string| null;
    public branch?: string | null;
    public tripType?: string | null;
    public sector?: string | null;
    public priority?: string | null;
    public deliveryPartner?: boolean | null;
    public orderBy?: string | null;
    public orderDirection?: string | null = "asc";
    public pageSize?: number | null;
    public pageIndex?: number | null;
}

export const templatesConfigurationService = {
    getTripTemplates: async (options: GetTripTemplateOption = new GetTripTemplateOption()) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.templatesConfiguration + '/TripTemplate', options);
    },
    getCollectionTemplates: async (options: GetCollectionTemplateOption = new GetCollectionTemplateOption()) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.templatesConfiguration + '/CollectionTemplate', options);
    },
    getDeliveryTemplates: async (options: GetDeliveryTemplateOption = new GetDeliveryTemplateOption()) => {
        const request = new basicRequest();
        return await request.get(planningToolApiUrls.templatesConfiguration + '/DeliveryTemplate', options);
    }
}