import React from "react";

const Page404: React.FC = () => {
  return (
    <main>
      <div className="px-[16px] md:px-[30px] text-brown uppercase font-bold text-center my-20">
        <div className="text-[52px]">404</div>
        <div className="text-[36px]">not found</div>
      </div>
    </main>
  );
};

export default Page404;
