import { createSlice } from "@reduxjs/toolkit";

const orderDetailInitialState = {
  isUpdating: false,
  isQueued: false,
  idUpdating: -1,
  lastUpdateDate: null,
  enrichmentStartDate: null,
};

const orderDetailSlice = createSlice({
  name: "orderDetail",
  initialState: orderDetailInitialState,
  reducers: {
    setIsUpdating(state, action) {
      state.isUpdating = action.payload;
    },
    setIsQueued(state, action) {
      state.isQueued = action.payload;
    },
    setIdUpdating(state, action) {
      state.idUpdating = action.payload;
    },
    setLastUpdateDate(state, action) {
      state.lastUpdateDate = action.payload;
    },
    setEnrichmentStartDate(state, action) {
      state.enrichmentStartDate = action.payload;
    },
  },
});

export const {
  setIsUpdating,
  setIsQueued,
  setIdUpdating,
  setLastUpdateDate,
  setEnrichmentStartDate,
} = orderDetailSlice.actions;

export default orderDetailSlice.reducer;
