import React, { useState } from "react";
import styles from "./DensityDropdownStyles";

type DensityDropdownProps = {
  density: string;
  handleDensityChange: (density: string) => void;
  DENSITY_LIST: string[];
};

const DensityDropdown: React.FC<DensityDropdownProps> = ({
  density,
  handleDensityChange,
  DENSITY_LIST,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <div>
        <button
          type="button"
          style={styles.button}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span style={{ flexGrow: 1 }}>{density}</span>
          <svg
            style={{ height: "20px", width: "20px", marginLeft: "8px" }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              transform="rotate(90 12 12)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 15l7-7 7 7"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div style={styles.menu}>
          <div
            style={{ padding: "8px 0" }}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {DENSITY_LIST.map((item) => (
              <button
                key={item}
                onClick={() => {
                  handleDensityChange(item);
                  setIsOpen(false);
                }}
                style={{
                  ...styles.menuItem,
                  fontWeight: item === density ? "bold" : "normal",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#F3F4F6")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "transparent")
                }
              >
                {item}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DensityDropdown;
