import { GridApi, RowNode } from "@ag-grid-community/core";
import React, { useContext, useMemo, useRef, useState } from "react";
import CommonTable, { TableRef } from "../../components/commonTable/CommonTable";
import Button from "../../components/ui/button/Button";
import { toast } from "react-toastify";
import { DefaultRoutingConfiguration } from "../../types/routingConfiguration";
import { IMasterDataContext, MasterDataContext } from "../../context/MasterDataContext";
import Select from "../../components/ui/select/Select";
import Modal from "../../components/ui/modal/Modal";
import { customConfirmAlert } from "../../utils/functions/customConfirmAlert";
import SelectAsyncPreference from "../../components/ui/selectAsyncPreference/SelectAsyncPreference";
import { masterDataService } from "../../api/masterDataService/masterDataService";
import { ResultType } from "../../components/ui/selectTextInputAsync/SelectTextInputAsync";
import "./PlanningToolDefaultRoutingAssignment.css";
import { CustomTooltipProps } from "@ag-grid-community/react";
import usePermissions from "../../utils/hooks/usePermissions";
import AuthorizedComponent from "../../components/permissions/AuthorizedComponent";
import { PermissionAreas, PermissionOperations } from "../../types/permissions";
import ModalShowRoutingAssignmentConflicts from "../../components/modals/modalShowRoutingAssignmentConflicts/ModalShowRoutingAssignmentConflicts";
import { GetRoutingOption, routingConfigurationService } from "../../api/routingConfigurationService/routingConfigurationService";
import { gridTextBasicFilterParamsSSR } from "../../configs/constants";

const containerStyle = { width: '100%', height: '100%' };

const PlanningToolDefaultRoutingAssignment: React.FC = () => {

    const { hasPermission } = usePermissions();
    const { businessFlowTypes, deposits, updateDeposits } = useContext(MasterDataContext) as IMasterDataContext;

    // #region State

    // Data from BE
    const commonTableRef = useRef<TableRef>(null);

    const [inAdding, setInAdding] = useState<boolean>(false);
    const [openModalShowConflicts, setOpenModalShowConflicts] = useState<boolean>(false);
    const [conflictRows, setConflictRows] = useState<DefaultRoutingConfiguration[]>([]);

    // Modal
    const [openSpecialDuplicateModal, setOpenSpecialDuplicateModel] = useState<boolean>(false);
    const [rowToSpecialCopy, setRowToSpecialCopy] = useState<DefaultRoutingConfiguration | null>(null);
    // #endregion

    // #region Memo
    const businessFlowTypesOptions = useMemo(() => {
        return businessFlowTypes.map((e) => {
            return {
                value: e.name,
                label: e.name
            }
        });
    }, [businessFlowTypes]);

    const columnDefs: any = useMemo(() => [
        {
            field: "customerCode",
            headerName: "CUSTOMER CODE",
            minWidth: 95,            
            editable: (props: any) => {
                return props.node.rowPinned === "top";
            }
        },
        {
            field: 'businessFlow',
            headerName: "BUSINESS FLOW TYPE",
            cellClass: "agCellAlignCenter",
            editable: false,
            filter: "agSetColumnFilter",
            filterParams: {
                values: businessFlowTypes.map(x => x.name),
            },            
            minWidth: 170,
            cellRenderer: (props: { data: DefaultRoutingConfiguration, node: RowNode, api: GridApi, value: any }) => {
                let selectedValue = !!props.value ? props.value : props.data.businessFlow;
                if (!!selectedValue) {
                    selectedValue = businessFlowTypesOptions.find(x => x.value.toUpperCase() === selectedValue.toUpperCase())?.value;
                }

                return (
                    <>
                        {
                            props.node.rowPinned === "top" &&
                            <Select
                                style={{ backgroundColor: "transparent" }}
                                options={businessFlowTypesOptions}
                                callbackOnChange={(e) => {
                                    props.node?.setDataValue("businessFlow", e.target.value);
                                }}
                                value={selectedValue}
                                placeholder={"Select BFT..."}
                                defaultOptionEnabled={true}
                                disabled={props.node.rowPinned !== "top"}
                                className="h-[30px] p-0 w-full"
                            />
                        }

                        {props.node.rowPinned !== "top" &&
                            <>{selectedValue}</>
                        }
                    </>

                )
            }
        },
        {
            field: "originCountry",
            headerName: "ORIGIN COUNTRY",
            minWidth: 105,
            editable: (props: any) => {
                return props.node.rowPinned === "top";
            }
        },
        {
            field: "originZipCodeFrom",
            minWidth: 100,
            headerName: "ORIGIN POSTCODE FROM",
            editable: (props: any) => {
                return props.node.rowPinned === "top";
            }
        },
        {
            field: "originZipCodeTo",
            minWidth: 100,
            headerName: "ORIGIN POSTCODE TO",
            editable: (props: any) => {
                return props.node.rowPinned === "top";
            }
        },
        {
            field: "destinationCountry",
            minWidth: 125,
            headerName: "DESTINATION COUNTRY",
            editable: (props: any) => {
                return props.node.rowPinned === "top";
            }
        },
        {
            field: "destinationZipCodeFrom",
            minWidth: 125,
            headerName: "DESTINATION POSTCODE FROM",
            editable: (props: any) => {
                return props.node.rowPinned === "top";
            }
        },
        {
            field: "destinationZipCodeTo",
            minWidth: 125,
            headerName: "DESTINATION POSTCODE TO",
            editable: (props: any) => {
                return props.node.rowPinned === "top";
            }
        },
        {
            field: "deposit1",
            headerName: "1ST DEPOT",
            minWidth: 100,
            filter: false,
            editable: false,
            sortable: false,
            tooltipComponent: (props: CustomTooltipProps) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.RoutingCondition, PermissionOperations.Modify) &&
                            <div className="bg-white p-4 rounded shadow">
                                <SelectAsyncPreference
                                    label="Select depot"
                                    initialOptions={deposits}
                                    value={null}
                                    name="deposit"
                                    placeholder="Select depot..."
                                    callbackOnSelect={(value) => {
                                        if (!!value) {
                                            // Update value
                                            let actualRoute = props.node?.data.route;
                                            actualRoute[0] = value.name;
                                            //props.node?.setData({...props.node.data, route: actualRoute});
                                            //props.node?.setDataValue("route", actualRoute);
                                            props.node?.setDataValue("deposit1", value.name);

                                            // Close tooltip
                                            if (!!props.hideTooltipCallback) {
                                                props.hideTooltipCallback();
                                            }
                                        }
                                    }}
                                    dropDownPositionY="bottom"
                                    apiServiceSearch={masterDataService.searchDeposits}
                                    apiServiceAddRemoveToFavourites={masterDataService.addRemoveDepositToFavourites}
                                    callbackOnAddedToFavourites={updateDeposits}
                                    callbackOnRemovedFromFavourites={updateDeposits}
                                    paginated={true}
                                    paginationCount={25}
                                />
                            </div>
                        }
                    </>
                )
            },
            tooltipField: 'route',
            cellRenderer: (props: { data: DefaultRoutingConfiguration, node: RowNode, api: GridApi, value: ResultType | null | undefined }) => {
                let selectedValue = (!!props.data.route ? props.data.route[0] : "");
                return (
                    <div className="flex items-center h-full">
                        <div>
                            {!!selectedValue ? selectedValue : '-'}
                        </div>

                        {
                            hasPermission(PermissionAreas.RoutingCondition, PermissionOperations.Modify) &&
                            <>
                                <div className="ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(57, 135, 226)" className="w-4 h-4">
                                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                                        <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                                    </svg>
                                </div>

                                <div
                                    onClick={() => {
                                        // Update value
                                        let actualRoute = props.node?.data.route;
                                        actualRoute[0] = '';
                                        //props.node?.setData({...props.node.data, route: actualRoute});
                                        //props.node?.setDataValue("route", actualRoute);
                                        props.node?.setDataValue("deposit1", '');
                                    }}
                                    className="cursor-pointer ml-2"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#cf0a2c" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </div>
                            </>
                        }
                    </div>
                )
            }
        },
        {
            field: "deposit2",
            headerName: "2ND DEPOT",
            minWidth: 100,
            filter: false,
            editable: false,
            sortable: false,
            tooltipField: "route",
            tooltipComponent: (props: CustomTooltipProps) => {
                return (
                    <>
                        {hasPermission(PermissionAreas.RoutingCondition, PermissionOperations.Modify) &&
                            <div className="bg-white p-4 rounded shadow">
                                <SelectAsyncPreference
                                    label="Select depot"
                                    initialOptions={deposits}
                                    value={null}
                                    name="deposit"
                                    placeholder="Select depot..."
                                    callbackOnSelect={(value) => {
                                        if (!!value) {
                                            // Update value
                                            let actualRoute = props.node?.data.route;
                                            actualRoute[1] = value.name;
                                            //props.node?.setData({...props.node.data, route: actualRoute});
                                            //props.node?.setDataValue("route", actualRoute);
                                            props.node?.setDataValue("deposit2", value.name);

                                            // Close tooltip
                                            !!props.hideTooltipCallback && props.hideTooltipCallback();
                                        }
                                    }}
                                    dropDownPositionY="bottom"
                                    apiServiceSearch={masterDataService.searchDeposits}
                                    apiServiceAddRemoveToFavourites={masterDataService.addRemoveDepositToFavourites}
                                    callbackOnAddedToFavourites={updateDeposits}
                                    callbackOnRemovedFromFavourites={updateDeposits}
                                    paginated={true}
                                    paginationCount={25}
                                />
                            </div>
                        }
                    </>
                )
            },
            cellRenderer: (props: { data: DefaultRoutingConfiguration, node: RowNode, api: GridApi, value: ResultType | null | undefined }) => {
                let selectedValue = (!!props.data.route ? props.data.route[1] : "");
                return (
                    <div className="flex items-center h-full">
                        <div>
                            {!!selectedValue ? selectedValue : '-'}
                        </div>

                        {
                            hasPermission(PermissionAreas.RoutingCondition, PermissionOperations.Modify) &&
                            <>
                                <div className="ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(57, 135, 226)" className="w-4 h-4">
                                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                                        <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                                    </svg>
                                </div>

                                <div
                                    onClick={() => {
                                        // Update value
                                        let actualRoute = props.node?.data.route;
                                        actualRoute[1] = '';
                                        //props.node?.setData({...props.node.data, route: actualRoute});
                                        //props.node?.setDataValue("route", actualRoute);
                                        props.node?.setDataValue("deposit2", '');
                                    }}
                                    className="cursor-pointer ml-2"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#cf0a2c" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </div>
                            </>
                        }
                    </div>
                )
            }
        },
        {
            field: 'actions',
            headerName: "ACTIONS",
            editable: false,
            filter: false,
            minWidth: 248,
            hide: !hasPermission(PermissionAreas.RoutingCondition, PermissionOperations.Modify),
            cellRenderer: (props: { data: DefaultRoutingConfiguration, node: RowNode }) => {
                return (
                    <>
                        {
                            <div className="flex">
                                {
                                    props.node.rowPinned !== "top" &&
                                    <>
                                        <div
                                            onClick={() => {
                                                if (!inAdding) {
                                                    onClickDuplicateCondition(props.data)
                                                }
                                            }}
                                            className={"flex items-center" + (inAdding ? ' opacity-30 cursor-not-allowed' : ' cursor-pointer')}
                                        >
                                            <span className="mr-[8px]">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.33337 5.33398H2.66671C1.93137 5.33398 1.33337 5.93198 1.33337 6.66732V13.334C1.33337 14.0693 1.93137 14.6673 2.66671 14.6673H9.33337C10.0687 14.6673 10.6667 14.0693 10.6667 13.334V6.66732C10.6667 5.93198 10.0687 5.33398 9.33337 5.33398Z" fill="#3987E2" />
                                                    <path d="M13.3334 1.33398H6.66671C6.31309 1.33398 5.97395 1.47446 5.7239 1.72451C5.47385 1.97456 5.33337 2.3137 5.33337 2.66732V4.00065H10.6667C11.0203 4.00065 11.3595 4.14113 11.6095 4.39118C11.8596 4.64122 12 4.98036 12 5.33398V10.6673H13.3334C13.687 10.6673 14.0261 10.5268 14.2762 10.2768C14.5262 10.0267 14.6667 9.68761 14.6667 9.33398V2.66732C14.6667 2.3137 14.5262 1.97456 14.2762 1.72451C14.0261 1.47446 13.687 1.33398 13.3334 1.33398Z" fill="#3987E2" />
                                                </svg>
                                            </span>
                                            <span className="hover:underline">Copy</span>
                                        </div>

                                        <div
                                            onClick={() => {
                                                if (!inAdding) {
                                                    onClickSpecialDuplicateCondition(props.data)
                                                }
                                            }}
                                            className={"ml-[32px] flex items-center" + (inAdding ? ' opacity-30 cursor-not-allowed' : ' cursor-pointer')}
                                        >
                                            <span className="mr-[8px]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14"><g fill="#3987E2" fillRule="evenodd" clipRule="evenodd"><path d="M1.424.27A.75.75 0 0 1 2 0h5a.75.75 0 0 1 .738.884l-.024.131A1.5 1.5 0 0 1 9 2.5v.75H7.25a3 3 0 0 0-3 3V11H1.5A1.5 1.5 0 0 1 0 9.5v-7a1.5 1.5 0 0 1 1.286-1.485l-.024-.13a.75.75 0 0 1 .162-.616ZM2.9 1.5l.115.634a.75.75 0 0 0 .738.616h1.496a.75.75 0 0 0 .738-.616l.115-.634z" /><path d="M7.25 4.5A1.75 1.75 0 0 0 5.5 6.25v6c0 .966.784 1.75 1.75 1.75h5A1.75 1.75 0 0 0 14 12.25v-6a1.75 1.75 0 0 0-1.75-1.75zM7.875 8c0-.345.28-.625.625-.625H11a.625.625 0 1 1 0 1.25H8.5A.625.625 0 0 1 7.875 8M8.5 9.875a.625.625 0 1 0 0 1.25H11a.625.625 0 1 0 0-1.25z" /></g></svg>
                                            </span>
                                            <span className="hover:underline">Copy Smart</span>
                                        </div>
                                    </>
                                }

                                {
                                    props.node.rowPinned === "top" &&
                                    <div
                                        onClick={onClickCancelNewRow}
                                        className={"flex text items-center cursor-pointer"}
                                    >
                                        <span className="mr-[8px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#3987E2" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z" /></svg>
                                        </span>
                                        <span className="hover:underline not-italic ">Remove</span>
                                    </div>
                                }
                            </div>
                        }
                    </>
                )
            },
        }
    ], [businessFlowTypes, businessFlowTypesOptions, inAdding, deposits]);
    // #endregion

    // #region UI Handlers
    const onClickDuplicateSameDepots = () => {
        if (rowToSpecialCopy !== null) {
            duplicateSameDepots(rowToSpecialCopy);
        }

        setOpenSpecialDuplicateModel(false);
    };

    const onClickDuplicateDifferentPostCodes = () => {
        if (rowToSpecialCopy !== null) {
            duplicateDifferentPostCodes(rowToSpecialCopy);
        }

        setOpenSpecialDuplicateModel(false);
    };

    const onClickCancelNewRow = () => {
        cancelNewRow();
    };

    const onClickDuplicateCondition = (dataToCopy: DefaultRoutingConfiguration) => {
        duplicateCondition(dataToCopy);
    };

    const onClickSpecialDuplicateCondition = (dataToCopy: DefaultRoutingConfiguration) => {
        setRowToSpecialCopy(dataToCopy);
        setOpenSpecialDuplicateModel(true);
    };

    const onClickAddNewCondition = () => {
        addNewRoutingData();
    };

    const onClickDeleteButton = async () => {
        await confirmDeleteConditions();
    };

    const onClickSaveButton = async () => {
        await confirmSaveConditions();
    };
    // #endregion

    // #region Util Functions
    const duplicateSameDepots = (dataToCopy: DefaultRoutingConfiguration) => {
        let newRow: any = {};
        newRow.deposit1 = !!dataToCopy.route ? JSON.parse(JSON.stringify(dataToCopy.route[0])) : null;
        newRow.deposit2 = !!dataToCopy.route ? JSON.parse(JSON.stringify(dataToCopy.route[1])) : null;
        newRow.route = JSON.parse(JSON.stringify(dataToCopy.route));
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const duplicateDifferentPostCodes = (dataToCopy: DefaultRoutingConfiguration) => {
        let newRow: any = JSON.parse(JSON.stringify({ ...dataToCopy }));
        newRow.originZipCodeFrom = "";
        newRow.originZipCodeTo = "";
        newRow.destinationZipCodeFrom = "";
        newRow.destinationZipCodeTo = "";
        newRow.deposit1 = !!dataToCopy.route ? dataToCopy.route[0] : null;
        newRow.deposit2 = !!dataToCopy.route ? dataToCopy.route[1] : null;
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const duplicateCondition = (dataToCopy: DefaultRoutingConfiguration) => {
        let newRow: any = JSON.parse(JSON.stringify({ ...dataToCopy }));
        newRow.deposit1 = !!dataToCopy.route ? dataToCopy.route[0] : null;
        newRow.deposit2 = !!dataToCopy.route ? dataToCopy.route[1] : null;
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const cancelNewRow = () => {
        commonTableRef.current?.cancelNewRowInsertion();
        setInAdding(false);
    };

    const confirmSaveConditions = async () => {
        commonTableRef.current?.setStopEditing();
        const wantToSave = await customConfirmAlert({
            message: "Are you sure to save the configuration?",
            title: "Save conditions"
        });

        if (wantToSave) {
            await saveConditions();
        }
    };

    const checkForSameDepositInRoute = (rowsToUpdate: DefaultRoutingConfiguration[]) => {
        let thereAreSameDepositsInRoute = false;
        for (let index = 0; index < rowsToUpdate.length; index++) {
            const row = rowsToUpdate[index];

            if (!!row.route && row.route[0] === row.route[1]) {
                thereAreSameDepositsInRoute = true;
                break;
            }
        }

        return thereAreSameDepositsInRoute;
    }

    const saveConditions = async () => {
        let rowsToUpdate: DefaultRoutingConfiguration[] | undefined = commonTableRef.current?.getTableRows();

        if (rowsToUpdate === undefined) {
            return;
        }

        if (checkForSameDepositInRoute(rowsToUpdate) === true) {
            toast.error("There are some duplicated deposits in the same route");
            return;
        }

        let newRowToInsert = commonTableRef.current?.getNewRowToInsert();
        if (newRowToInsert !== undefined) {
            let newRowRoute = [];
            if (!!newRowToInsert.deposit1) {
                newRowRoute[0] = newRowToInsert.deposit1;
            }
            if (!!newRowToInsert.deposit2) {
                if (!!newRowRoute[0]) {
                    newRowRoute[1] = newRowToInsert.deposit2;
                } else {
                    newRowRoute[0] = newRowToInsert.deposit2;
                }
            }
            newRowToInsert.route = newRowRoute;

            let equalRows = rowsToUpdate.filter((x: DefaultRoutingConfiguration) => x.customerCode === newRowToInsert.customerCode && x.businessFlow === newRowToInsert.businessFlow && x.originCountry === newRowToInsert.originCountry && x.originZipCodeFrom === newRowToInsert.originZipCodeFrom && x.originZipCodeTo === newRowToInsert.originZipCodeTo && x.destinationCountry === newRowToInsert.destinationCountry && x.destinationZipCodeFrom === newRowToInsert.destinationZipCodeFrom && x.destinationZipCodeTo === newRowToInsert.destinationZipCodeTo);
            if (equalRows.length > 0) {
                toast.error("There are conflicts with one or more rows");
                setConflictRows([newRowToInsert, ...equalRows]);
                return;
            }

            rowsToUpdate = [newRowToInsert, ...rowsToUpdate];
        }

        if (foundNullValue(rowsToUpdate)) {
            toast.error("There are some null values");
            return;
        }

        const toastId = toast.loading("Updating routing data...");
        const response = await routingConfigurationService.updateRoutingConfigurations(rowsToUpdate);
        if (response.success) {
            toast.update(toastId, { type: "success", isLoading: false, render: `Routing data updated successfully!`, autoClose: 3000 });
            commonTableRef.current?.cancelNewRowInsertion();
            commonTableRef.current?.setUnsavedCells([]);
            commonTableRef.current?.setRowsInError([]);

            setTimeout(function () { commonTableRef.current?.refreshServerSide() }, 0)
            setInAdding(false);
            setConflictRows([]);
        } else if (response.statusCode === 409) {
            toast.update(toastId, { type: "error", isLoading: false, render: `There are conflicts with one or more rows`, autoClose: 3000 });
            setConflictRows(!!response.data[0]?.conflicts ? [newRowToInsert, ...response.data[0]?.conflicts] : []);
        } else {
            toast.update(toastId, { type: "error", isLoading: false, render: `Error updating routing data`, autoClose: 3000 });
        }
    };

    const foundNullValue = (rows: DefaultRoutingConfiguration[]) => {
        let foundNullValue = false;
        Object.keys(new DefaultRoutingConfiguration()).forEach(field => {
            if (rows.some((row: any) => {                
                return !!!row[field];
            })) {
                foundNullValue = true;
            }
        });

        return foundNullValue;
    };

    const confirmDeleteConditions = async () => {
        let selectedRows: DefaultRoutingConfiguration[] = commonTableRef.current?.getSelectedRows() as DefaultRoutingConfiguration[];
        if (selectedRows.length > 0) {
            const wantToDelete = await customConfirmAlert({
                message: "Are you sure to delete the selected rows?",
                title: "Delete rows"
            });

            if (wantToDelete) {
                if (await deleteRoutingData(selectedRows)) {
                    commonTableRef.current?.refreshServerSide();
                }
            }
        } else {
            toast.info("No rows selected");
        }
    };

    const deleteRoutingData = async (rowsToDelete: DefaultRoutingConfiguration[]) => {
        const response = await routingConfigurationService.deleteRoutingConfigurations(rowsToDelete, "Deleting routing data", "Routing data deleted successfully", "Error while deleting routing data");
        return response.success;
    };

    const addNewRoutingData = () => {
        let newRow: any = {};
        newRow.route = [];
        commonTableRef.current?.addNewRowInsertion(newRow);
        setInAdding(true);
    };

    const getRoutingData = async (pageSize: number, pageIndex: number, orderBy?: string, orderDirection?: string, filterModel?: DefaultRoutingConfiguration) => {
        const options: GetRoutingOption = {
            pageIndex: pageIndex,
            pageSize: pageSize,
            customerCode: filterModel?.customerCode,
            businessFlow: filterModel?.businessFlow as string[],
            originCountry: filterModel?.originCountry,
            originZipCodeFrom: filterModel?.originZipCodeFrom,
            originZipCodeTo: filterModel?.originZipCodeTo,
            destinationCountry: filterModel?.destinationCountry,
            destinationZipCodeFrom: filterModel?.destinationZipCodeFrom,
            destinationZipCodeTo: filterModel?.destinationZipCodeTo,
            orderBy: orderBy,
            orderDirection: orderDirection
        };
        const response = await routingConfigurationService.getRoutingConfiguration(options);
        if (response.success) {
            return response.data.routes;
        } else {
            toast.error("Error while retrieving data");
        }
    };
    // #endregion

    return (
        <div className="w-full mb-6 bg-white p-[32px]">
            <div id="default-routing-assignment">
                <div className="font-light text-lg leading-[25px]">Planning Tool</div>
                <div className="flex items-center pb-3 mb-6 border-b">
                    <div className="md:mr-3 text-[32px] font-bold leading-[45px]">
                        Default Routing Assignment
                    </div>
                </div>

                <AuthorizedComponent area={PermissionAreas.RoutingCondition} operation={PermissionOperations.Modify}>
                    <div className="mb-6 flex items-center">
                        <Button
                            className="bg-blue text-white !px-[12px] !py-[8px]"
                            callback={onClickAddNewCondition}
                            disabled={inAdding}
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.3333 9.16602V9.66602H10.8333H15.3333V10.3327H10.8333H10.3333V10.8327V15.3327H9.66667V10.8327V10.3327H9.16667H4.66667V9.66602H9.16667H9.66667V9.16602V4.66602H10.3333V9.16602Z" fill="#3987E2" stroke="white" />
                            </svg>
                        </Button>

                        <Button
                            className="bg-blue text-white !px-[12px] !py-[8px] ml-[12px]"
                            callback={onClickDeleteButton}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z" /></svg>
                        </Button>

                        <div className="ml-[12px]">
                            <Button
                                callback={onClickSaveButton}
                                className="bg-transparent text-black border-[1.4px] border-black"
                            >
                                Save configuration
                            </Button>
                        </div>

                        {
                            conflictRows.length > 0 &&
                            <div className="ml-7 flex cursor-pointer" onClick={() => setOpenModalShowConflicts(true)}>
                                <div className="border-red pt-[10px] pl-[15px] pr-[15px] pb-[10px] border-[3px] rounded-[10px] flex">
                                    <svg className="text-red" xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 10 10"><path fill="currentColor" d="M10 5A5 5 0 1 0 0 5a5 5 0 0 0 10 0M9 5a4 4 0 0 1-6.453 3.16L8.16 2.547C8.686 3.224 9 4.076 9 5M7.453 1.84L1.84 7.453A4 4 0 0 1 7.453 1.84" /></svg>
                                    <span className="ml-3">Some routing conditions are overlapping</span>
                                </div>
                            </div>
                        }
                    </div>
                </AuthorizedComponent>

                <div style={containerStyle}>
                    <CommonTable
                        ref={commonTableRef}
                        columnDefs={columnDefs}
                        fetchDataFunction={getRoutingData}
                        options={{
                            pageSize: 10,
                            isServerSide: true,
                            showSelectionCheckbox: hasPermission(PermissionAreas.RoutingCondition, PermissionOperations.Modify),
                            showRowsNumber: true,
                            editable: hasPermission(PermissionAreas.RoutingCondition, PermissionOperations.Modify)
                        }}
                        getRowId={(data: { data: DefaultRoutingConfiguration }) => {
                            return JSON.stringify(data.data);
                        }}
                    />
                </div>

                {
                    openSpecialDuplicateModal &&
                    <Modal
                        callbackCloseModal={() => setOpenSpecialDuplicateModel(false)}
                        title="Select copy mode"
                    >
                        <div className="flex items-center justify-center">
                            <Button
                                className="bg-blue text-white p-2 ml-2"
                                callback={onClickDuplicateSameDepots}
                            >
                                Depots
                            </Button>

                            <Button
                                className="bg-blue text-white p-2 ml-4"
                                callback={onClickDuplicateDifferentPostCodes}
                            >
                                All but PostCode
                            </Button>
                        </div>
                    </Modal>
                }
            </div>

            {
                openModalShowConflicts === true &&
                <ModalShowRoutingAssignmentConflicts
                    callbackCloseModal={() => setOpenModalShowConflicts(false)}
                    conflicts={conflictRows}
                />
            }
        </div>
    )
}

export default PlanningToolDefaultRoutingAssignment;